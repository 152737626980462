<template>
  <div>
    <div class="mt-3">
      <v-card class="mt-2" :color="getColor(message.id)" dark>
        <v-card-text>
          <p>
            {{ message.body }}
          </p>
        </v-card-text>
      </v-card>

      <p class="text-right pa-4">
        {{ message.created_at }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Detail",
  props: ['message'],
  methods: {
    getColor(messageId) {
      return this.isConsumer(messageId) ? "grey darken-1" : "teal lighten-1"
    },
    isConsumer(meessageId) {
      return meessageId.includes('false')
    }
  }
}
</script>

<style scoped>

</style>
