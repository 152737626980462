<template>
  <v-card>
    <v-card-title>
      Покупки - ({{ getByRealUserCount() }}) / {{ tariffUsages.length }}

      <v-btn class="ml-4" color="orange" @click="getTariffUsageList()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="tariffUsages"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          class="elevation-1"
          item-key="id"
          :items-per-page="itemsPerPage"
          :item-class="itemRowBackground"
          show-select
      >
        <template v-slot:item.created_at="{ item }">
          {{ convertUtcToDate(item) }}
        </template>

        <template v-slot:item.profile="{ item }">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <ProfileControlDialog :profile_id="item.profile_id" @close="close()"/>

                {{ item.profile.first_name }}
              </span>
            </template>

            <span>
               {{ getTooltipInfo(item.profile) }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.publisher="{ item }">
          {{ item.profile.publisher }}
        </template>

        <template v-slot:item.product_id="{ item }">
          {{ item.tariff.product_id }}
        </template>

        <template v-slot:item.age="{ item }">
          {{ item.profile.age }}
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.profile.city.name }}
        </template>

        <template v-slot:item.os_type="{ item }">
          <OsTypeShop :os_type="item.os_type"/>
        </template>

        <template v-slot:item.tariff_name="{ item }">
          {{ item.tariff ? item.tariff.name : null }}
        </template>

        <template v-slot:item.tariff_price="{ item }">
          {{ item.tariff ? item.tariff.price : null }}
        </template>

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, sexList} from "@/enums/enums";
import DefaultStatus from "../DefaultStatus";
import ProfileControlDialog from "../Profile/ProfileControlDialog";
import OsTypeShop from "./OsTypeShop";
import {mapGetters} from "vuex";
import {convertUtcToDate} from "@/utils/converters";

const queryString = require('query-string');

export default {
  name: 'TariffUsageGrid',
  props: ['url'],
  mounted() {
    this.queryParams = this.$route.query
    this.getTariffUsageList()
  },
  components: {
    ProfileControlDialog,
    DefaultStatus,
    OsTypeShop
  },
  data() {
    return {
      tariffUsages: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},
      itemsPerPage: 100,

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Дата',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Профиль',
          align: 'start',
          sortable: true,
          value: 'profile',
        },
        /*{
          text: 'Партнер',
          align: 'start',
          sortable: true,
          value: 'publisher',
        },*/
        {
          text: 'Возраст',
          align: 'start',
          sortable: true,
          value: 'age',
        },
        {
          text: 'product_id',
          align: 'start',
          sortable: true,
          value: 'product_id',
        },
        {
          text: 'Тариф',
          align: 'start',
          sortable: true,
          value: 'tariff_name',
        },
        {
          text: 'Кол-во',
          align: 'start',
          sortable: true,
          value: 'meetings',
        },
        {
          text: 'Цена',
          align: 'start',
          sortable: true,
          value: 'tariff_price',
        },
        {
          text: 'Город',
          align: 'start',
          sortable: true,
          value: 'city',
        },
        {
          text: 'Магазин',
          align: 'start',
          sortable: true,
          value: 'os_type',
        },
        {
          text: 'Покупка',
          align: 'start',
          sortable: true,
          value: 'purchase_id',
        },

        /*
        {
            text: 'Свидания',
            align: 'start',
            sortable: true,
            value: 'meetings',
        },
        {
            text: 'Sex',
            align: 'start',
            sortable: true,
            value: 'sex',
        },
        {
            text: 'Валюта',
            align: 'start',
            sortable: true,
            value: 'currency',
        },

        {
            text: 'Google purchase id',
            align: 'start',
            sortable: true,
            value: 'google_purchase_id',
        },
        {
            text: 'Apple purchase id',
            align: 'start',
            sortable: true,
            value: 'apple_purchase_id',
        },*/

        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
    }),
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_TARIFF_USAGE_BY_ADMIN, async () => {
      this.getTariffUsageList()
    })
    eventBus.$on(eventBusEvents.EVENT_CREATE_TARIFF_USAGE_BY_ADMIN, async () => {
      this.getTariffUsageList()
    })
    eventBus.$on(eventBusEvents.EVENT_FILTER_TARIFF_USAGE, async search => {
      // console.log('search: ' , search)
      this.queryParams = search
      this.getTariffUsageList()
    })
  },
  methods: {
    getByRealUserCount() {
      return this.tariffUsages.filter(this.getRealConditionByAge()).length
    },
    getRealConditionByAge() {
      return it => it.profile.age < 70
    },
    convertUtcToDate(user) {
      return convertUtcToDate(user.created_at, user?.profile?.city?.timezone)
    },
    /* todo облегчить нагрузку*/
    itemRowBackground(tariffUsage) {
      if (!!tariffUsage.profile.age && (tariffUsage.profile.age > 70)) {
        return 'yellow lighten-2'
      }

      return tariffUsage.sex === sexList.man ? 'cyan lighten-5' : 'red lighten-5'
    },
    getTariffUsageList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      // console.log('queryParams: ', this.queryParams)
      // console.log('q: ', q)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}?${q}`)
          .then(response => this.tariffUsages = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
    getTooltipInfo(profile) {
      return `${profile.user_id}_${profile.id}. ${profile.phone}`
    },
  }
}
</script>

<style scoped>

</style>
