<template>
  <div>
    <div>
      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()" v-if="allowNext()">
        Далее
      </v-btn>

      <p class="mt-8">
        Всего: {{ profiles.length }}
      </p>

      <v-divider/>

      <ProfileOnModerationItem :profile="profile" v-for="profile in profiles" :key="profile.id" class="mb-6"/>

      <v-divider/>

      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()">
        Далее
      </v-btn>

      {{ queryParams }}

      <hr>
    </div>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, userModesList} from "@/enums/enums";
import {mapActions, mapGetters} from "vuex";
import {objectToQueryString} from "@/utils/helpers";

import ProfileOnModerationItem from "./ProfileOnModerationItem";

export default {
  name: 'ProfileOnModerationList',
  components: {
    ProfileOnModerationItem
  },
  data() {
    return {
      profiles: [],

      queryParams: {
        limit: 100,
        offset: 0,
        is_viewed_by_moderator: false
      },
    }
  },
  mounted() {
    this.queryParams = {...this.queryParams, ...this.$route.query}
    this.getProfilesList()
  },
  created() {
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
      user: 'GET_USER',
    }),
    crudActions: function () {
      return crudActions
    },
    userModesList: function () {
      return userModesList
    },
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    update(val) {
      this.getProfilesList();
    },

    resetFilters() {
      this.queryParams = {
        limit: 100,
        offset: 0,
        is_viewed_by_moderator: false
      }
      eventBus.$emit(eventBusEvents.EVENT_FILTER_USER_RESET)
      this.getProfilesList()
    },
    next() {
      this.queryParams.offset += 100
      this.getProfilesList()

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    allowNext() {
      return this.profiles.length > 0
    },
    prev() {
      if (!this.showPrev()) {
        alert('Невозможно уменьшить')
      }

      this.queryParams.offset -= 100
      this.getProfilesList()
      window.scroll(0, 0)
    },
    showPrev() {
      return !!this.queryParams.offset
    },

    getProfilesList() {
      let q = objectToQueryString(this.queryParams)

      console.log('qqqqq: ', {q})

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles-on-moderation?${q}`)
          .then(response => {
            this.profiles = response.data.data.items
            this.counters = response.data.data.counters
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    close() {
      this.getProfilesList();
    },
  }
}
</script>

<style scoped>

</style>
