<template>
  <v-chip :color="getColor()">
    {{ getName() }}

    <slot></slot>
  </v-chip>
</template>

<script>
import {userStatusesEnum} from "@/enums/enums";

export default {
  name: "UserStatus",
  props: ['status'],
  methods: {
    getName() {
      return userStatusesEnum.find(it => it.id === this.status).title
    },
    getColor() {
      return userStatusesEnum.find(it => it.id === this.status).color
    },
  }
}
</script>

<style scoped>

</style>
