<template>
  <v-card>
    <v-card-title>
      Группа
    </v-card-title>

    <v-card-text>
      <v-radio-group v-model="casteLocal">
        <v-radio
            v-for="itCaste in casteList"
            :key="itCaste.id"
            :label="itCaste.name"
            :value="itCaste.id"
        />
      </v-radio-group>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {casteEnum} from "@/enums/enums";

export default {
  name: "CasteForm",
  props: ['caste', 'profile'],
  data() {
    return {
      casteLocal: null
    }
  },
  mounted() {
    this.casteLocal = this.caste
  },
  computed: {
    casteList: function () {
      return casteEnum
    }
  },
  methods: {
    change() {
      if (!this.casteLocal) {
        alert('Caste is empty')
        return false
      }

      // let url = this.profile ? `/admin/users/${this.profile.user_id}/profile/caste` : `/profile/caste`

      let url = this.profile ? `/admin/profiles/${this.profile.id}/caste` : `/profile/caste`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        caste: this.casteLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_CASTE, this.casteLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
