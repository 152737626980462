<template>
  <div>
    <v-row>
      <v-col cols="11">
        <StopperGrid :url="'/admin/stoppers'"/>
      </v-col>

      <!--<v-col cols="1">
          <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>-->
    </v-row>

    <!--<v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher :clipped="false">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
        <BiometricFilter class="mb-4"/>

        <v-divider/>
    </v-navigation-drawer>-->
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import StopperGrid from "../../components/Stopper/StopperGrid";

export default {
  name: "AdminStoppersPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    StopperGrid,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
