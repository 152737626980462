var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Шаги регистрации "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"single-select":_vm.singleSelect,"headers":_vm.headers,"items":_vm.registerSteps,"expanded":_vm.expanded,"search":_vm.search,"item-key":"id","items-per-page":30,"show-select":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('DefaultStatus',{attrs:{"status":item.status}})]}},{key:"item.down",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.moveTo(item, 'down')}}},[_c('v-icon',[_vm._v(" mdi-arrow-up-bold ")])],1)]}},{key:"item.up",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.moveTo(item, 'up')}}},[_c('v-icon',[_vm._v(" mdi-arrow-down-bold ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('RegisterStepControlDialog',{attrs:{"register_step":item,"action":_vm.crudActions.update}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }