<template>
  <v-chip :color="getColor()">
    {{ score }}
  </v-chip>
</template>

<script>
import {pairRatingParamsList} from "@/enums/enums";

export default {
  name: "VedicRate",
  props: ['param', 'score'],
  computed: {
    pairRatingParamsList: function () {
      return pairRatingParamsList
    }
  },
  methods: {
    getColor() {
      let colors = {
        danger: 'red',
        info: 'grey',
        warning: 'warning',
        success: 'green',
      }

      if (this.param === this.pairRatingParamsList.rating) {
        if (this.score < 18) return colors.danger
        else if (this.score >= 18 && this.score <= 24) return colors.info
        else if (this.score >= 25 && this.score <= 32) return colors.warning
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.vedic_total) {
        if (this.score < 18) return colors.danger
        else if (this.score >= 18 && this.score <= 24) return colors.info
        else if (this.score >= 25 && this.score <= 32) return colors.warning
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.varna_score) {
        return this.score === 0 ? colors.danger : colors.success
      }
      if (this.param === this.pairRatingParamsList.vashya_score) {
        if (this.score <= 0.5) return colors.danger
        else if (this.score >= 1 && this.score <= 1.5) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.dina_score) {
        if (this.score <= 0.5) return colors.danger
        else if (this.score >= 1 && this.score <= 2) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.yoni_score) {
        if (this.score <= 1) return colors.danger
        else if (this.score >= 1.5 && this.score <= 2.5) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.grahamaitri_score) {
        if (this.score <= 2) return colors.danger
        else if (this.score >= 2.5 && this.score <= 3.5) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.gana_score) {
        if (this.score <= 2) return colors.danger
        else if (this.score >= 2 && this.score <= 3.5) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.rasi_score) {
        if (this.score <= 2.5) return colors.danger
        else if (this.score >= 3 && this.score <= 4.5) return colors.info
        else return colors.success
      }
      if (this.param === this.pairRatingParamsList.nadi_score) {
        if (this.score <= 3.5) return colors.danger
        else if (this.score >= 4 && this.score <= 6) return colors.info
        else return colors.success
      }
    }
  }
}
</script>

<style scoped>

</style>
