<template>
  <div>
    <v-card>
      <v-card-title>
        Пульт управления:
      </v-card-title>

      <v-card-text>
        <template v-if="actionLocal === crudActions.create">
          <SheetForm
              :action="actionLocal"
              :sheet="sheetLocal"
          />
        </template>
        <template v-else>
          <!-- sheetLocal_: {{ sheetLocal }}-->

          <SheetForm
              v-if="sheetLocal"
              :action="actionLocal"
              :sheet="sheetLocal"
          />
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";
import SheetForm from "./SheetForm";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: "SheetPanel",
  components: {
    SheetForm
  },
  props: ['action', 'sheet_id'],
  data() {
    return {
      sheetLocal: null,
      actionLocal: null,
    }
  },
  mounted() {
    this.actionLocal = this.action

    if(this.actionLocal === this.crudActions.update) {
      this.getSheetById(this.sheet_id)
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_SHEET_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.update
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_SHEET_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.create
    });
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  methods: {
    getSheetById(){
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets/${this.sheet_id}`)
          .then(resp => this.sheetLocal = resp.data.data.sheet)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    goToCreate() {
      this.actionLocal = this.crudActions.create
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_SHEET_BY_ADMIN)
    },
    isActionCreate() {
      return this.actionLocal === this.crudActions.create
    },
    isActionUpdate() {
      return !this.isActionCreate()
    }
  }
}
</script>

<style scoped>

</style>
