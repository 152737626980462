<template>
  <div>
    <v-row>
      <v-col cols="8">
        <TariffGrid/>
      </v-col>

      <v-col cols="4">
        <TariffPanel
            :action="crudActions.create"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums";
import TariffGrid from "../../components/Tariff/TariffGrid";
import TariffPanel from "../../components/Tariff/TariffPanel";

export default {
  name: "AdminTariffsPage",
  components: {
    TariffGrid,
    TariffPanel,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
