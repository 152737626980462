<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-select v-model="select"
                :items="items"
                :label="title"
                item-text="name"
                item-value="id"
                @change="change"
      />
    </v-card-text>

<!--    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>-->
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "DictFloatPanel",
  props: [
    'dictMethod',
    'title',
    'changeMethod',
    'postArgument',
    'value'
  ],
  data() {
    return {
      select: null,
      items: []
    }
  },
  mounted() {
    this.select = this.value
    this.getList()
  },
  methods: {
    ...mapActions([
      'EXEC_CHANGE_PROFILE_PARAM'
    ]),
    getList() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}${this.dictMethod}`)
          .then(resp => this.items = resp.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    change: function () {
      console.log('select', this.select)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${this.changeMethod}`, {
        [this.postArgument]: this.select,
        _method: 'put'
      })
          .then(() => {
            this.EXEC_CHANGE_PROFILE_PARAM({
              key: this.postArgument,
              value: this.select
            })
            eventBus.$emit(eventBusEvents.EVENT_CHANGE_DICT, {key: this.postArgument, value: this.select})
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
