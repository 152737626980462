<template>
  <v-card>
    <v-card-title>
      Sex
    </v-card-title>

    <v-card-text>
      <v-radio-group v-model="sexLocal">
        <v-radio
            v-for="itSex in sexList"
            :key="itSex.id"
            :label="itSex.name"
            :value="itSex.id"
        />
      </v-radio-group>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {sexEnum} from "@/enums/enums";

export default {
  name: "SexForm",
  props: ['sex', 'profile'],
  data() {
    return {
      sexLocal: null
    }
  },
  mounted() {
    this.sexLocal = this.sex
  },
  computed: {
    sexList: function () {
      return sexEnum
    }
  },
  methods: {
    change() {
      if (!this.sexLocal) {
        alert('Sex is empty')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/sex` : `/profile/sex`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        sex: this.sexLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_SEX, this.sexLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
