<template>
  <div>
    <UniversalDashboard
        :title="'Доходы'"
        :url="'/admin/profiles?income_id=code'"
        :list="this.incomes"
        v-if="this.incomes.length"
    />
  </div>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus"
import {converValidationServerErrorsToString} from "@/utils/errorUtil"
import {objectToQueryString} from "@/utils/helpers"
import UniversalDashboard from "../UniversalDashboard";

const queryString = require('query-string')

export default {
  name: "IncomesDashboard",
  components: {
    UniversalDashboard
  },
  data() {
    return {
      incomes: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_DASHBOARD, async search => {
      console.log('EVENT_FILTER_DASHBOARD', search)
      this.queryParams = search
      this.getIncomes()
    });
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getIncomes()
  },
  methods: {
    getIncomes() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/incomes?${q}`)
          .then(response => this.incomes = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
