<template>
  <div>
    <v-row>
      <v-col cols="11">
        <BooleanSettingGrid/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import BooleanSettingGrid from "../../components/BooleanSetting/BooleanSettingGrid";

export default {
  name: "AdminBiometricsPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    BooleanSettingGrid,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
