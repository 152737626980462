<template>
  <div>
    <v-row>
      <v-col cols="11">
        <MeetingGrid :url="`/admin/meetings`"/>
      </v-col>

      <v-col cols="1">
        <!--<v-app-bar-nav-icon @click="drawer = !drawer"/>-->
      </v-col>
    </v-row>

    <!--<v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher
                         :clipped="false">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
        <UserFilter class="mb-4"/>
        <v-divider/>
    </v-navigation-drawer>-->
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import MeetingGrid from "../../components/Meeting/MeetingGrid";

export default {
  name: "AdminMeetingsPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    MeetingGrid,
    // DailyMatchGrid,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
