<template>
  <v-card>
    <v-card-title>
      Пульт управления:
    </v-card-title>

    <v-card-text>
      <FaqForm
          v-if="this.actionLocal"
          :action="actionLocal"
          :faq="faqLocal"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {crudActions} from "@/enums/enums";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import FaqForm from "./FaqForm";

export default {
  name: "FaqPanel",
  props: ['action', 'faq'],
  components: {
    FaqForm
  },
  data() {
    return {
      actionLocal: null,
      faqLocal: null,
    }
  },
  mounted() {
    this.actionLocal = this.action
    this.faqLocal = this.faq
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_FAQ_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.update
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_FAQ_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.create
    });
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
  methods: {
    goToCreate() {
      this.actionLocal = this.crudActions.create
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_FAQ_BY_ADMIN)
    },
    isActionCreate() {
      return this.actionLocal === this.crudActions.create
    },
    isActionUpdate() {
      return !this.isActionCreate()
    }
  }
}
</script>

<style scoped>

</style>
