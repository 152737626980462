<template>
  <div>
      <v-container>
        <v-row>
          <v-col md="4">
            <v-img
                v-for="photo in profile.photos"
                :key="photo.id"
                :src="photo.image_path_large"
                max-height="600"
                class="grey darken-4 mb-4"
            />
            <br>

            <!--image_thumb_path-->
          </v-col>

          <v-col md="8">
            <h2 v-if="!!profile.id">
              {{ profile.first_name }} {{ profile.age }}, {{ getCityName(profile) }}
            </h2>

            <p v-if="!!profile.phone">
              <a :href='`tel:${profile.phone}`'>
                {{ profile.phone }}
              </a>
            </p>

            <v-simple-table class="ma-6">
              <template v-slot:default>
                <tbody>

                <tr v-if="profile.children_status">
                  <!--<td>Children status</td>-->
                  <td>Статус детей</td>
                  <td>
                    {{ profile.children_status.name }}
                  </td>
                </tr>

                <tr v-if="profile.marital_status">
<!--                  <td>Family status</td>-->
                  <td>Семейное положение</td>
                  <td>
                    {{ profile.marital_status.name }}
                  </td>
                </tr>

                <tr v-if="profile.constitution">
<!--                  <td>Constitution</td>-->
                  <td>Телосложение</td>
                  <td>
                    {{ profile.constitution.name }}
                  </td>
                </tr>

                <tr v-if="profile.education">
<!--                  <td>Education</td>-->
                  <td>Образование</td>
                  <td>
                    {{ profile.education.name }}
                  </td>
                </tr>

                <tr v-if="profile.sphere">
<!--                  <td>Sphere</td>-->
                  <td>Сфера деятельности</td>
                  <td>
                    {{ profile.sphere.name }}
                  </td>
                </tr>

                <tr v-if="profile.professional_status">
<!--                  <td>Professional status</td>-->
                  <td>Профессиональный статус</td>
                  <td>
                    {{ profile.professional_status.name }}
                  </td>
                </tr>

                <tr v-if="profile.smoke_status">
<!--                  <td>Smoke status</td>-->
                  <td>Курение</td>
                  <td>
                    {{ profile.smoke_status.name }}
                  </td>
                </tr>

                <tr v-if="profile.interests.length > 0">
<!--                  <td>Interests</td>-->
                  <td>Интересы</td>
                  <td>
                    {{ profile.interests.map(it => it.name).join(', ') }}
                  </td>
                </tr>

                <tr v-if="profile.languages.length > 0">
<!--                  <td>Languages</td>-->
                  <td>Языки</td>
                  <td>
                    {{ profile.languages.map(it => it.name).join(', ') }}
                  </td>
                </tr>

                <tr v-if="profile.features.length > 0">
<!--                  <td>Features for meting</td>-->
                  <td>Предпочтения по свиданию</td>
                  <td>
                    {{ profile.features.map(it => it.name).join(', ') }}
                  </td>
                </tr>

                <tr>
<!--                  <td>Search age preferences</td>-->
                  <td>Поиск возрастных предпочтений</td>
                  <td>
                    {{ profile.search_age_from }} - {{ profile.search_age_to }}
                  </td>
                </tr>

                </tbody>
              </template>
            </v-simple-table>

            <slot/>
          </v-col>
        </v-row>
      </v-container>
<!--    </div>-->
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums";

require('dotenv').config()

export default {
  name: 'ProfilePresentable',
  props: {
    profile: Object
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  methods: {
    getCityName(profile) {
      return profile.city_live?.name
    }
  }
}
</script>

<style scoped>

</style>
