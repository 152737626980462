<template>
  <div>
        <span v-if="value">
            {{ value }}
        </span>

    <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="dateFormatted"
            :label="label"
            persistent-hint
            v-bind="attrs"
            @blur="date = parseDate(dateFormatted)"
            v-on="on"
        />

        <!--:placeholder="value"-->
      </template>

      <v-date-picker
          v-model="date"
          no-title
          @input="menu1 = false"
      />
    </v-menu>
  </div>
</template>

<script>
import {eventBus, eventBusEvents} from "../eventBus/eventBus";

export default {
  name: "FilterDatepicker",
  props: ['fieldName', 'label', 'value'],
  created() {
    eventBus.$on(eventBusEvents.EVENT_CALENDAR_FILTER_RESET, async () => {
      console.log('eventBusEvents.EVENT_CALENDAR_FILTER_RESET_____')
      this.resetFilter()
    });
  },
  mounted() {
    if (this.value) {
      this.date = this.value
    }
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: null,
      menu1: false,
      menu2: false,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },
  methods: {
    resetFilter() {
      this.dateFormatted = null
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date, delimiter = '/') {
      if (!date) return null
      const [day, month, year] = date.split(delimiter)
      return `${year}-${month}-${day}`
      // return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
  watch: {
    date() {
      if (!this.date) return false
      this.dateFormatted = this.formatDate(this.date)
      let dateWithReplacement = this.dateFormatted.replace(/\//g, '-');
      let result = this.parseDate(dateWithReplacement, '-')
      this.$emit('update', result)

      // вариант для crud
      if (this.value) {
        this.dateFormatted = null
      }
    },
  },
}
</script>

<style scoped>

</style>
