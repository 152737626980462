<template>
  <div>
    <v-row>
      <v-col cols="12">
        <ProfileOnModerationList/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileOnModerationList from "../../../../components/Profile/ProfileOnModerationList";

export default {
  name: "ProfilesOnModerationPage.vue",
  components: {
    ProfileOnModerationList
  },
}
</script>

<style scoped>

</style>
