<template>
  <v-card>
    <v-card-title>
      Agree video
    </v-card-title>

    <v-card-text>
      <iframe width="560"
              height="315"
              src="https://www.youtube.com/embed/5T4MLHRPnhk"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen/>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "AgreeVideo",
  data() {
    return {
      height: 170,
      min: 100,
      max: 250,
    }
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    change() {
      eventBus.$emit(eventBusEvents.EVENT_CHANGE_AGREE_VIDEO, this.height)
    }
  }
}
</script>

<style scoped>

</style>
