<template>
  <v-row>
    <v-col cols=11>
      <UniversalDashboard
          :title="'Купленные тарифы по возрастам'"
          :list="this.tariffUsages"
          v-if="this.tariffUsages.length"
      />
    <!-- Наш доход по возрастам -->

      <!--:url="'/admin/users?tariff_id=code'"-->
    </v-col>

    <v-col cols=1>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-navigation-drawer v-model="drawer"
                           :clipped="false"
                           right fixed dark app disable-route-watcher enable-resize-watcher
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"/>

        <TariffUsagesByAgeDashboardFilter class="mb-4"/>

        <v-divider/>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {objectToQueryString} from "@/utils/helpers";
import UniversalDashboard from "../UniversalDashboard";
import TariffUsagesByAgeDashboardFilter from "./TariffUsagesByAgeDashboardFilter";

const queryString = require('query-string')

export default {
  name: "TariffUsagesByAgeDashboard",
  components: {
    TariffUsagesByAgeDashboardFilter,
    UniversalDashboard
  },
  data() {
    return {
      drawer: false,
      tariffUsages: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_USER_BY_AGE_RANGE, async search => {
      console.log('EVENT_FILTER_USER_BY_AGE_RANGE', search)
      this.queryParams = search
      this.getTariffUsages()
    })
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getTariffUsages()
  },
  methods: {
    getTariffUsages() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/tariff-usages-by-age?${q}`)
          .then(response => this.tariffUsages = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
  }
}
</script>

<style scoped>

</style>
