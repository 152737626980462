<template>
  <div>
    <v-card v-if="member">
      <template v-slot:progress>
        <v-progress-linear color="deep-purple" height="10" indeterminate/>
      </template>

      <v-card-subtitle>
        <div class="pa-4">
          <img style="width: 100%" :src="getCover(member.profile.photos)"/>
          <!--<v-img aspect-ratio="1.7" :src="getCover(member.user.profile.photos)"/>-->
        </div>
        <p>
          Предложение: {{ member.offer_id }}
        </p>
      </v-card-subtitle>

      <v-card-title class="text-sm-body-2">
        <MemberNameColorReaction :member="member"/>
        <!--{{ profile.first_name }}-->

        <v-divider/>

        <v-icon aria-hidden="false">
          {{ member.profile.sex === 1 ? 'mdi-gender-male' : 'mdi-gender-female' }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <VueJsonPretty :path="'res'" :data="handshakes"/>
      </v-card-text>

      <v-card-actions>
        <!--<PairReaction class="mt-4" :reaction="member.reaction"/>-->

        <v-spacer/>

        <v-btn @click="copyText">
          copy <v-icon>mdi-mail</v-icon>
        </v-btn>

        <v-btn icon :href="getWhatsappLink(member.profile.phone)" target="_blank" color="green">
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>

        <ProfileControlDialog :profile_id="member.profile.id" @close="close()"/>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ProfileControlDialog from "./ProfileControlDialog";
import MemberNameColorReaction from "../Member/MemberNameColorReaction";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import {getWhatsappLinkByPhone} from "@/utils/helpers";

export default {
  name: "ProfileCard",
  components: {
    ProfileControlDialog,
    MemberNameColorReaction,
    VueJsonPretty
    /*PairReaction,*/
  },
  mounted() {
    this.getHandshakes()
  },
  props: ['member', 'pair_id'],
  data() {
    return {
      handshakes: []
    }
  },
  methods: {
    getCover(photos) {
      let cover = photos.filter(it => !!it.is_cover)?.first?.image
      if (cover) return cover
      return photos[0]?.image || null
    },
    getHandshakes() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${this.pair.id}/handshakes?searcher_id=${this.member.profile.id}`)
          .then(response => {
            this.handshakes = response.data.data.items
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
    /////
    showPhone() { /// todo duplicate
      return this.member.profile.phone
      // return this.isAllowed() ? this.member.profile.phone : this.member.profile.phone_trash
    },
    getWhatsappLink() {
      return getWhatsappLinkByPhone(this.showPhone())
    },
    copyText() {
      navigator.clipboard.writeText(this.member.profile.email);
    }
  }
}
</script>

<style scoped>

</style>
