<template>
  <v-card>
    <v-card-title>
      {{ getTitle() }}
    </v-card-title>

    <v-card-text>
      <CoolLightBox
          :items="photos.map(it => it.image_thumb_path)"
          :index="index"
          @close="index = null">
      </CoolLightBox>

      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-text>
              <slot/>

              <div class="form-group py-2">
                <input type="file"
                       name="picture"
                       ref="fileupload"
                       v-if="allowToUpload()"
                       class="form-control-file"
                       id="image"
                       @change="onFileChange"/>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="deep-purple darken-2" @click="upload" v-if="allowToUpload()" text>
                Добавить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="3">
          <div class="form-group py-2">
            <img :src="imagePreview" style="height: 100px" v-show="showPreview" alt=""/>
          </div>
        </v-col>

        <v-col cols="3">
          <v-card
              class="mx-auto mb-4"
              :color="getColorHeader(photo)"
              max-width="400"
              v-for="(photo, i) in localPhotos"
              :key="photo.id"
              dark
          >
            <!-- <LightBox :media="formatForMedia(photo)"/>-->

            <div class="text-right">
              <v-icon @click="changeCover(photo)" v-if="!photo.is_cover && showAnchor" large center style="cursor: pointer;">
                mdi-anchor
              </v-icon>

              <v-icon @click="remove(photo)" large center style="cursor: pointer;">
                mdi-close
              </v-icon>
            </div>

            <v-img :src="photo.image_thumb_path" height="125" class="grey darken-4 mb-4" @click="popupImage(i)"/>

            <template v-if="showSource">
              photo.image_path_large:
              <br/>
              <a :href="photo.image_path_large">{{ photo.image_path_large }}</a>
              <hr/>
              photo.image_path_medium:
              <br/>
              <a :href="photo.image_path_medium">{{ photo.image_path_medium }}</a>
              <hr/>
              photo.image_path_small:
              <br/>
              <a :href="photo.image_path_small">{{ photo.image_path_small }}</a>
            </template>

            <!--<v-hover v-slot:default="{ hover }">
              <v-img :src="photo.image_thumb_path" height="125" class="grey darken-4 mb-4" @click="popupImage(i)">
                <v-expand-transition>
                  <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out red darken-2 v-card&#45;&#45;reveal display-3 white&#45;&#45;text"
                      style="height: 30%;"
                  >
                    <v-icon @click="remove(photo)" large center style="cursor: pointer;">
                      mdi-close
                    </v-icon>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>-->

          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";

export default {
  name: "PhotoForm",
  props: [
    'title',
    'photos',
    'min',
    'max',
    'url',
    'showSource', // false default
    'showAnchor', // false default
  ],
  components: {
    CoolLightBox,
  },
  data() {
    return {
      localPhotos: [],
      formFields: {
        photo: null
      },
      imagePreview: null,
      showPreview: false,

      index: null
    }
  },
  mounted() {
    this.localPhotos = this.photos

    this.$nextTick()
  },
  methods: {
    ...mapActions([
      'EXEC_REFRESH_PHOTOS'
    ]),
    getColorHeader(photo) {
      return photo.is_cover ? 'orange' : 'black'
    },
    popupImage(i) {
      this.index = i
    },
    getTitle() {
      return this.title || 'Photo'
    },
    refresh() {
      this.formFields.photo = null
      this.imagePreview = null
      this.showPreview = false
      this.$refs.fileupload.value = null;
    },
    // todo duplicate
    remove(photo) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}/${photo.id}`).then(() => {
        let index = this.localPhotos.findIndex(it => it.id === photo.id)
        this.localPhotos.splice(index, 1)
        this.EXEC_REFRESH_PHOTOS(this.localPhotos)

        // eventBus.$emit(eventBusEvents.EVENT_REMOVE_PHOTO, this.localPhotos)
      }).catch(err => {
        console.log('upload_err___', err);
        alert(converValidationServerErrorsToString(err))
      })
    },
    changeCover(photo) {
      let data = {
        ...{
          _method: 'put'
        }
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}/${photo.id}/change-cover`, data).then(() => {
        this.localPhotos.forEach(elem => {
          let index = this.localPhotos.findIndex(it => it.id === elem.id)
          this.localPhotos[index].is_cover = false
        })

        let index = this.localPhotos.findIndex(it => it.id === photo.id)
        this.localPhotos[index].is_cover = !this.localPhotos[index].is_cover // or true

        this.EXEC_REFRESH_PHOTOS(this.localPhotos)

        // eventBus.$emit(eventBusEvents.EVENT_REMOVE_PHOTO, this.localPhotos)
      }).catch(err => {
        console.log('upload_err___', err);
        alert(converValidationServerErrorsToString(err))
      })
    },
    upload() {
      let formData = new FormData();
      if (!this.formFields.photo) {
        alert('image is empty')
        return false
      }

      formData.append('image', this.formFields.photo)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}`, formData).then(res => {
        this.localPhotos.push(res.data.data.photo)
        this.refresh()

        eventBus.$emit(eventBusEvents.EVENT_LOAD_PHOTO, this.localPhotos)
        this.EXEC_REFRESH_PHOTOS(this.localPhotos)
      }).catch(err => {
        console.log('upload_err___', err);
        alert(converValidationServerErrorsToString(err))
      })
    },
    onFileChange(event) {
      this.formFields.photo = event.target.files[0];

      let reader = new FileReader();

      reader.addEventListener('load', function () {
        this.showPreview = true;
        this.imagePreview = reader.result;
      }.bind(this), false);

      if (this.formFields.photo) {
        if (/\.(jpe?g|png|gif)$/i.test(this.formFields.photo.name)) {
          reader.readAsDataURL(this.formFields.photo);
        }
      }
    },
    allowToChange() {
      return this.localPhotos.length >= this.min && this.localPhotos.length <= this.max
    },
    allowToUpload() {
      return this.localPhotos.length < this.max
    }
  }
}
</script>

<style scoped>

</style>
