<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="biometricLocal.name"
          :rules="rules.nameRules"
          label="name"
          type="tel"
          @keyup.enter="handleSubmit"
          required
      />

      <v-text-field
          v-model="biometricLocal.body"
          :rules="rules.bodyRules"
          label="body"
          type="text"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";

require('dotenv').config()

export default {
  name: "BiometricForm",
  props: ['action', 'biometric'],
  data() {
    return {
      valid: true,

      actionLocal: null,

      biometricLocal: {
        name: "",
        body: "",
      },
      rules: {
        nameRules: [
          v => !!v || 'Name must be valid',
          v => v.length > 2 || 'Name length must be more than 2 characters',
        ],
        bodyRules: [
          // v => !!v || 'Body must be valid',
          // v => v.length > 20 || 'Body length must be more than 20 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
    }
  },
  created() {
    this.actionLocal = this.action

    if (this.biometric) {
      this.biometricLocal = this.biometric
      this.edit(this.biometric)
    }

    eventBus.$on(eventBusEvents.EVENT_EDIT_BIOMETRIC_BY_ADMIN, async biometric => {
      console.log('EDIT_biometric_BY_ADMIN', biometric)
      this.edit(biometric)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_BIOMETRIC_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    if (this.actionLocal === this.crudActions.update) {
      this.biometricLocal = this.biometric
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.biometricLocal.id = data.id
      this.biometricLocal.name = data.name
      this.biometricLocal.body = data.body
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.biometricLocal.id = null
      this.biometricLocal.name = null
      this.biometricLocal.body = null
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      console.log('biometric_form_create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/biometrics`,
          this.biometricLocal
      ).then(resp => {
        const biometric = resp.data.data.biometric
        console.log('Created biometric: ', biometric)
        eventBus.$emit(eventBusEvents.EVENT_CREATE_BIOMETRIC_BY_ADMIN, biometric)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    update() {
      console.log('biometric_form_update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.biometricLocal,
        ...{
          _method: 'put'
        }
      }

      console.log('biometric_update_data___', data)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/biometrics/${this.biometricLocal.id}`,
          data
      ).then(() => {
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_BIOMETRIC_BY_ADMIN)
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.biometricLocal.name) {
        alert('name is empty')
        return false
      }

      /*if (!this.biometricLocal.body) {
          alert('body is empty')
          return false
      }*/

      return true
    }
  }
}
</script>

<style scoped>

</style>
