<template>
  <div>
    <DocumentForm
        :document="localDocument"
        :title="title"
        :type="type"
        :url="`/profile/documents`"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import DocumentForm from "../../components/Profile/Forms/DocumentForm";

export default {
  name: "RegisterDocumentUpload",
  props: ['document', 'type', 'title'],
  components: {
    DocumentForm
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_LOAD_DOCUMENT, async document => {
      this.localDocument = document
      this.next()
    });
  },
  data() {
    return {
      localDocument: null
    }
  },
  mounted() {
    this.localDocument = this.document
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_REFRESH_USER',

    ]),
    next() {
      if (!this.localDocument) {
        alert('Document not uploaded')
        return false
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/profile/increment-register-step`, {
        _method: 'put'
      })
          .then((resp) => {
            this.EXEC_INCREMENT_REGISTER_STEP()
            this.EXEC_REFRESH_USER(resp.data.data.user)
          }).catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
