<template>
  <div>
    <ProfilePresentable :profile="profile" v-if="profile">
      <template> <!-- v-if="allowReaction()" -->
        <v-row>
          <v-col>
            {{ showReaction() }}
          </v-col>
          <v-col>
            <v-btn color="green" @click="accept()">
              Да
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="red" @click="reject()">
              Нет
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProfilePresentable>

    <!--<template v-else>
      {{ showReaction() }}
    </template>-->
  </div>
</template>

<script>
import ProfilePresentable from "../../../components/Profile/ProfilePresentable";
import {mapActions, mapGetters} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: "DetailPage",
  components: {
    ProfilePresentable
  },
  data() {
    return {
      profile: null,
      reaction: null,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
    password: function () {
      let password = this.$route.params.password

      if (!password) {
        alert('hash undefined')
        return;
      }

      return password
    }
  },
  mounted() {
    this.invokeProfile()
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    invokeProfile() {
      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/vip/offers/${this.password}`)
          .then(res => {
            // this.localCandidateId = res.data.data.candidate_id
            this.profile = res.data.data.profile
            this.reaction = res.data.data.reaction
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    accept() {
      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/vip/offers/${this.password}/accept`, {
        _method: 'put'
      })
          .then(() => {
            // this.invokeProfile()
            document.location.reload();
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    reject() {
      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/vip/offers/${this.password}/reject`, {
        _method: 'put'
      })
          .then(() => {
            // this.invokeProfile()
            document.location.reload();
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    allowReaction() {
      return this.reaction === 'ignore'
    },
    showReaction() {
      return this.reaction
    }
  }
}
</script>

<style scoped>

</style>