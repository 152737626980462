<template>
  <div>
    <v-row>
      <v-col cols="11">
        <ProfileGrid :mode="userModesList.challengers"/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <v-navigation-drawer right fixed dark app disable-route-watcher enable-resize-watcher
                         v-model="drawer"
                         :clipped="false">
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <ProfileFilter class="mb-4" :mode="userModesList.challengers"/>

      <v-divider/>

    </v-navigation-drawer>
  </div>
</template>

<script>
import {crudActions, userModesList} from "@/enums/enums"
import ProfileGrid from "@/components/Profile/ProfileGrid";
import ProfileFilter from "@/components/Profile/ProfileFilter";

export default {
  name: "AdminChallengersPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    ProfileGrid,
    ProfileFilter,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    userModesList: function () {
      return userModesList
    },
  },
}
</script>

<style scoped>

</style>
