import Vue from 'vue'

export const eventBus = new Vue();

export const eventBusEvents = {
    EVENT_SMS_VERIFIED: 'eventBus_EVENT_SMS_VERIFIED',

    EVENT_LOAD_PHOTO: 'eventBus_EVENT_LOAD_PHOTO',
    EVENT_EVENT_REMOVE_PHOTO: 'eventBus_EVENT_EVENT_REMOVE_PHOTO',

    EVENT_CHANGE_SEX: 'eventBus_EVENT_CHANGE_SEX',
    EVENT_CHANGE_CASTE: 'eventBus_EVENT_CHANGE_CASTE',
    EVENT_CHANGE_PRESENTABLE: 'eventBus_EVENT_CHANGE_PRESENTABLE',
    EVENT_CHANGE_UFO: 'eventBus_EVENT_CHANGE_UFO',
    EVENT_CHANGE_BIRTHDAY: 'eventBus_EVENT_CHANGE_BIRTHDAY',
    EVENT_CHANGE_BIRTHTIME: "eventBus_EVENT_CHANGE_BIRTHTIME",
    EVENT_CHANGE_HEIGHT: "eventBus_EVENT_CHANGE_HEIGHT",
    EVENT_CHANGE_CITY_BORN: "eventBus_EVENT_CHANGE_CITY_BORN",
    EVENT_CHANGE_SEARCH_AGE: "eventBus_EVENT_CHANGE_SEARCH_AGE",
    EVENT_CHANGE_SEARCH_HEIGHT: "eventBus_EVENT_CHANGE_SEARCH_HEIGHT",
    EVENT_CHANGE_PREFER_CONNECTIONS: "eventBus_EVENT_CHANGE_PREFER_CONNECTIONS",
    EVENT_LOAD_DOCUMENT: "eventBus_EVENT_LOAD_DOCUMENT",
    EVENT_CHANGE_DICT: "eventBus_EVENT_CHANGE_DICT",
    EVENT_CHANGE_AGREE_VIDEO: "eventBus_EVENT_CHANGE_AGREE_VIDEO",
    EVENT_CHANGE_ABOUT: "eventBus_EVENT_CHANGE_ABOUT",

    EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN: "eventBus_EVENT_REFRESHS_WHATSAPP_MESSAGES_IN_CHAT_MAIN",
    EVENT_REFRESH_WHATSAPP_DIALOGS_MAIN: "eventBus_EVENT_REFRESH_WHATSAPP_DIALOGS_MAIN",

    EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_SPAM: "eventBus_EVENT_REFRESHS_WHATSAPP_MESSAGES_IN_CHAT_MAIN",
    EVENT_REFRESH_WHATSAPP_DIALOGS_SPAM: "eventBus_EVENT_REFRESH_WHATSAPP_DIALOGS_SPAM",

    EVENT_REFRESH_USER_BY_ADMIN: "eventBus_EVENT_CREATE_USER_BY_ADMIN",
    EVENT_EDIT_USER_BY_ADMIN: "eventBus_EVENT_EDIT_USER_BY_ADMIN",
    EVENT_DELETE_USER_BY_ADMIN: "eventBus_EVENT_DELETE_USER_BY_ADMIN",
    EVENT_FILTER_PROFILE: "eventBus_EVENT_FILTER_USER",
    EVENT_REGISTER_USER: "eventBus_EVENT_REGISTER_USER",
    EVENT_FILTER_DASHBOARD: "eventBus_EVENT_FILTER_DASHBOARD",
    // EVENT_UPDATE_USER_BY_ADMIN:  "eventBus_EVENT_UPDATE_USER_BY_ADMIN",
    // EVENT_CREATE_USER_BY_ADMIN:  "eventBus_EVENT_CREATE_USER_BY_ADMIN",

    EVENT_FILTER_USER_BY_STATUS: "eventBus_EVENT_FILTER_USER_BY_STATUS",
    EVENT_FILTER_USER_BY_VIEWED_MODERATOR: "eventBus_EVENT_FILTER_USER_BY_VIEWED_MODERATOR",

    EVENT_FILTER_USER_BY_STATUS_FOO: "eventBus_EVENT_FILTER_USER_BY_STATUS_FOO",
    EVENT_FILTER_USER_RESET: "eventBus_EVENT_FILTER_USER_RESET",
    EVENT_FILTER_USER_BY_AGE_RANGE: "eventBus_EVENT_FILTER_USER_BY_AGE_RANGE",
    EVENT_FILTER_PROFIT_BY_AGE_RANGE: "eventBus_EVENT_FILTER_PROFIT_BY_AGE_RANGE",

    EVENT_FILTER_TARIFF_USAGE: "eventBus_EVENT_FILTER_TARIFF_USAGE",
    EVENT_FILTER_TARIFF_USAGE_RESET: "eventBus_EVENT_FILTER_TARIFF_USAGE_RESET",

    EVENT_FILTER_ACQUIRING: "eventBus_EVENT_FILTER_ACQUIRING",

    // Календарь
    EVENT_CALENDAR_FILTER_RESET: "eventBus_EVENT_CALENDAR_FILTER_RESET",

    EVENT_EDIT_PROFILE_BY_ADMIN: "eventBus_EVENT_EDIT_PROFILE_BY_ADMIN",

    EVENT_EDIT_TARIFF_BY_ADMIN: "eventBus_EVENT_EDIT_TARIFF_BY_ADMIN",
    EVENT_REFRESH_TARIFF_BY_ADMIN: "eventBus_EVENT_REFRESH_TARIFF_BY_ADMIN",
    EVENT_CREATE_TARIFF_BY_ADMIN: "eventBus_EVENT_CREATE_TARIFF_BY_ADMIN",
    EVENT_FILTER_TARIFF: "eventBus_EVENT_FILTER_TARIFF",

    EVENT_EDIT_TARIFF_USAGE_BY_ADMIN: "eventBus_EVENT_EDIT_TARIFF_USAGE_BY_ADMIN",
    EVENT_REFRESH_TARIFF_USAGE_BY_ADMIN: "eventBus_EVENT_REFRESH_TARIFF_USAGE_BY_ADMIN",
    EVENT_CREATE_TARIFF_USAGE_BY_ADMIN: "eventBus_EVENT_CREATE_TARIFF_USAGE_BY_ADMIN",

    EVENT_EDIT_ACQUIRING_BY_ADMIN: "eventBus_EVENT_EDIT_ACQUIRING_BY_ADMIN",
    EVENT_REFRESH_ACQUIRING_BY_ADMIN: "eventBus_EVENT_REFRESH_ACQUIRING_BY_ADMIN",
    EVENT_CREATE_ACQUIRING_BY_ADMIN: "eventBus_EVENT_CREATE_ACQUIRING_BY_ADMIN",

    EVENT_EDIT_STOPPER_BY_ADMIN: "eventBus_EVENT_EDIT_STOPPER_BY_ADMIN",
    EVENT_REFRESH_STOPPER_BY_ADMIN: "eventBus_EVENT_REFRESH_STOPPER_BY_ADMIN",
    EVENT_CREATE_STOPPER_BY_ADMIN: "eventBus_EVENT_CREATE_STOPPER_BY_ADMIN",
    EVENT_FILTER_STOPPER: "eventBus_EVENT_FILTER_STOPPER",

    EVENT_EDIT_MISSING_CITY_BY_ADMIN: "eventBus_EVENT_EDIT_MISSING_CITY_BY_ADMIN",
    EVENT_REFRESH_MISSING_CITY_BY_ADMIN: "eventBus_EVENT_REFRESH_MISSING_CITY_BY_ADMIN",
    EVENT_CREATE_MISSING_CITY_BY_ADMIN: "eventBus_EVENT_CREATE_MISSING_CITY_BY_ADMIN",
    EVENT_FILTER_MISSING_CITY: "eventBus_EVENT_FILTER_MISSING_CITY",

    EVENT_REFRESH_PROMO_CATEGORY_BY_ADMIN: "eventBus_EVENT_REFRESH_PROMO_CATEGORY_BY_ADMIN",
    EVENT_EDIT_PROMO_CATEGORY_BY_ADMIN: "eventBus_EVENT_EDIT_PROMO_CATEGORY_BY_ADMIN",
    EVENT_CREATE_PROMO_CATEGORY_BY_ADMIN: "eventBus_EVENT_CREATE_PROMO_CATEGORY_BY_ADMIN",
    EVENT_FILTER_PROMO_CATEGORY: "eventBus_EVENT_FILTER_PROMO_CATEGORY",

    EVENT_REFRESH_PROMO_BY_ADMIN: "eventBus_EVENT_REFRESH_PROMO_BY_ADMIN",
    EVENT_EDIT_PROMO_BY_ADMIN: "eventBus_EVENT_EDIT_PROMO_BY_ADMIN",
    EVENT_CREATE_PROMO_BY_ADMIN: "eventBus_EVENT_CREATE_PROMO_BY_ADMIN",
    EVENT_FILTER_PROMO: "eventBus_EVENT_FILTER_PROMO",

    EVENT_REFRESH_PAIR_BY_ADMIN: "eventBus_EVENT_REFRESH_PAIR_BY_ADMIN",
    EVENT_FILTER_PAIR: "eventBus_EVENT_FILTER_PAIR",

    EVENT_REFRESH_BIOMETRIC_BY_ADMIN: "eventBus_EVENT_REFRESH_BIOMETRIC_BY_ADMIN",
    EVENT_EDIT_BIOMETRIC_BY_ADMIN: "eventBus_EVENT_EDIT_BIOMETRIC_BY_ADMIN",
    EVENT_CREATE_BIOMETRIC_BY_ADMIN: "eventBus_EVENT_CREATE_BIOMETRIC_BY_ADMIN",
    EVENT_FILTER_BIOMETRIC: "eventBus_EVENT_FILTER_BIOMETRIC",

    EVENT_REFRESH_BIOMETRIC_PHOTO_BY_ADMIN: "eventBus_EVENT_REFRESH_BIOMETRIC_PHOTO_BY_ADMIN",
    EVENT_EDIT_BIOMETRIC_PHOTO_BY_ADMIN: "eventBus_EVENT_EDIT_BIOMETRIC_PHOTO_BY_ADMIN",
    EVENT_CREATE_BIOMETRIC_PHOTO_BY_ADMIN: "eventBus_EVENT_CREATE_BIOMETRIC_PHOTO_BY_ADMIN",
    EVENT_FILTER_BIOMETRIC_PHOTO: "eventBus_EVENT_FILTER_BIOMETRIC_PHOTO",

    EVENT_REFRESH_NUMERIC_SETTING_BY_ADMIN: "eventBus_EVENT_REFRESH_NUMERIC_SETTING_BY_ADMIN",
    EVENT_EDIT_NUMERIC_SETTING_BY_ADMIN: "eventBus_EVENT_EDIT_NUMERIC_SETTING_BY_ADMIN",
    EVENT_CREATE_NUMERIC_SETTING_BY_ADMIN: "eventBus_EVENT_CREATE_NUMERIC_SETTING_BY_ADMIN",
    EVENT_FILTER_NUMERIC_SETTING: "eventBus_EVENT_FILTER_NUMERIC_SETTING",

    EVENT_REFRESH_BOOLEAN_SETTING_BY_ADMIN: "eventBus_EVENT_REFRESH_BOOLEAN_SETTING_BY_ADMIN",
    EVENT_EDIT_BOOLEAN_SETTING_BY_ADMIN: "eventBus_EVENT_EDIT_BOOLEAN_SETTING_BY_ADMIN",
    EVENT_CREATE_BOOLEAN_SETTING_BY_ADMIN: "eventBus_EVENT_CREATE_BOOLEAN_SETTING_BY_ADMIN",
    EVENT_FILTER_BOOLEAN_SETTING: "eventBus_EVENT_FILTER_BOOLEAN_SETTING",

    EVENT_EDIT_BOOLEAN_REGISTER_STEP_BY_ADMIN: "eventBus_EVENT_EDIT_BOOLEAN_REGISTER_STEP_BY_ADMIN",
    EVENT_REFRESH_BOOLEAN_REGISTER_STEP_BY_ADMIN: "eventBus_EVENT_REFRESH_BOOLEAN_REGISTER_STEP_BY_ADMIN",

    EVENT_REFRESH_FAQ_BY_ADMIN: "eventBus_EVENT_REFRESH_FAQ_BY_ADMIN",
    EVENT_EDIT_FAQ_BY_ADMIN: "eventBus_EVENT_EDIT_FAQ_BY_ADMIN",
    EVENT_CREATE_FAQ_BY_ADMIN: "eventBus_EVENT_CREATE_FAQ_BY_ADMIN",
    EVENT_FILTER_FAQ: "eventBus_EVENT_FILTER_FAQ",

    EVENT_REFRESH_SHEET_BY_ADMIN: "eventBus_EVENT_REFRESH_SHEET_BY_ADMIN",
    EVENT_EDIT_SHEET_BY_ADMIN: "eventBus_EVENT_EDIT_SHEET_BY_ADMIN",
    EVENT_CREATE_SHEET_BY_ADMIN: "eventBus_EVENT_CREATE_SHEET_BY_ADMIN",
    EVENT_FILTER_SHEET: "eventBus_EVENT_FILTER_SHEET",

    EVENT_REFRESH_MEETINGS_BY_ADMIN: "eventBus_EVENT_REFRESH_MEETINGS_BY_ADMIN",
    EVENT_EDIT_MEETINGS_BY_ADMIN: "eventBus_EVENT_EDIT_MEETINGS_BY_ADMIN",
    EVENT_CREATE_MEETINGS_BY_ADMIN: "eventBus_EVENT_CREATE_MEETINGS_BY_ADMIN",
    EVENT_FILTER_MEETINGS: "eventBus_EVENT_FILTER_MEETINGS",
}
