<template>
  <v-card>
    <v-card-title>
      Complete Press Btn
    </v-card-title>

    <v-card-actions>
      <v-btn
          color="deep-purple lighten-2"
          @click="next"
          text
      >
        Complete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: "RegisterComplete",
  methods: {
    ...mapActions([
      'COMPLETE_REGISTER',
      'EXEC_REFRESH_USER',
    ]),
    next() {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/profile/register-complete`, {
        _method: 'put'
      }).then((resp) => {
        this.COMPLETE_REGISTER()
        this.EXEC_REFRESH_USER(resp.data.data.user)
        this.$router.push('/Profile')
      }).catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
