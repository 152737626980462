<template>
  <v-card v-if="userLocal">
    <v-card-title>
      Модерация учетной записи:
      <v-divider/>
      <UserStatus :status="userLocal.status"/>
    </v-card-title>

    <v-card-actions>
      <v-btn class="" @click="submit()" :color="getColor()" fab x-small dark>
        <v-icon aria-hidden="false">
          {{ getIcon() }}
        </v-icon>
      </v-btn>

      <v-divider/>

      <v-btn v-if="!isRejected()" class="" @click="defineRejected()" color="primary" dark>
        Пометить как отклоненного
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {userStatusesEnum, userStatusesList} from "@/enums/enums";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import UserStatus from "./UserStatus";

export default {
  name: "UserStatusesControl",
  props: ['user'],
  components: {
    UserStatus
  },
  data() {
    return {
      userLocal: null
    }
  },
  computed: {
    userStatusesList: function () {
      return userStatusesList
    }
  },
  mounted() {
    this.userLocal = this.user
  },
  methods: {
    defineActive() {
      this.defineStatus(this.userStatusesList.active)
    },
    defineReModeration() {
      this.defineStatus(this.userStatusesList.on_re_moderation)
    },
    defineRejected() {
      this.defineStatus(this.userStatusesList.rejected)
    },
    getColor() {
      return userStatusesEnum.find(it => it.id === this.userLocal.status).color
    },
    getIcon() {
      return userStatusesEnum.find(it => it.id === this.userLocal.status).icon
    },
    submit() {
      if (this.isOnModeration() || this.isOnReModeration() || this.isOnRegister() || this.isRejected()) {
        this.defineActive()
      } else if (this.isActive()) {
        this.defineReModeration()
      } else if (this.isDeleted()) {
        // no no no
      }
    },
    isDeleted() {
      return this.userLocal.status === this.userStatusesList.deleted
    },
    isRejected() {
      return this.userLocal.status === this.userStatusesList.rejected
    },
    isOnRegister() {
      return this.userLocal.status === this.userStatusesList.on_register
    },
    isActive() {
      return this.userLocal.status === this.userStatusesList.active
    },
    isOnModeration() {
      return this.userLocal.status === this.userStatusesList.on_moderation
    },
    isOnReModeration() {
      return this.userLocal.status === this.userStatusesList.on_re_moderation
    },
    defineStatus(status) {
      let statusAction = status === this.userStatusesList.active ? 'activate' : (status === userStatusesList.rejected ? 'reject' : 'remoderate')

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${this.user.id}/${statusAction}`, {
        status,
        _method: 'put'
      })
          .then(resp => this.userLocal.status = resp.data.data.user.status)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
