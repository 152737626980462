<template>
  <v-card>
    <v-card-title>
      Тарифы

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="tariffs"
          :expanded.sync="expanded"
          :search="search"
          item-key="id"
          show-select
          :items-per-page="itemsPerPage"
          class="elevation-1"
      >
        <!--:sort-by="['id']"
        :sort-desc="[true]"-->

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn class=""
                 @click="edit(item)"
                 color="info"
                 fab
                 x-small
                 dark>

            <v-icon aria-hidden="false">
              mdi-pencil
            </v-icon>
          </v-btn>

          <AgreeDialog
              @remove="remove(item)"
              class="ml-2"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import AgreeDialog from "../AgreeDialog";
import DefaultStatus from "@/components/DefaultStatus";

const queryString = require('query-string');

export default {
  name: "UserGrid",
  mounted() {
    this.getTariffsList()
  },
  components: {
    AgreeDialog,
    DefaultStatus
  },
  data() {
    return {
      tariffs: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      itemsPerPage: 100,
      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'OS',
          align: 'start',
          sortable: true,
          value: 'os_type',
        },
        {
          text: 'Age',
          align: 'start',
          sortable: true,
          value: 'age_type',
        },
        {
          text: 'City',
          align: 'start',
          sortable: true,
          value: 'city_type',
        },
        {
          text: 'Product',
          align: 'start',
          sortable: true,
          value: 'product_id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Price',
          sortable: true,
          value: 'price',
        },
        {
          text: 'Sale',
          sortable: true,
          value: 'sale',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        /*
        {
            text: 'Man rate',
            sortable: true,
            value: 'man_rate',
        },
        {
            text: 'Woman rate',
            sortable: true,
            value: 'woman_rate',
        },
        {
            text: 'Meetings',
            sortable: true,
            value: 'meetings',
        },
        {
            text: 'Incognito',
            align: 'start',
            sortable: true,
            value: 'is_incognito',
        },
        {
            text: 'Body',
            sortable: true,
            value: 'body',
        },
        */
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CREATE_TARIFF_BY_ADMIN, async () =>
        this.getTariffsList()
    );
    eventBus.$on(eventBusEvents.EVENT_REFRESH_TARIFF_BY_ADMIN, async () =>
        this.getTariffsList()
    );
    eventBus.$on(eventBusEvents.EVENT_FILTER_TARIFF, async search => {
      this.queryParams = search
      this.getTariffsList()
    });
  },
  methods: {
    getTariffsList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/tariffs?${q}`)
          .then(response => this.tariffs = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    edit(tariff) {
      eventBus.$emit(eventBusEvents.EVENT_EDIT_TARIFF_BY_ADMIN, tariff)
    },
    remove(tariff) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/tariffs/${tariff.id}`)
          .then(response => {
            console.log('tariff removed', response.data)
            this.getTariffsList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    changeProfile(tariff) {
      eventBus.$emit(eventBusEvents.EVENT_EDIT_PROFILE_BY_ADMIN, tariff.id)
    }
  }
}
</script>

<style scoped>

</style>
