<template>
  <div>
    <v-card class="mt-4 grey lighten-5">
      <v-card-title>
        {{ title }} - {{ getTotal() }}
        <v-divider/>
        <v-btn @click="changeDisplayMode()">Изменить режим отображения</v-btn>
      </v-card-title>

      <v-card-text>
        <div v-show="mode === 'bar'">
          <apexchart ref="refChartBar"
                     width="500"
                     type="bar"
                     :options="bar.chartOptions"
                     :series="bar.series"
          />
        </div>

        <div v-show="mode === 'donut'">
          <apexchart
              ref="refChartDonut"
              width="800"
              type="donut"
              :options="donut.chartOptions"
              :series="donut.series"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
const _ = require('lodash')

export default {
  name: "UniversalDashboard",
  props: ['url', 'list', 'title'],
  mounted() {
    this.init()
  },
  data() {
    return {
      mode: 'bar',

      bar: {
        series: [],
        chartOptions: {
          chart: {
            events: {
              click: (event, chartContext, config) => {
                let eventAttribute = event.target.attributes['j']
                if (!eventAttribute || !eventAttribute.value) return
                let result = config.globals.labels[eventAttribute.value]
                let bufferCode = result.split('-')[0].trim()

                console.log('result____', result)

                // todo release callbacks
                if (this.title === 'Возраст') {
                  let agesArr = result.split('-')
                  this.$router.push(`/admin/profiles?age_from=${agesArr[0]}&age_to=${agesArr[1]}`)
                } else {
                  let code = this.list.find(it => it.title === bufferCode).code

                  if (this.url) {
                    this.$router.push(this.url.replace('code', code))
                  }
                }

                // ages ?
              }
            },
            width: 380,
            type: 'bar'
          }
        }
      },
      donut: {
        series: [],
        chartOptions: {
          chart: {
            events: {
              click: (event, chartContext, config) => {
                let eventAttribute = event.target.attributes['j']
                if (!eventAttribute || !eventAttribute.value) return
                let result = config.globals.labels[eventAttribute.value]

                // todo release callbacks
                if (this.title === 'Возраст') {
                  let agesArr = result.split('-')
                  this.$router.push(`/admin/profiles?age_from=${agesArr[0]}&age_to=${agesArr[1]}`)
                } else {
                  let code = this.list.find(it => it.title === result).code
                  this.$router.push(this.url.replace('code', code))
                }
              }
            },
            width: 380,
            type: 'donut',
          }
        }
      }
    }
  },
  methods: {
    changeDisplayMode() {
      this.mode = this.mode === 'donut' ? 'bar' : 'donut'
      // this.getUserStatuses()
    },
    getTotal() {
      return this.list.reduce(function (prev, cur) {
        return prev + cur.value
      }, 0)
    },
    init() {
      let list = this.list

      /** donut */
      this.donut.chartOptions = {
        labels: list.map(it => it.title)
      }
      this.donut.series = list.map(it => it.value)

      // console.log('step1')

      this.$refs.refChartDonut.updateSeries([{
        data: list.map(it => it.value)
      }])

      // console.log('step2')

      /** bar*/
      this.bar.chartOptions = {
        xaxis: {
          categories: this.list.map(it => `${it.title} - ${it.value}`)
        }
      };
      this.$refs.refChartBar.updateSeries([{
        data: this.list.map(it => it.value)
      }])
    }
  },
  watch: {
    list: {
      handler: _.debounce(function (v) {
        this.init(v)
      }, 1000),
      deep: true,
    },
  }

}
</script>

<style scoped>

</style>
