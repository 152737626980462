<template>
  <v-card>
    <v-card-title>
      RegisterSearchAge
    </v-card-title>

    <v-card-text>
      <v-range-slider
          v-model="ageRangeLocal"
          hint="Im a hint"
          label="Age"
          :min="min"
          :max="max"
          color="red"
          thumb-label="always"
      ></v-range-slider>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "SearchAgePanel",
  props: ['ageRange', 'profile'],
  data() {
    return {
      ageRangeLocal: [25, 35],
      min: 18,
      max: 84,
    }
  },
  mounted() {
    this.ageRangeLocal = this.ageRange || [25, 35]
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    change() {
      if (!this.ageRangeLocal) {
        alert('ageRange is empty')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/search-age` : `/profile/search-age`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        search_age_from: this.ageRangeLocal[0],
        search_age_to: this.ageRangeLocal[1],
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_SEARCH_AGE, this.ageRangeLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
