<template>
    <span>
        <v-icon aria-hidden="false">
            {{ getIcon() }}
        </v-icon>
        {{ os_type }}
    </span>
</template>

<script>
import {osTypeEnum} from "@/enums/enums";

export default {
  name: "OsTypeShop",
  props: ['os_type'],
  methods: {
    getIcon() {
      // todo пока поиск по id или по названию. Привести к стрингам.
      let osType = osTypeEnum.find(it =>
          it.id === this.os_type || it.name === this.os_type || it.alias === this.os_type
      )

      return osType ? osType.icon : null
    }
  }
}
</script>

<style scoped>

</style>
