<template>
  <v-row>
    <v-col cols=11>
      <UniversalDashboard
          :title="'Статусы учетных записей'"
          :url="'/admin/profiles?user_status=code'"
          :list="this.userStatuses"
          v-if="this.userStatuses.length"
      />

      queryParams: {{ queryParams }}
    </v-col>

    <v-col cols=1>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-navigation-drawer v-model="drawer"
                           :clipped="false"
                           right fixed dark app disable-route-watcher enable-resize-watcher
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"/>

        <UserStatusesDashboardFilter class="mb-4"/>

        <v-divider/>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import {objectToQueryString} from "@/utils/helpers"
import {converValidationServerErrorsToString} from "@/utils/errorUtil"
import {eventBus, eventBusEvents} from "@/eventBus/eventBus"
import UserStatusesDashboardFilter from "./UserStatusesDashboardFilter";
import UniversalDashboard from "../UniversalDashboard";

const queryString = require('query-string')

export default {
  name: "UserStatusesDashboard",
  components: {
    UserStatusesDashboardFilter,
    UniversalDashboard
  },
  data() {
    return {
      drawer: false,
      userStatuses: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_USER_BY_STATUS_FOO, async search => {
      // console.log('EVENT_FILTER_DASHBOARD', search)
      console.log('oppa')
      this.queryParams = search
      this.getUserStatuses()
    })
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getUserStatuses()
  },
  methods: {
    getUserStatuses() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/user-statuses?${q}`)
          .then(response => this.userStatuses = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
  }
}
</script>

<style scoped>

</style>
