<template>
  <v-card>
    <v-card-title>
      Пульт управления:

      <template v-if="!isActionCreate()">
                <span style="color: dodgerblue; cursor: pointer" @click="goToCreate()">
                    Вернуться в добавление
                </span>
        <br/>
        <br/>
      </template>
    </v-card-title>

    <v-card-text>
      <PromoCategoryForm :action="crudActions.create"/>
    </v-card-text>
  </v-card>
</template>

<script>
import {crudActions} from "@/enums/enums";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import PromoCategoryForm from "./PromoCategoryForm";

export default {
  name: "TariffPanel",
  props: ['action'],
  components: {
    PromoCategoryForm
  },
  data() {
    return {
      actionLocal: null
    }
  },
  mounted() {
    this.actionLocal = this.action
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_PROMO_CATEGORY_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.update
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PROMO_CATEGORY_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.create
    });
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
  methods: {
    goToCreate() {
      this.actionLocal = this.crudActions.create
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_PROMO_CATEGORY_BY_ADMIN)
    },
    isActionCreate() {
      return this.actionLocal === this.crudActions.create
    },
    isActionUpdate() {
      return !this.isActionCreate()
    }
  }
}
</script>

<style scoped>

</style>
