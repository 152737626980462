<template>
  <div>
    foo
    <v-row>
      <v-col cols="11">
        <ProfileGrid :mode="userModesList.profiles"/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <!--<UserPanel :action="crudActions.create" />-->

    <v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher
                         :clipped="false">
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <ProfileFilter
          class="mb-4" :mode="userModesList.profiles"
      />

      <v-divider/>

    </v-navigation-drawer>
  </div>
</template>

<script>
import {crudActions, userModesList} from "@/enums/enums";
import ProfileFilter from "@/components/Profile/ProfileFilter";
import ProfileGrid from "@/components/Profile/ProfileGrid";
// import UserPanel from "../../components//User/UserPanel";

export default {
  name: "MatchmakerProfilesPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    ProfileGrid,
    ProfileFilter,
    // UserPanel,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    userModesList: function () {
      return userModesList
    }
  }
}
</script>

<style scoped>

</style>
