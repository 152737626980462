<template>
  <v-card>
    <v-card-title>
      Информация о себе
    </v-card-title>

    <v-card-text>
      <v-text-field v-model="aboutLocal" :rules="aboutRules"/>

      <p v-if="aboutLocal">
        about: {{ aboutLocal }}
      </p>

      <p v-if="aboutLocalDraft">
        about_draft: {{ aboutLocalDraft }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Обновить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: 'AboutForm',
  props: ['about', 'about_draft', 'profile'],
  data() {
    return {
      aboutLocal: null,
      aboutLocalDraft: null,
      aboutRules: [
        v => !!v || 'About is required',
        v => (v && v.length <= 500) ||
            `About must be less than 500 characters`,
      ]
    }
  },
  mounted() {
    this.aboutLocal = this.about
    this.aboutLocalDraft = this.about_draft
  },
  methods: {
    change() {
      // if (!this.aboutLocal) {
      //   alert('About is empty')
      //   return false
      // }

      // todo на случай микса
      let url = this.profile ? `/admin/profiles/${this.profile.id}/about` : `/profile/about`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        about: this.aboutLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_ABOUT, this.aboutLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)))
    }
  }
}
</script>

<style scoped>

</style>
