<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="faqLocal.question"
          :rules="rules.questionRules"
          label="question"
          type="tel"
          @keyup.enter="handleSubmit"
          required
      />

      <v-text-field
          v-model="faqLocal.answer"
          :rules="rules.answerRules"
          label="answer"
          type="text"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";

require('dotenv').config()

export default {
  name: "FaqForm",
  props: ['action', 'faq'],
  data() {
    return {
      valid: true,

      actionLocal: null,

      faqLocal: {
        question: "",
        answer: "",
      },
      rules: {
        questionRules: [
          v => !!v || 'Question must be valid',
          v => v.length > 2 || 'Question length must be more than 2 characters',
        ],
        answerRules: [
          v => !!v || 'Answer must be valid',
          v => v.length > 20 || 'Answer length must be more than 20 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
    }
  },
  created() {
    this.actionLocal = this.action

    if (this.faq) {
      this.faqLocal = this.faq

      this.edit(this.faqLocal)
    }

    eventBus.$on(eventBusEvents.EVENT_EDIT_FAQ_BY_ADMIN, async faq => {
      console.log('EDIT_FAQ_BY_ADMIN', faq)
      this.edit(faq)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_FAQ_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    if (this.actionLocal === this.crudActions.update) {
      this.faqLocal = this.meetings
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.faqLocal.id = data.id
      this.faqLocal.question = data.question
      this.faqLocal.answer = data.answer
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.faqLocal.id = null
      this.faqLocal.question = null
      this.faqLocal.answer = null
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      console.log('faq_form_create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/faq`,
          this.faqLocal
      ).then(resp => {
        const faq = resp.data.data.faq
        console.log('Created faq: ', faq)
        eventBus.$emit(eventBusEvents.EVENT_CREATE_FAQ_BY_ADMIN, faq)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    update() {
      console.log('faq_form_update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.faqLocal,
        ...{
          _method: 'put'
        }
      }

      console.log('faq_update_data___', data)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/faq/${this.faqLocal.id}`,
          data
      ).then(() => {
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_FAQ_BY_ADMIN)
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.faqLocal.question) {
        alert('question is empty')
        return false
      }
      if (!this.faqLocal.answer) {
        alert('answer is empty')
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
