<template>
  <div>
    <v-row>
      <v-col cols="5">
        <DialogList/>
      </v-col>

      <v-col>
        <MessageList :chat_type="whatsappTypes.main"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DialogList from "./Dialog/List";
import MessageList from "./Message/List";
import {whatsappTypes} from "../../enums/enums";

export default {
  name: "Whatsapp",
  components: {
    DialogList,
    MessageList,
  },
  computed: {
    whatsappTypes: function () {
      return whatsappTypes
    }
  }
}
</script>

<style scoped>

</style>
