<template>
  <div>
    <h4 class="my-4">Рейтинг страницы: {{ profile.rating_worksheet }}</h4>

    <h4 class="my-4">Заполненность: {{ profile.filling_profile }}</h4>

    <h4 class="my-4">Шарм: {{ profile.rating_charm }}</h4>

    <v-divider/>

    <h4 class="my-4">Статусы профиля:</h4>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Статус поиска</td>
          <td>
            <StatusSearch :status="profile.search_status"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h4 class="my-4">информация по предложениям</h4>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Баланс</td>
          <td>
            {{ profile.balance }}
          </td>
        </tr>

        <tr>
          <td>Суммарно свиданий (включая блат)</td>
          <td>
            {{ profile.meetings_total_count }}
          </td>
        </tr>

        <tr>
          <td>Куплено свиданий в приложении</td>
          <td>
            {{ profile.meetings_purchase_count }}
          </td>
        </tr>

        <tr>
          <td>Куплено свиданий на сайте</td>
          <td>
            {{ profile.meetings_by_acquiring_count }}
          </td>
        </tr>

        <tr>
          <td>Уже предложено</td>
          <td>
            {{ profile.offers_count }}
          </td>
        </tr>

        <tr>
          <td>Кол-во возможных предложений</td>
          <td>
            {{ profile.available_offers_count }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br/>

    <template>
      <h4 class="my-4">Устройства:</h4>

      <v-simple-table>
        <template v-slot:default>
          <tbody>
          <tr v-for="device in profile.devices" :key="device.id">
            <td>{{ device.os }}</td>
            <td>
              {{ device.model }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <br>

    <v-divider/>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Баланс</td>
          <td>{{ profile.balance }}</td>
        </tr>
        <tr>
          <td>is_seed</td>
          <td>{{ profile.is_seed }}</td>
        </tr>
        <tr>
          <td>Бонус активирован?</td>
          <td>{{ profile.is_bonus_activate }}</td>
        </tr>
        <tr>
          <td>Кол-во предложенных пар</td>
          <td>{{ profile.offers_count }}</td>
        </tr>

        <tr :class="getRowClass(profile.city_live_id)">
          <td>Город поиска</td>
          <td>{{ getCityName() }}</td>
        </tr>

        <tr>
          <td>Sex</td>
          <td>{{ profile.sex }}</td>
        </tr>
        <!--todo в акканут-->
        <!--<tr>
            <td>Пригласил:</td>
            <td>{{ profile.user.applicant_id }}</td>
        </tr>-->
        </tbody>
      </template>
    </v-simple-table>

    <v-divider/>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr :class="getRowClass(profile.height)">
          <td>Рост</td>
          <td>{{ profile.height }}</td>
        </tr>
        <tr :class="getRowClass">
          <td>Рост кандидата</td>
          <td>{{ profile.search_height_from }} - {{ profile.search_height_to }}</td>
        </tr>
        <tr :class="getRowClass">
          <td>Возраст</td>
          <td>{{ profile.age }}</td>
        </tr>
        <tr>
          <td>Возраст кандидата</td>
          <td>{{ profile.search_age_from }} - {{ profile.search_age_to }}</td>
        </tr>

        <tr :class="getRowClass(profile.city_born)">
          <td>Город рождения</td>
          <td>{{ profile.city_born }}</td>
        </tr>

        <tr :class="getRowClass(profile.city_born)">
          <td>timezone</td>
          <td>{{ profile.timezone_born }}</td>
        </tr>

        <tr :class="getRowClass(profile.city_born)">
          <td>LatLng</td>
          <td>{{ profile.latitude }}:{{ profile.longitude }}</td>
        </tr>

        <tr :class="getRowClass(profile.birthtime)">
          <td>Время рождения</td>
          <td>{{ profile.birthtime }}</td>
        </tr>
        <tr>
          <td>zodiac</td>
          <td>{{ profile.zodiac }}</td>
        </tr>
        <tr>
          <td>День рождения</td>
          <td>{{ profile.birthday }}</td>
        </tr>

        <tr :class="getRowClass(profile.about)">
          <td>Информация о себе</td>
          <td>{{ profile.about }}</td>
        </tr>
        <tr :class="getRowClass(profile.photos.length >= 1)">
          <td>Кол-во фотографий</td>
          <td>{{ profile.photos.length }}</td>
        </tr>
        <tr :class="getRowClass(profile.languages.length >= 1)">
          <td>Кол-во языков</td>
          <td>{{ profile.languages.length }}</td>
        </tr>
        <tr :class="getRowClass(profile.interests.length >= 1)">
          <td>Кол-во интересов</td>
          <td>{{ profile.interests.length }}</td>
        </tr>
        <tr :class="getRowClass(profile.income_id)" v-if="profile.sex === 1">
          <td>
            Доход
          </td>
          <td>
            {{ !!profile.income_id ? profile.income.name : profile.income_id }}
          </td>
        </tr>
        <tr :class="getRowClass(profile.features.length)" v-else>
          <td>
            Кол-во предпочтений по свиданию
          </td>
          <td>
            {{ profile.features.length }}
          </td>
        </tr>

        <tr :class="getRowClass(profile.education_id)">
          <td>Образование</td>
          <td>{{ !!profile.education_id ? profile.education.name : profile.education_id }}</td>
        </tr>
        <tr :class="getRowClass(profile.professional_status_id)">
          <td>Проф. статус</td>
          <td>{{
              !!profile.professional_status_id ? profile.professional_status.name :
                  profile.professional_status_id
            }}
          </td>
        </tr>
        <tr :class="getRowClass(profile.sphere_id)">
          <td>Сфера деятельности</td>
          <td>{{ !!profile.sphere_id ? profile.sphere.name : profile.sphere_id }}</td>
        </tr>
        <tr :class="getRowClass(profile.constitution_id)">
          <td>Телосложение</td>
          <td>{{ !!profile.constitution_id ? profile.constitution.name : profile.constitution_id }}</td>
        </tr>
        <tr :class="getRowClass(profile.marital_status_id)">
          <td>Семейное положение</td>
          <td>{{ !!profile.marital_status_id ? profile.marital_status.name : profile.marital_status_id }}</td>
        </tr>

        <tr :class="getRowClass(profile.children_status_id)">
          <td>Дети</td>
          <td>{{ !!profile.children_status_id ? profile.children_status.name : profile.children_status_id }}
          </td>
        </tr>
        <tr :class="getRowClass(profile.smoke_status_id)">
          <td>Отношение к курению</td>
          <td>{{ !!profile.smoke_status_id ? profile.smoke_status.name : profile.smoke_status_id }}</td>
        </tr>
        <tr :class="getRowClass(profile.religion_id)">
          <td>Религия</td>
          <td>{{ !!profile.religion_id ? profile.religion.name : profile.religion_id }}</td>
        </tr>

        <tr :class="getRowClassForConnections(profile)">
          <td>Предпочтение связи по телефону</td>
          <td>{{ profile.is_phone_prefer }} - {{ profile.phone }}</td>
        </tr>
        <tr :class="getRowClassForConnections(profile)">
          <td>Предпочтение связи по whatsapp</td>
          <td>{{ profile.is_whatsapp_prefer }} - {{ profile.whatsapp }}</td>
        </tr>
        <tr :class="getRowClassForConnections(profile)">
          <td>Предпочтение связи по telegram</td>
          <td>{{ profile.is_telegram_prefer }} - {{ profile.telegram }}</td>
        </tr>
        <tr :class="getRowClassForConnections(profile)">
          <td>Предпочтение связи по viber</td>
          <td>{{ profile.is_viber_prefer }} - {{ profile.viber }}</td>
        </tr>
        <tr :class="getRowClassForConnections(profile)">
          <td>Предпочтение связи по email</td>
          <td>{{ profile.is_email_prefer }} - {{ profile.email }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <br>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>is_register_complete</td>
          <td>{{ profile.is_register_complete }}</td>
        </tr>
        <tr>
          <td>Текущий шаг регистрации</td>
          <td>
            {{ profile.register_step_id }} -
            <span v-if="!!profile.register_step">
                            {{ profile.register_step.name }}
                        </span>
          </td>
        </tr>
        <tr>
          <td>Шаги регистрации</td>
          <td>
            <VueJsonPretty :path="'res'" :data="profile.register_steps"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {profilePairStatusesEnum, profileSearchStatusesEnum,} from "@/enums/enums";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import StatusSearch from "./StatusSearch";

require('dotenv').config()

export default {
  name: 'ProfileTechInfo',
  props: ['profile'],
  components: {
    VueJsonPretty,
    StatusSearch
  },
  data() {
    return {}
  },
  mounted() {
  },
  computed: {},
  methods: {
    isFilled(param) {
      return !!param
    },
    isEmpty(param) {
      return !this.isFilled(param)
    },
    getRowClass(param) {
      return {'red': this.isEmpty(param)}
    },
    getRowClassForConnections(profile) {
      let connections = [
        'is_phone_prefer',
        'is_whatsapp_prefer',
        'is_telegram_prefer',
        'is_viber_prefer',
        'is_email_prefer',
      ]

      return {'red': !connections.filter(it => profile[it]).length}
    },
    getProfileSearchStatusName(status) {
      return profileSearchStatusesEnum.find(it => it.id === status).title
    },
    getProfilePairStatusName(status) {
      return profilePairStatusesEnum.find(it => it.id === status).name
    },
    // todo doublicate
    getCityName() {
      return !this.profile.city_live ? this.profile?.missing_cities?.map(it => it.name)?.join(',') : this.profile.city_live.name
    }
  }
}
</script>

<style scoped>

</style>
