import axios from "axios";

require('dotenv').config()

export default class RequestService {
    static send(url, data, okCb, errCb) {
        return new Promise((resolve, reject) => {
            axios({
                url: `${process.env.VUE_APP_SERVER_BASE_URL}${url}`,
                data: data,
                method: 'post'
            }).then(resp => {
                okCb(resp.data)
                resolve(resp)
            }).catch(err => {
                errCb(err)
                reject(err)
            })
        })
    }
}
