<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-select
          v-model="checked"
          @change="change"
          :items="items"
          item-text="name"
          item-value="id"
          single-line
          chips
          multiple
      />
    </v-card-text>

    <!--<v-card-actions>
        <v-btn color="deep-purple lighten-2" @click="change" text>
            Change
        </v-btn>
    </v-card-actions>-->
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "DictMultiplePanel",
  props: [
    'dictMethod',
    'changeMethod',
    'postArgument',
    'title',
    'values'
  ],
  data() {
    return {
      checked: [],
      items: [],
    }
  },
  mounted() {
    this.checked = Array.isArray(this.values) ? this.values.map(it => it.id) : []

    // this.checked = this.values
    this.getList()
  },
  methods: {
    ...mapActions([
      'EXEC_CHANGE_PROFILE_PARAM'
    ]),
    getList() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}${this.dictMethod}`).then(resp => {
        // console.log(resp.data)
        this.items = resp.data.data.items
      })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    change: function () {
      const url = `${process.env.VUE_APP_SERVER_BASE_URL}${this.changeMethod}`

      let postData = this.checked.filter(it => !!it)

      this.$http.post(url, {
        [this.postArgument]: postData
      }).then(() => {
        let resultKeyValuePairsArr = this.items.filter(it => this.checked.includes(it.id))

        this.EXEC_CHANGE_PROFILE_PARAM({
          key: this.postArgument,
          value: resultKeyValuePairsArr
        })

        eventBus.$emit(eventBusEvents.EVENT_CHANGE_DICT, {
          key: this.postArgument,
          value: resultKeyValuePairsArr
        })
      }).catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
