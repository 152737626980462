<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" :color="getIconColor()" v-bind="attrs" v-on="on" fab x-small dark>
        <v-icon aria-hidden="false">
          {{ getIcon() }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          Пользователь
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn dark text @click="dialog = false">
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- user_id: {{ user_id }} <br/>-->
      <!-- profile: {{ profile }} <br/>-->

      <UserPanel
          :action="actionLocal"
          :user_id="user_id"
          :profile="profile"
      />

      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import {crudActions} from "@/enums/enums";
import UserPanel from "./UserPanel";

export default {
  name: 'UserControlDialog',
  props: [
    'user_id',
    'profile',
    'action',
  ],
  components: {
    UserPanel
  },
  mounted() {
    this.actionLocal = this.action
  },
  data() {
    return {
      dialog: false,
      actionLocal: null
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  methods: {
    getIcon() {
      return this.user_id ? 'mdi-pencil' : 'mdi-account-plus'
    },

    getIconColor() {
      return this.user_id ? 'orange' : 'info'
    }
  },
}
</script>

<style scoped>

</style>
