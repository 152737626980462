<template>
  <v-icon :color="getColor(status)">
    {{ getIcon(status) }}
  </v-icon>
</template>

<script>
import {profileSearchStatusesEnum} from "../../enums/enums";

export default {
  name: "StatusSearch",
  props: ['status'],
  methods: {
    getIcon() {
      return profileSearchStatusesEnum.find(it => it.id === this.status).icon
    },
    getColor() {
      return profileSearchStatusesEnum.find(it => it.id === this.status).color
    },
  }
}
</script>

<style scoped>

</style>
