<template>
  <div>
    <UniversalDashboard
        :title="'Статусы поиска'"
        :url="'/admin/profiles?search_status=code'"
        :list="this.profileStatuses"
        v-if="this.profileStatuses.length"
    />
  </div>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {objectToQueryString} from "@/utils/helpers";
import UniversalDashboard from "../UniversalDashboard";

const queryString = require('query-string');

export default {
  name: "ProfileStatusesDashboard",
  components: {
    UniversalDashboard
  },
  data() {
    return {
      profileStatuses: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_DASHBOARD, async search => {
      console.log('EVENT_FILTER_DASHBOARD', search)
      this.queryParams = search
      this.getProfileStatuses()
    });
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getProfileStatuses()
  },
  methods: {
    getProfileStatuses() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/profile-statuses?${q}`)
          .then(response => this.profileStatuses = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
