<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">User Profile</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="smsCode"
                    label="sms code*"
                    autofocus
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
              color="blue darken-1"
              @click="sendSms"
              text> Submit
          </v-btn>
          <v-btn
              color="red darken-1"
              @click="dialog = false"
              text> Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
        @click="dialog = !dialog"
        color="red"
        class="mt-4"> Enter SMS code
    </v-btn>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import RequestService from "../services/RequestService";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "SmsVerifier",

  data() {
    return {
      smsCode: '',
      dialog: false
    }
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_PROCESSING_REQUEST',
    ]),
    sendSms() {
      let okCb = async () => {
        this.dialog = false
        console.log('success verify.')
        this.EXEC_INCREMENT_REGISTER_STEP()
        eventBus.$emit(eventBusEvents.EVENT_SMS_VERIFIED)
      }

      let errCb = (err) => {
        this.dialog = false
        alert(converValidationServerErrorsToString(err))
      }

      this.EXEC_PROCESSING_REQUEST(true)

      RequestService.send('/verify/by-phone', {
        token: this.smsCode
      }, okCb, errCb).then(() => {
        this.dialog = false
      })
          .catch(() => {
          })
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
  }
}
</script>

<style scoped>

</style>
