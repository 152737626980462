<template>
  <v-card>
    <v-card-title>
      Фильтр
    </v-card-title>

    <v-card-text>
      <v-text-field v-model="search.biometric.name" label="Наименование"/>

      <v-row>
        <v-col>
          <v-select v-model="search.biometric.status"
                    :items="statuses"
                    label="Статус"
                    item-text="name"
                    item-value="id"
          />

        </v-col>

        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn
                @click="search.profile.status = null"
                color="warning"
                x-small
                dark
            >
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus"
import {cities, defaultStatusesEnum} from "@/enums/enums"

const _ = require('lodash')

export default {
  name: "BiometricFilter",
  components: {},
  data() {
    return {
      ageMin: 18,
      ageMax: 84,

      search: {
        biometric: {
          name: null,
          status: null,
        },
      }
    }
  },
  computed: {
    cities: function () {
      return cities
    },
    statuses: function () {
      return defaultStatusesEnum
    },
  },
  methods: {
    commitFilter(data) {
      let modifyProfile = {}
      let modifyUser = {}

      Object.keys(data.user)
          .filter(key => data.user[key] !== null && data.user[key] !== '')
          .forEach(key => modifyUser[`${key}`] = data.user[key])

      Object.keys(data.profile)
          .filter(key => key !== 'age_range' && !!data.profile[key])
          .forEach(key => modifyProfile[`profile_${key}`] = data.profile[key])

      let result = {...modifyUser, ...modifyProfile}
      result.profile_age_from = data.profile.age_range[0]
      result.profile_age_to = data.profile.age_range[1]

      eventBus.$emit(eventBusEvents.EVENT_FILTER_PROFILE, result)
    }
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.commitFilter(v)
      }, 1000),
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
