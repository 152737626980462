<template>
  <div>
    <v-row>
      <v-col cols="11">
        <BiometricGrid/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <v-navigation-drawer
        v-model="drawer"
        right
        fixed
        dark
        app
        disable-route-watcher
        enable-resize-watcher
        :clipped="false"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <BiometricFilter class="mb-4"/>

      <v-divider/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import BiometricFilter from "../../components/Biometric/BiometricFilter";
import BiometricGrid from "../../components/Biometric/BiometricGrid";

export default {
  name: "AdminBiometricsPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    BiometricGrid,
    BiometricFilter,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
