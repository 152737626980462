<template>
  <div>
    <ProfileControlDialog :profile_id="person.profile.id" @close="close()"/>

    <!--<PairReaction class="mt-4" :reaction="person.reaction"/>-->

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">

        <v-icon color="grey" v-if="person.is_viewed">
          mdi-eye
        </v-icon>

        <span v-bind="attrs" v-on="on">
            <MemberNameColorReaction :member="person"/>
        </span>
      </template>

      <span>
           {{ getTooltipInfo(person.profile) }}
        </span>
    </v-tooltip>

    <template v-if="person.handshake">
      <br/>
      <span :class="getHandshakeColor(person.handshake)">
        {{ getHandshakeDisplay(person.handshake) }}
        <br/>
        {{ convertUtcToDate(person, person.handshake) }}
      </span>
    </template>
  </div>
</template>

<script>
import ProfileControlDialog from "@/components/Profile/ProfileControlDialog";
import MemberNameColorReaction from "@/components/Member/MemberNameColorReaction";
import {convertUtcToDate} from "@/utils/converters";

export default {
  name: "PairGridPersonCell",
  components: {
    ProfileControlDialog,
    MemberNameColorReaction,
  },
  props: ['person'],
  methods: {
    getHandshakeDisplay(handshake) {
      return handshake.status === 'reject' ? 'Отказался' : 'Отправил рукопожатие'
    },
    getHandshakeColor(handshake) {
      return handshake.status === 'reject' ? 'red' : 'green'
    },
    convertUtcToDate(person, handshake) {
      return convertUtcToDate(
          handshake.created_at, person?.profile?.city?.timezone
      )
    },
    getTooltipInfo(profile) {
      return `${profile.user_id}_${profile.id}. ${profile.phone}`
    },
  }
}
</script>

<style scoped>

</style>
