<template>
  <div>
    <v-row>
      <v-col cols=11>
        <UniversalDashboard
            :title="'Реакции'"
            :url="null"
            :list="this.reactions"
            v-if="this.reactions.length"
        />
      </v-col>

      <v-col cols=1>
        <v-app-bar-nav-icon @click="drawer = !drawer"/>

        <v-navigation-drawer v-model="drawer"
                             :clipped="false"
                             right fixed dark app disable-route-watcher enable-resize-watcher
        >
          <v-app-bar-nav-icon @click="drawer = !drawer"/>

          <ReactionsDashboardFilter class="mb-4"/>

          <v-divider/>

        </v-navigation-drawer>
      </v-col>
    </v-row>

    queryParams: {{ queryParams }}
  </div>
</template>

<script>
import ReactionsDashboardFilter from "./ReactionsDashboardFilter";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {objectToQueryString} from "@/utils/helpers";
import UniversalDashboard from "../UniversalDashboard";

const queryString = require('query-string');

export default {
  name: 'ReactionsDashboard',
  components: {
    ReactionsDashboardFilter,
    UniversalDashboard
  },
  data() {
    return {
      drawer: false,
      reactions: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_DASHBOARD, async search => {
      // console.log('EVENT_FILTER_DASHBOARD', search)
      this.queryParams = search
      this.getReactions()
    })
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getReactions()
  },
  methods: {
    getReactions() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/reactions?${q}`)
          .then(response => this.reactions = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
