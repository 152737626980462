<template>
  <v-card>
    <v-card-title>
      Шаги регистрации

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="registerSteps"
          :expanded.sync="expanded"
          :search="search"
          item-key="id"
          :items-per-page=30
          show-select
          class="elevation-1"
      >
<!--  :sort-by="['position']"
          :sort-desc="[false]"
                -->
        <!--items-per-page="30"-->

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>

        <template v-slot:item.down="{ item }">
          <v-btn @click="moveTo(item, 'down')">
            <v-icon>
              mdi-arrow-up-bold
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.up="{ item }">
          <v-btn @click="moveTo(item, 'up')">
            <v-icon>
              mdi-arrow-down-bold
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <RegisterStepControlDialog
              :register_step="item"
              :action="crudActions.update"
          />

          <!--<AgreeDialog
                  @remove="remove(item)"
                  class="ml-2"
          />-->
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
// import AgreeDialog from "../AgreeDialog";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";
import DefaultStatus from "../DefaultStatus";
import RegisterStepControlDialog from "./RegisterStepControlDialog";

const queryString = require('query-string');

export default {
  name: "RegisterStepGrid",
  mounted() {
    this.getRegisterSteps()
  },
  components: {
    // AgreeDialog,
    DefaultStatus,
    RegisterStepControlDialog
  },
  data() {
    return {
      registerSteps: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Наименование',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Балл',
          sortable: true,
          value: 'rate',
        },
        {
          text: 'Статус',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Опустить',
          sortable: false,
          value: 'down',
          width: '20%'
        },
        {
          text: 'Порядок',
          sortable: true,
          value: 'position',
        },
        {
          text: 'Поднять',
          sortable: false,
          value: 'up',
          width: '20%'
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_BOOLEAN_REGISTER_STEP_BY_ADMIN, async () =>
        this.getRegisterSteps()
    )
    eventBus.$on(eventBusEvents.EVENT_FILTER_PAIR, async search => {
      this.queryParams = search
      this.getRegisterSteps()
    })
  },
  methods: {
    moveTo(registerStep, direction) {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/register-steps/${registerStep.id}/${direction}`, {
        _method: 'put'
      })
          .then(response => {
            console.log('registerStep moved', response.data)
            this.getRegisterSteps()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
    getRegisterSteps() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/register-steps?${q}`)
          .then(response => this.registerSteps = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
    remove(registerStep) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/register-steps/${registerStep.id}`)
          .then(response => {
            console.log('registerStep removed', response.data)
            this.getRegisterSteps()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
  }
}
</script>

<style scoped>

</style>
