require('dotenv').config()

export const CBMessages = {
    noUser: 'PLease enter user data'
}

export const documentTypes = {
    selfieWithCover: 1,
    familyStatus: 2
}

export const profileDisplayMode = {
    managing: 'managing',
    presentable: 'presentable',
}

export const whatsappTypes = {
    main: 'main',
    spam: 'spam',
}

export const meetingEnum = [
    {
        id: 1,
        name: 'by_tariff',
        title: 'За Тариф',
        icon: 'mdi-currency-usd',
    },
    {
        text: 'Дата',
        align: 'start',
        sortable: true,
        value: 'created_at',
    },
    {
        id: 3,
        name: 'promo_invite',
        title: 'Приглашение',
        icon: 'mdi-account-multiple-plus',
    },
    {
        id: 4,
        name: 'promo_admin',
        title: 'Промокод админа',
        icon: 'mdi-ticket-percent',
    },
    {
        id: 5,
        name: 'black_door',
        title: 'Блат',
        icon: 'mdi-keyboard-outline',
    },
    {
        id: 8,
        name: 'site_purchase',
        title: 'Покупка на сайте',
        icon: 'mdi-video-input-component',
    },
]

export const osTypeEnum = [
    {
        id: 1,
        name: 'google',
        alias: 'android',
        title: 'google',
        icon: 'mdi-google',
    },
    {
        id: 2,
        name: 'apple',
        alias: 'ios',
        title: 'apple',
        icon: 'mdi-apple',
    },
    {
        id: 3,
        name: 'huawei',
        alias: 'huawei',
        title: 'huawei',
        icon: 'mdi-huawei',
    },
]

export const osTypeList = {
    google: 1,
    apple: 2,
}

export const rolesEnum = [
    {
        id: 1,
        name: 'admin',
    },
    {
        id: 2,
        name: 'moderator',
    },
    {
        id: 3,
        name: 'partner',
    },
    {
        id: 4,
        name: 'consumer',
    },
    {
        id: 5,
        name: 'editor',
    },
    {
        id: 6,
        name: 'matchmaker',
    },
]

export const rolesList = {
    admin: 1,
    moderator: 2,
    partner: 3,
    consumer: 4,
    editor: 5,
    matchmaker: 6,
}

export const agreementsEnum = [
    {
        id: 1,
        name: 'one',
        title: 'Один',
    },
    {
        id: 2,
        name: 'both',
        title: 'Оба',
    },
]

export const agreementsList = {
    one: 1,
    both: 2,
}

export const pairStatusesEnum = [
    {
        id: 1,
        name: 'create',
        title: 'Создана',
    },
    {
        id: 2,
        name: 'matched',
        title: 'Совпала',
    },
    {
        id: 3,
        name: 'mismatch',
        title: 'Не совпала',
    },
    {
        id: 4,
        name: 'declined',
        title: 'Разорвана',
    },
    {
        id: 5,
        name: 'resumed',
        title: 'Ждет повтора',
    },
]

export const pairStatusesList = {
    created: 1,
    matched: 2,
    mismatch: 3,
    declined: 4,
    resumed: 5,
}

export const pairRatingParamsList = {
    rating: 1,
    vedic_total: 2,
    varna_score: 3,
    vashya_score: 4,
    dina_score: 5,
    yoni_score: 6,
    grahamaitri_score: 7,
    gana_score: 8,
    rasi_score: 9,
    nadi_score: 10,
}

export const userStatusesEnum = [
    {
        id: 0,
        title: 'На регистрации',
        icon: 'mdi-alert-decagram',
        color: 'red',
    },
    {
        id: 1,
        title: 'Активен',
        icon: 'mdi-check-circle-outline',
        color: 'green',
    },
    {
        id: 2,
        title: 'На модерации',
        icon: 'mdi-alarm-light',
        color: 'orange',
    },
    {
        id: 3,
        title: 'На перемодерации',
        icon: 'mdi-alarm-light',
        color: 'yellow'
    },
    {
        id: 4,
        title: 'Удален',
        icon: 'mdi-close-thick',
        color: 'white'
    },
    {
        id: 5,
        title: 'Отклонен',
        icon: 'mdi-keyboard-return',
        color: 'grey'
    },
    {
        id: 6,
        title: 'Активен автом.',
        icon: 'mdi-check-circle-outline',
        color: 'green',
    },
    {
        id: 7,
        title: 'Отклонен автом.',
        icon: 'mdi-keyboard-return',
        color: 'grey'
    },
]

export const userStatusesList = {
    on_register: 0,
    active: 1,
    on_moderation: 2,
    on_re_moderation: 3,
    deleted: 4,
    rejected: 5,
    active_with_bot: 6,
    rejected_with_bot: 7,
}

export const preferConnectionsEnum = [
    {
        label: 'is_phone_prefer',
        code: 1,
        value: false
    },
    {
        label: 'is_whatsapp_prefer',
        code: 2,
        value: false
    },
    {
        label: 'is_telegram_prefer',
        code: 3,
        value: false
    },
    {
        label: 'is_viber_prefer',
        code: 4,
        value: false
    },
    {
        label: 'is_email_prefer',
        code: 5,
        value: false
    }
]

export const profileSearchStatusesList = {
    status_ready_for_search: 1,
    status_pair_is_assigned: 2,
    status_frozen: 3,
}

export const profileSearchStatusesEnum = [
    {
        id: 1,
        name: 'ready_for_search',
        title: 'Готов к поиску',
        icon: 'mdi-play',
        color: 'primary'
    },
    {
        // deprecated
        id: 2,
        name: 'pair_is_assigned',
        title: 'Пара назначена',
        icon: 'mdi-pause',
        color: 'gray'
    },
    {
        id: 3,
        name: 'frozen',
        title: 'Поиск приостановлен',
        icon: 'mdi-pause',
        color: 'gray'
    },
]

export const profilePairStatusesList = {
    free: 0,
    on_date: 1,
    my_reaction_ok_partner_reaction_ignore: 2,
    my_reaction_ok_partner_reaction_reject: 3,
    my_reaction_reject_partner_reaction_reject: 4,
    my_reaction_reject_partner_reaction_ok: 5,
    my_reaction_reject_partner_reaction_ignore: 6,
    my_reaction_ignore_partner_reaction_reject: 7,
    my_reaction_ignore_partner_reaction_ok: 8,
    both_ignore: 9,
}

export const profilePairStatusesEnum = [
    {
        id: 0,
        name: 'Свободен',
    },
    {
        id: 1,
        name: 'На свидании',
    },
    {
        id: 2,
        name: 'Я - да, кандидат - игнор',
    },
    {
        id: 3,
        name: 'Я - да, кандидат - нет',
    },
    {
        id: 4,
        name: 'Оба нет',
    },
    {
        id: 5,
        name: 'Я - нет, кандидат - да',
    },
    {
        id: 6,
        name: 'Я - нет, кандидат - игнор',
    },
    {
        id: 7,
        name: 'Я - игнор, кандидат - нет',
    },
    {
        id: 8,
        name: 'Я - игнор, кандидат - да',
    },
    {
        id: 9,
        name: 'Оба в игноре',
    }
]

export const defaultStatusesEnum = [
    {
        id: 1,
        name: 'active',
        title: 'Активен',
        color: 'green',
    },
    {
        id: 2,
        name: 'frozen',
        title: 'На паузе',
        color: 'secondary',
    },
    {
        id: 3,
        name: 'reject',
        title: 'Отклонено',
        color: 'red',
    }
]

export const defaultStatusesList = {
    active: 1,
    frozen: 2,
    reject: 3,
}

export const sexEnum = [
    {
        id: 1,
        name: 'man',
        title: 'Муж.',
    },
    {
        id: 2,
        name: 'woman',
        title: 'Жен.',
    }
]
export const casteEnum = [
    {
        id: 1,
        name: 'Один'
    },
    {
        id: 2,
        name: 'Два'
    },
    {
        id: 3,
        name: 'Три'
    }
]

export const sexList = {
    man: 1,
    woman: 2,
}

export const registerSteps = {
    smsVerification: 1,
    videoAgree: 2,
    sex: 3,
    birthday: 4,
    height: 5,
    birthtime: 6,
    cityBorn: 7,
    photo: 8,
    searchAge: 9,
    searchHeight: 10,
    documentSelfie: 11,
    documentFamilyPage: 12,
    lastStep: 13,
    registerComplete: 0,
}

export const userModesList = {
    profiles: 'profiles',
    challengers: 'challengers',
    ufo: 'ufo',
}

/**
 * Список объектов для классификаторов c множественным выбором (checkboxes)
 */
export const dictMultipleList = [
    {
        title: 'Города',
        dictMethod: 'cities',
        changeMethod: 'cities',
        postArgument: 'cities',
    },
    {
        title: 'Языки',
        dictMethod: 'languages',
        changeMethod: 'languages',
        postArgument: 'languages',
    },
    {
        title: 'Интересы',
        dictMethod: 'interests',
        changeMethod: 'interests',
        postArgument: 'interests',
    },
    {
        title: 'Пожелания по свиданию',
        dictMethod: 'features',
        changeMethod: 'features',
        postArgument: 'features',
    },
]

/**
 * Список объектов для классификаторов c dropdown
 */
export const dictFloatList = [
    {
        title: 'Город',
        dictMethod: 'cities',
        changeMethod: 'city-live',
        postArgument: 'city_live_id',
    },
    {
        title: 'Образование',
        dictMethod: 'educations',
        changeMethod: 'education',
        postArgument: 'education_id',
    },
    {
        title: 'Профессиональный статус',
        dictMethod: 'professional-statuses',
        changeMethod: 'professional-status',
        postArgument: 'professional_status_id',
    },
    {
        title: 'Сфера деятельности',
        dictMethod: 'spheres',
        changeMethod: 'sphere',
        postArgument: 'sphere_id',
    },
    {
        title: 'Телосложение',
        dictMethod: 'constitutions',
        changeMethod: 'constitution',
        postArgument: 'constitution_id',
    },
    {
        title: 'Дети',
        dictMethod: 'children-statuses',
        changeMethod: 'children-status',
        postArgument: 'children_status_id',
    },
    {
        title: 'Уровень дохода',
        dictMethod: 'incomes',
        changeMethod: 'income',
        postArgument: 'income_id',
    },
    {
        title: 'Отношение к курению',
        dictMethod: 'smoke-statuses',
        changeMethod: 'smoke-status',
        postArgument: 'smoke_status_id',
    },
    {
        title: 'Вероисповедание',
        dictMethod: 'religions',
        changeMethod: 'religion',
        postArgument: 'religion_id',
    },
    {
        title: 'Семейный статус',
        dictMethod: 'marital-statuses',
        changeMethod: 'marital-status',
        postArgument: 'marital_status_id',
    },
    /* todo deprecated
    {
        title: 'qualities',
        dictMethod: 'qualities',
        changeMethod: 'quality',
        postArgument: 'quality_id',
    },*/
]

export const cities = [
    {
        id: 1,
        name: 'Москва'
    },
    {
        id: 2,
        name: 'Санкт-Петербург'
    },
]

export const castes = [
    {
        id: 1,
        name: 1
    },
    {
        id: 2,
        name: 2
    },
    {
        id: 3,
        name: 3
    },
]

export const crudActions = {
    create: 'create',
    update: 'update',
    delete: 'delete',
    register: 'register',
}

export const sheetTypes = {
    default: 'default'
}
