<template>
  <v-chip :color="getColor()">
    {{ getName() }}
  </v-chip>
</template>

<script>
import {rolesList} from "@/enums/enums";

export default {
  name: "UserRole",
  props: ['role'],
  methods: {
    getName() {
      if (this.role === rolesList.admin) {
        return 'Админ'
      }
      if (this.role === rolesList.moderator) {
        return 'Модератор'
      }
      if (this.role === rolesList.partner) {
        return 'Партнер'
      }
      if (this.role === rolesList.consumer) {
        return 'Юзер'
      } else return 'Unknown'
    },
    getColor() {
      if (this.role === rolesList.admin) {
        return 'red'
      }
      if (this.role === rolesList.moderator) {
        return 'green'
      }
      if (this.role === rolesList.partner) {
        return 'secondary'
      }
      if (this.role === rolesList.consumer) {
        return 'primary'
      } else return 'Unknown'
    },
  }
}
</script>

<style scoped>

</style>
