<template>
  <v-chip :color="getColor()">
    {{ getName() }}
  </v-chip>
</template>

<script>

import {defaultStatusesEnum} from "../enums/enums";

export default {
  name: "DefaultStatus",
  props: ['status'],
  methods: {
    getName() {
      return defaultStatusesEnum.find(it => it.id === this.status).title || 'Unknown'
    },
    getColor() {
      return defaultStatusesEnum.find(it => it.id === this.status).color || 'Unknown'
    },
  }
}
</script>

<style scoped>

</style>
