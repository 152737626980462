<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="userLocal.name"
          :rules="rules.nameRules"
          label="name"
          type="tel"
          @keyup.enter="handleSubmit"
          required
      />

      <v-text-field
          v-model="userLocal.phone"
          :rules="rules.phoneRules"
          v-mask="'+7 (###) ###-##-##'"
          label="phone"
          type="tel"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-text-field
          v-model="userLocal.email"
          :rules="rules.emailRules"
          label="email"
          type="email"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

<!--      <v-text-field
          v-model="userLocal.password"
          v-if="showPasswordField"
          :counter="20"
          :rules="rules.passwordRules"
          label="password"
          type="password"
          @keyup.enter="handleSubmit"
          required
      />-->

      <v-select v-model="profileLocal.city_live_id"
                v-if="showCityLiveDropdown"
                :items="cities"
                label="Город"
                item-text="name"
                item-value="id"
      />

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>
    </v-form>

    <!--UserForm.userLocal: {{ userLocal }}-->
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {mapActions} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {cities, crudActions} from "@/enums/enums";

require('dotenv').config()

export default {
  name: "UserForm",
  props: ['action', 'user', 'profile'],
  data() {
    return {
      valid: true,

      actionLocal: null,

      userLocal: {
        name: "",
        email: "",
        phone: "",
        // password: "",
      },
      profileLocal: {
        city_live_id: 1
      },
      rules: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        phoneRules: [
          v => !!v || 'E-mail is required',
          v => /\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}/.test(v) || 'Phone must be valid',
        ],
        nameRules: [
          v => !!v || 'Name must be valid',
          v => v.length > 2 || 'Password length must be more than 2 characters',
        ],
        passwordRules: [
          v => !!v || 'Password must be valid',
          v => v.length > 5 || 'Password length must be more than 5 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    cities: function () {
      return cities;
    },
    fakeUser: function () {
      return {
        name: process.env.VUE_APP_TEST_USER_NAME,
        email: process.env.VUE_APP_TEST_USER_EMAIL,
        phone: process.env.VUE_APP_TEST_USER_PHONE,
        password: process.env.VUE_APP_TEST_USER_PASSWORD
      }
    },
    showCityLiveDropdown: function () {
      return this.actionLocal === this.crudActions.create
    },
    showPasswordField: function () {
      return [
        this.crudActions.register,
        this.crudActions.create
      ].includes(this.actionLocal)
    },
    btnText: function () {
      if (this.actionLocal === this.crudActions.create) {
        return 'Create'
      } else if (this.actionLocal === this.crudActions.update) {
        return 'update'
      } else {
        return 'register'
      }
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_USER_BY_ADMIN, async user => {
      console.log('EDIT_USER_BY_ADMIN', user)
      this.edit(user)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    this.actionLocal = this.action

    console.log('UserForm.user', this.user)

    if (this.user) {
      this.userLocal = this.user
      this.edit(this.user)
    }

    if ([
      this.crudActions.register,
    ].includes(this.actionLocal)) {
      if (process.env.VUE_APP_ENV === 'dev') {
        this.setFakeUser()
        this.profileLocal = this.profile || {}
      }
    }

    if (this.actionLocal === this.crudActions.update) {
      this.userLocal = this.user
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
      'EXEC_AUTH_SUCCESS',
      'EXEC_LOGOUT'
    ]),
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.userLocal.id = data.id
      this.userLocal.name = data.name
      this.userLocal.email = data.email
      this.userLocal.phone = data.phone
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.userLocal.id = null
      this.userLocal.name = null
      this.userLocal.email = null
      this.userLocal.phone = null
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.register) {
        this.register()
      } else if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else if (this.actionLocal === this.crudActions.update) {
        this.update()
      }
    },
    create() {
      console.log('create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users`,
          {...this.userLocal, ...{city_live_id: this.profileLocal.city_live_id}}
      ).then(resp => {
        const token = resp.data.data.token
        const user = resp.data.data.user;
        console.log('New User: ', user, token)
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },

    // todo my be move to UserForm
    update() {
      console.log('update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.userLocal,
        ...{
          city_live_id: this.profileLocal.city_live_id
        },
        ...{
          _method: 'put'
        }
      }

      console.log('data___', data)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${this.userLocal.id}`,
          data
      ).then(() => {
        // const { user, token } = resp.data.data
        // const user = resp.data.data.user;
        // console.log('Update user User: ', user, token)

        eventBus.$emit(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN)
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    setFakeUser() {
      this.userLocal.name = process.env.VUE_APP_TEST_USER_NAME
      this.userLocal.email = process.env.VUE_APP_TEST_USER_EMAIL
      this.userLocal.phone = process.env.VUE_APP_TEST_USER_PHONE
      this.userLocal.password = process.env.VUE_APP_TEST_USER_PASSWORD
    },
    validate() {
      if (!this.userLocal.name) {
        alert('name is empty')
        return false
      }
      if (!this.userLocal.email) {
        alert('email is empty')
        return false
      }
      if (!this.userLocal.phone) {
        alert('phone is empty')
        return false
      }

      if ([
        this.crudActions.register,
        this.crudActions.create
      ].includes(this.actionLocal)) {
        /*if (!this.userLocal.password) {
          alert('password is empty')
          return false
        }*/
        if (!this.profileLocal.city_live_id) {
          alert('city_live_id is empty')
          return false
        }
      }

      return true
    },
    // todo my be move to RegisterPage
    register() {
      console.log('register')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/auth/register`,
          {
            ...this.userLocal,
            ...{city_live_id: this.profileLocal.city_live_id}
          }
      ).then(resp => {
        const token = resp.data.data.token
        const user = resp.data.data.user;
        this.EXEC_AUTH_SUCCESS({token, user})
        eventBus.$emit(eventBusEvents.EVENT_REGISTER_USER)
      }).catch(err => {
        this.EXEC_LOGOUT()
        alert(converValidationServerErrorsToString(err))
      }).finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
  }
}
</script>

<style scoped>

</style>
