<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="ml-2"
          :color="getIconColor()"
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          dark
      >
        <v-icon aria-hidden="false">
          {{ getIcon() }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          Настройки (числовые)
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn @click="dialog = false" dark text>
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row>
        <v-col>
          <BooleanSettingPanel
              :action="action"
              :setting="setting"
          />
        </v-col>

        <v-col>
          ...
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {crudActions} from "@/enums/enums";
import BooleanSettingPanel from "./BooleanSettingPanel";

export default {
  name: 'BooleanSettingControlDialog',
  props: ['setting', 'action'],
  components: {
    BooleanSettingPanel
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  methods: {
    getIcon() {
      return this.setting ? 'mdi-pencil' : 'mdi-plus'
    },
    getIconColor() {
      return this.setting ? 'orange' : 'info'
    },
    close() {
      this.dialog = false
    }
  },
}
</script>

<style scoped>

</style>
