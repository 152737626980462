<template>
  <div>
    <v-row>
      <v-col cols="12">
        <PairPanel :pair_id="pair_id" v-if="pair_id"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PairPanel from "../../../components/Pair/PairPanel";

export default {
  name: "AdminPairDetailPage",
  mounted() {
    console.log(this.$route.params)

    this.pair_id = this.$route.params.pairId
  },
  data() {
    return {
      pair_id: null
    }
  },
  components: {
    PairPanel,
  },
}
</script>

<style scoped>

</style>
