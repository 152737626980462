<template>
  <div @click="change()">
    <v-row>
      <v-col cols="3">
        <v-avatar size="50px" class="ml-3">
          <v-img :src="dialog.image" v-if="dialog.image"/>

          <v-icon :color="'red lighten-2'" v-else>
            mdi-account
          </v-icon>
        </v-avatar>
      </v-col>

      <v-col>
        <ProfileControlDialog :profile_id="dialog.profile.id" @close="close()" v-if="dialog.profile"/>
        {{ dialog.profile ? dialog.profile.first_name : null }}
        <br/>
        ({{ dialog.name }})
      </v-col>

      <v-col>
        <small>
          {{ dialog.last_time_at }}
        </small>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ProfileControlDialog from "../../Profile/ProfileControlDialog";

export default {
  name: "Detail",
  props: ['dialog'],
  components: {
    ProfileControlDialog
  },
  methods: {
    change() {
      this.$emit('change', this.dialog)
    }
  }
}
</script>

<style scoped>

</style>