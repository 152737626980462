<template>
  <div v-if="pairLocal">
    <v-row>
      <v-col>
        <PairStatusesControl :pair="pairLocal" v-if="pairLocal"/>
      </v-col>

      <v-col>
        <v-card class="mt-4 orange lighten-5">
          <v-card-actions @click="showStatusesHistory = !showStatusesHistory">
            История изменения статусов
            <v-spacer/>

            <v-btn icon>
              <v-icon>{{ showStatusesHistory ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showStatusesHistory">
              <v-divider/>

              <v-card-text>
                <StatusesHistory class="mt-4" :statuses_history="pairLocal.statuses" :dictEnum="pairStatusesEnum"/>
                <!--:statuses_history="pairLocal.statuses_history"-->
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

        <v-card class="mt-4 brown lighten-5">
          <v-card-actions @click="showTechInfo = !showTechInfo">
            Техническая информация
            <v-spacer/>

            <v-btn icon>
              <v-icon>{{ showTechInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showTechInfo">
              <v-divider/>

              <v-card-text>
                <PairTechInfo v-if="pairLocal" :pair="pairLocal"/>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <!--Пара: {{ pairLocal.id }}-->
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col :cols=10>
            <v-row>
              <v-col>
                <ProfileCard v-if="pairLocal.man" :member="pairLocal.man" :pair_id="pair_id"/>
              </v-col>

              <v-col>
                <ProfileCard v-if="pairLocal.woman" :member="pairLocal.woman" :pair_id="pair_id"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols=2>
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>
                        Предложено
                      </td>
                      <td>
                        {{ pairLocal.offer_at }}
                      </td>
                    </tr>
                    <tr v-if="pairLocal.declined_at">
                      <td>
                        Разорвано
                      </td>
                      <td>
                        {{ pairLocal.declined_at }}
                      </td>
                    </tr>
                    <tr v-if="pairLocal.decliner_id">
                      <td>
                        Разорвал
                      </td>
                      <td>
                        {{ pairLocal.decliner.name }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <PairVedicTable :vedic="pairLocal.vedic" :rating="pairLocal.rating"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {pairStatusesEnum, pairStatusesList} from "@/enums/enums";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import ProfileCard from "../Profile/ProfileCard";
import StatusesHistory from "../StatusesHistory";
import PairStatusesControl from "./PairStatusesControl";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import PairVedicTable from "./PairVedicTable";
import PairTechInfo from "./PairTechInfo";

export default {
  name: "PairPanel",
  props: ['pair_id'],
  components: {
    ProfileCard,
    PairTechInfo,
    PairVedicTable,
    StatusesHistory,
    PairStatusesControl,
  },
  data() {
    return {
      pairLocal: null,
      showTechInfo: false,
      showStatusesHistory: false
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PAIR_BY_ADMIN, async respPair => {
      console.log('oppa___', respPair)
      this.pairLocal = respPair
      // this.getPair()
    })
  },
  mounted() {
    this.pairLocal = this.pair

    this.getPair()
  },
  computed: {
    pairStatusesList: function () {
      return pairStatusesList
    },
    pairStatusesEnum: function () {
      return pairStatusesEnum
    },
  },
  methods: {
    getPair() {
      console.log('getPair________')

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${this.pair_id}`)
          .then(res => this.pairLocal = res.data.data.pair)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
