<template>
  <div>
    <div v-if="vedic">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Аттрибут
            </th>
            <th class="text-left">
              Значение
            </th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td>
              Рейтинг
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.rating" :score="rating"/>
            </td>
          </tr>
          <tr>
            <td>
              Общая оценка Vedic
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.vedic_total" :score="vedic.vedic_total"/>
            </td>
          </tr>
          <tr>
            <td>
              varna_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.varna_score" :score="vedic.varna_score"/>
            </td>
          </tr>
          <tr>
            <td>
              vashya_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.vashya_score" :score="vedic.vashya_score"/>
            </td>
          </tr>
          <tr>
            <td>
              dina_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.dina_score" :score="vedic.dina_score"/>
            </td>
          </tr>
          <tr>
            <td>
              yoni_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.yoni_score" :score="vedic.yoni_score"/>
            </td>
          </tr>
          <tr>
            <td>
              grahamaitri_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.grahamaitri_score"
                         :score="vedic.grahamaitri_score"/>
            </td>
          </tr>
          <tr>
            <td>
              gana_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.gana_score" :score="vedic.gana_score"/>
            </td>
          </tr>
          <tr>
            <td>
              rasi_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.rasi_score" :score="vedic.rasi_score"/>
            </td>
          </tr>
          <tr>
            <td>
              nadi_score
            </td>
            <td>
              <VedicRate :param="pairRatingParamsList.nadi_score" :score="vedic.nadi_score"/>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import {pairRatingParamsList} from "@/enums/enums";
import VedicRate from "./VedicRate";

export default {
  name: "PairVedicTable",
  props: ['vedic', 'rating'],
  components: {
    VedicRate,
  },
  data() {
    return {
      // pairLocal: null
    }
  },
  mounted() {
    this.pairLocal = this.pair
  },
  computed: {
    pairRatingParamsList: function () {
      return pairRatingParamsList
    },
  },
}
</script>

<style scoped>

</style>
