<template>
  <v-card>
    <v-card-title>
      Фильтр
    </v-card-title>

    <v-card-text>
      <template v-if="isModeUsers()">

        <v-text-field v-model="search.user.id" label="ID" type="number"/>

        <v-text-field v-model="search.user.name" label="Имя"/>

        <v-text-field v-model="search.user.phone" v-mask="'+7 (###) ###-##-##'" label="Телефон" type="tel"/>

        <v-text-field v-model="search.user.email" label="e-mail" type="email"/>

        <v-row>
          <v-col>
            <header class="pt-6 font-weight-bold">
              Статус в истории:
            </header>
          </v-col>
          <v-col>
            <aside class="pt-5 font-weight-bold">
              <v-btn @click="search.user.history_status = null" color="warning" x-small dark>
                Сбросить
              </v-btn>
            </aside>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="search.user.history_status"
                      :items="userStatuses"
                      label="Статус в истории"
                      item-text="title"
                      item-value="id"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <header class="pt-6 font-weight-bold">
              Диапазон поиска статусов в истории:
              <aside class="pt-5 font-weight-bold">
                <v-btn @click="resetCalendar()" color="warning" x-small dark>
                  Сбросить
                </v-btn>
              </aside>
            </header>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <FilterDatepicker
                :fieldName="'statuses_created_at_from'"
                :label="'От'"
                @update="updateStatusesCreatedAtFrom"
            />
          </v-col>
          <v-col>
            <FilterDatepicker
                :fieldName="'statuses_created_at_to'"
                :label="'До'"
                @update="updateStatusesCreatedAtTo"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <header class="pt-6 font-weight-bold">
              Статус текущий:
            </header>
          </v-col>

          <v-col>
            <aside class="pt-5 font-weight-bold">
              <v-btn @click="search.user.status = null" color="warning" x-small dark>
                Сбросить
              </v-btn>
            </aside>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="search.user.status"
                      :items="userStatuses"
                      label="Статус текущий"
                      item-text="title"
                      item-value="id"
            />

          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <header class="pt-6 font-weight-bold">
              Дата регистрации:
              <aside class="pt-5 font-weight-bold">
                <v-btn @click="resetCalendar()" color="warning" x-small dark>
                  Сбросить
                </v-btn>
              </aside>
            </header>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <FilterDatepicker :fieldName="'created_at_from'" :label="'От'" @update="updateCreatedAtFrom"/>
          </v-col>

          <v-col>
            <FilterDatepicker :fieldName="'created_at_to'" :label="'До'" @update="updateCreatedAtTo"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="search.profile.is_ufo" label="Ufo"/>
          </v-col>
          <v-col justify="center" x-small>
            <aside class="pt-5 font-weight-bold">
              <v-btn @click="search.profile.is_ufo = null" color="warning" x-small dark>
                Сбросить
              </v-btn>
            </aside>
          </v-col>
        </v-row>

        <!--<h2 >Profile</h2>-->

        <v-range-slider
            class="mt-8"
            v-model="search.profile.age_range"
            label="Возраст"
            :min="ageMin"
            :max="ageMax"
            color="red"
            thumb-label="always"
        />

        <v-row>
          <v-col>
            <header class="pt-6 font-weight-bold">
              Статус поиска:
            </header>
          </v-col>
          <v-col>
            <aside class="pt-5 font-weight-bold">
              <v-btn @click="search.profile.status = null" color="warning" x-small dark>
                Сбросить
              </v-btn>
            </aside>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="search.profile.status"
                      :items="profileStatuses"
                      label="Статус поиска"
                      item-text="name"
                      item-value="id"
            />
          </v-col>
        </v-row>

        <v-text-field v-model="search.profile.comment" label="Коммент"/>

        <v-row>
          <v-col>
            <v-select v-model="search.profile.sex" :items="sexList" label="Пол" item-text="name"
                      item-value="id"/>
          </v-col>
          <v-col justify="center" x-small>
            <aside class="pt-5 font-weight-bold">
              <v-btn @click="search.profile.sex = null" color="warning" x-small dark>
                Сбросить
              </v-btn>
            </aside>
          </v-col>
        </v-row>
      </template>

      <template v-if="!isModeUsers()">
        <v-range-slider
            class="mt-8"
            v-model="search.profile.likes_range"
            label="Рейтинг лайков"
            :min="likesMin"
            :max="likesMax"
            color="red"
            thumb-label="always"
        />
      </template>

      <v-row>
        <v-col>
          <v-select v-model="search.profile.city_live_id"
                    :items="cities"
                    label="Город"
                    item-text="name"
                    item-value="id"
          />
        </v-col>
        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.profile.city_live_id = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.profile.caste"
                    :items="castes"
                    label="Группа"
                    item-text="name"
                    item-value="id"
          />
        </v-col>
        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.profile.caste = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {castes, cities, profilePairStatusesEnum, sexEnum, userModesList, userStatusesEnum} from "@/enums/enums"
import FilterDatepicker from "../FilterDatePicker"

const _ = require('lodash')

export default {
  name: "ProfileFilter",
  props: ['mode'],
  components: {
    FilterDatepicker
  },
  data() {
    return {
      ageMin: 18,
      ageMax: 84,

      likesMin: 0,
      likesMax: 100,

      search: {
        user: {
          id: null,
          name: null,
          phone: null,
          email: null,
          status: null,
          is_wary: null,
          created_at_from: null,
          created_at_to: null,
          statuses_created_at_from: null,
          statuses_created_at_to: null,
        },
        profile: {
          age_range: [
            18,
            84
          ],
          likes_range: [
            0,
            100
          ],
          id: null,
          sex: null,
          caste: null,
          is_ufo: null,
          city_live_id: null,
          comment: null,
          status: null,
          is_viewed_by_moderator: null
        }
      }
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_USER_BY_STATUS, async userStatus => {
      console.log('eventBusEvents.EVENT_FILTER_USER_BY_STATUS_____')
      this.search.user.status = userStatus
    })
    eventBus.$on(eventBusEvents.EVENT_FILTER_USER_BY_VIEWED_MODERATOR, async () => {
      console.log('eventBusEvents.EVENT_FILTER_USER_BY_VIEWED_MODERATOR_____')
      this.search.profile.is_viewed_by_moderator = false
      // if(this.search.profile.is_viewed_by_moderator === null) {
      //   this.search.profile.is_viewed_by_moderator = false
      // } else {
      //   this.search.profile.is_viewed_by_moderator = !this.search.profile.is_viewed_by_moderator
      // }
    })
    eventBus.$on(eventBusEvents.EVENT_FILTER_USER_RESET, async () => {
      console.log('eventBusEvents.EVENT_FILTER_USER_RESET_____')
      this.resetFilter()
    })
  },
  computed: {
    cities: function () {
      return cities
    },
    castes: function () {
      return castes
    },
    userStatuses: function () {
      return userStatusesEnum
    },
    userModesList: function () {
      return userModesList
    },
    profileStatuses: function () {
      return profilePairStatusesEnum
    },
    sexList: function () {
      return sexEnum
    }
  },
  methods: {
    isModeUsers() {
      return this.mode === this.userModesList.profiles
    },
    isModeChallengers() {
      return this.mode === this.userModesList.challengers
    },
    isModeUfo() {
      return this.mode === this.userModesList.ufo
    },
    resetCalendar() {
      eventBus.$emit(eventBusEvents.EVENT_CALENDAR_FILTER_RESET)
    },
    resetFilter() {
      this.resetCalendar()

      this.search = {
        user: {
          name: null,
          phone: null,
          email: null,
          status: null,
          is_wary: null,
          created_at_from: null,
          created_at_to: null,
          statuses_created_at_from: null,
          statuses_created_at_to: null,
        },
        profile: {
          age_range: [
            18,
            84
          ],
          sex: null,
          city_live_id: null,
          comment: null,
          status: null,
          is_viewed_by_moderator: null,
        }
      }
    },
    updateCreatedAtFrom(val) {
      this.search.user.created_at_from = val
    },
    updateCreatedAtTo(val) {
      this.search.user.created_at_to = val
    },
    updateStatusesCreatedAtFrom(val) {
      this.search.user.statuses_created_at_from = val
    },
    updateStatusesCreatedAtTo(val) {
      this.search.user.statuses_created_at_to = val
    },
    commitFilter(data) {
      let modifyProfile = {}
      let modifyUser = {}

      /*Object.keys(data.user)
          .filter(key => data.user[key] !== null && data.user[key] !== '')
          .forEach(key => modifyUser[`${key}`] = data.user[key])
      Object.keys(data.profile)
          .filter(key => key !== 'age_range' && !!data.profile[key])
          .forEach(key => modifyProfile[`profile_${key}`] = data.profile[key])*/

      console.log('Object.keys_before.....', data.profile)

      Object.keys(data.profile)
          // .filter(key => key !== 'likes_range' && !!data.profile[key])
          // .filter(key => key !== 'age_range' && !!data.profile[key])
          .filter(key => !['likes_range', 'age_range'].includes(key))
          .filter(key => data.profile[key] !== null && data.profile[key] !== '')
          .forEach(key => modifyProfile[`${key}`] = data.profile[key])

      console.log('Object.keys_after.....', modifyProfile)

      Object.keys(data.user)
          .filter(key => data.user[key] !== null && data.user[key] !== '')
          .forEach(key => modifyUser[`user_${key}`] = data.user[key])

      let result = {...modifyUser, ...modifyProfile}
      result.age_from = data.profile.age_range[0]
      result.age_to = data.profile.age_range[1]

      if (this.mode === userModesList.challengers || this.mode === userModesList.ufo) {
        result.likes_from = data.profile.likes_range[0]
        result.likes_to = data.profile.likes_range[1]
      }

      eventBus.$emit(eventBusEvents.EVENT_FILTER_PROFILE, {search: result, mode: this.mode})
    }
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.commitFilter(v)
      }, 1000),
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
