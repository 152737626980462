<template>
  <v-card>
    <v-card-title>
      Пульт управления:
    </v-card-title>

    <v-card-text>
      <NumericSettingForm
          v-if="this.actionLocal"
          :action="actionLocal"
          :setting="settingLocal"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {crudActions} from "@/enums/enums";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import NumericSettingForm from "./NumericSettingForm";

export default {
  name: "NumericSettingPanel",
  props: ['action', 'setting'],
  components: {
    NumericSettingForm
  },
  data() {
    return {
      actionLocal: null,
      settingLocal: null,
    }
  },
  mounted() {
    this.actionLocal = this.action
    this.settingLocal = this.setting
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_NUMERIC_SETTING_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.update
    });
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
  methods: {
    goToCreate() {
      this.actionLocal = this.crudActions.create
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_NUMERIC_SETTING_BY_ADMIN)
    },
    isActionCreate() {
      return this.actionLocal === this.crudActions.create
    },
    isActionUpdate() {
      return !this.isActionCreate()
    }
  }
}
</script>

<style scoped>

</style>
