<template>
  <v-card>
    <v-card-title>
      RegisterCityBorn
    </v-card-title>

    <v-card-text>
      <!-- todo make спб cases -->
      <v-autocomplete
          v-model="city_born"
          :items="items"
          :loading="this.loading"
          :search-input.sync="search"
          item-text="name"
          item-value="name"
          hide-no-data
          label="CityBorn"
          append-icon=""
          placeholder="Например: Москва"
          clearable
          return-object
          solo
      />
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions, mapGetters} from "vuex";

const _ = require('lodash')

export default {
  name: "CityBornPanel",
  props: ['profile'],
  data() {
    return {
      city_born: {
        name: null,
        latitude: null,
        longitude: null
      },
      search: '',
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_PROCESSING_REQUEST',
    ]),
    async makeSearch(value) {
      if (!value) {
        this.items = [];
        this.city_born = null;
      }

      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/autocomplete/cities?q=${this.search}`)
          .then(response => {
            this.items = response.data.data.items
          })
          .catch(error => console.log(error))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    change() {
      if (!this.city_born) {
        alert('city_born is empty')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/city-born` : `/profile/city-born`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`,
          {...this.city_born, ...{_method: 'put'}})
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_CITY_BORN, this.city_born))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.makeSearch(v)
      }, 1000),
      // deep: true,
    }
    /*search(value) {
        if (!value) {
            return
        }
        //_.debounce(this.makeSearch, 200)(value, this)
        _.debounce(function (value){
            this.makeSearch(value)
        }, 200)
    }*/
  }
}
</script>

<style scoped>

</style>
