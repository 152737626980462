<template>
  <v-card>
    <v-card-title>
      {{ promoCategory.name }}

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="promos"
          :expanded.sync="expanded"
          :search="search"
          item-key="id"
          show-select
          :sort-by="['_lft']"
          :sort-desc="[false]"
          :items-per-page="itemsPerPage"
          class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn class="" @click="edit(item)" color="info" fab x-small dark>
            <v-icon aria-hidden="false">
              mdi-pencil
            </v-icon>
          </v-btn>

          <AgreeDialog
              @remove="remove(item)"
              class="ml-2"
          />

          <!--<v-btn class="ml-2"
                 @click="remove(item)"
                 color="red"
                 fab
                 x-small
                 dark>

              <v-icon aria-hidden="false">
                  mdi-cancel
              </v-icon>
          </v-btn>-->
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import AgreeDialog from "../AgreeDialog";

const queryString = require('query-string');

export default {
  name: "PromoGrid",
  props: [
    'promoCategory'
  ],
  mounted() {
    this.getPromosList()
  },
  components: {
    AgreeDialog
  },
  data() {
    return {
      promos: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      itemsPerPage: 100,
      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Промо',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Статус',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Свиданий',
          align: 'start',
          sortable: true,
          value: 'meetings',
        },
        {
          text: 'Кол-во',
          align: 'start',
          sortable: true,
          value: 'quantity',
        },
        {
          text: 'До',
          align: 'start',
          sortable: true,
          value: 'finished_at',
        },
        /*{
            text: 'Category',
            sortable: true,
            value: 'category_id',
        },*/
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CREATE_PROMO_BY_ADMIN, async () =>
        this.getPromosList()
    );
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PROMO_BY_ADMIN, async () =>
        this.getPromosList()
    );
    eventBus.$on(eventBusEvents.EVENT_FILTER_PROMO, async search => {
      this.queryParams = search
      this.getPromosList()
    });
  },
  methods: {
    getPromosList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promo-categories/${this.promoCategory.id}/promos?${q}`)
          .then(response => this.promos = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    edit(promo) {
      console.log('step1...............')
      eventBus.$emit(eventBusEvents.EVENT_EDIT_PROMO_BY_ADMIN, promo)
    },
    remove(promo) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promos/${promo.id}`)
          .then(response => {
            console.log('promo removed', response.data)
            this.getPromosList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    changeProfile(promo) {
      eventBus.$emit(eventBusEvents.EVENT_EDIT_PROFILE_BY_ADMIN, promo.id)
    }
  }
}
</script>

<style scoped>

</style>
