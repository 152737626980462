<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="ml-2"
          color="orange"
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          dark
      >
        <v-icon aria-hidden="false">
          mdi-view-list
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Купоны
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false">
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <AdminPromosPage :promoCategory="promoCategory"/>

      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import AdminPromosPage from "../../pages/Admin/AdminPromosPage";

export default {
  name: "PromoControlDialog",
  props: [
    'promoCategory'
  ],
  components: {
    AdminPromosPage
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>

</style>
