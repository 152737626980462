<template>
  <div>
    <template v-if="is_favorite">
      <v-icon color="red">
        mdi-star
      </v-icon>
    </template>
    <template v-else>
      <v-btn @click="addToBookmark()">
        <v-icon color="red">
          mdi-star-outline
        </v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {converValidationServerErrorsToString} from "../utils/errorUtil";

export default {
  name: "Favorite",
  props: [
    'is_favorite',
    'searcher_id',
    'candidate_id',
  ],
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    addToBookmark() {
      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/favorites`, {
        searcher_id: this.searcher_id,
        candidate_id: this.candidate_id,
      })
          .then(() => {
            this.$emit('searchCandidates')
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>