<template>
    <span>
        <v-icon aria-hidden="false">
            {{ getIcon() }}
        </v-icon>
        {{ getName() }}
    </span>
</template>

<script>
import {meetingEnum} from "@/enums/enums";

export default {
  name: "MeetingType",
  props: ['meeting_type'],
  methods: {
    getIcon() {
      return meetingEnum.find(it => it.id === this.meeting_type).icon
    },
    getName() {
      return meetingEnum.find(it => it.id === this.meeting_type).title
    }
  }
}
</script>

<style scoped>

</style>
