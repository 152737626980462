<template>
  <a :href="url" target='_blank'>
    <v-icon aria-hidden="false" color="primary">
      mdi-account
    </v-icon>
  </a>

  <!--  <v-btn :to="url" class="ml-2" color="primary" fab x-small dark>
    <v-icon aria-hidden="false">
      mdi-account
    </v-icon>
  </v-btn>-->
</template>

<script>

export default {
  name: "ProfileRouteLink",
  props: [
    // 'profile_id',
    'url'
  ],
  methods: {
    /*getUrl() {
      console.log('xxx: ', this.$route.params)
      return `profiles/${this.profile_id}`
    }*/
  }
}
</script>

<style scoped>

</style>
