<template>
  <div>
    <!--<h4>
        Dashboard Simple
    </h4>-->

    <v-row>
      <v-col cols="12">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Dashboard</v-toolbar-title>
        </v-toolbar>

        <v-tabs vertical>
          <v-tab>
            Статусы Учетной записи
          </v-tab>
          <v-tab>
            Статусы поиска
          </v-tab>
          <v-tab>
            Возраст
          </v-tab>
          <v-tab>
            Города
          </v-tab>
          <v-tab>
            Купленные тарифы
          </v-tab>
          <v-tab>
            Наш доход по возрастам
          </v-tab>
          <v-tab>
            Покупки по возрастам
<!--            Наш доход по возрастам-->
          </v-tab>
          <v-tab>
            Шаги регистрации лидов
          </v-tab>
          <v-tab>
            Sex
          </v-tab>
          <v-tab>
            Сферы деятельности
          </v-tab>
          <v-tab>
            Профессиональные статусы
          </v-tab>
          <v-tab>
            Доходы мужчин
          </v-tab>
          <v-tab>
            Реакции
          </v-tab>

          <v-tab-item>
            <UserStatusesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <ProfileStatusesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <AgeDashboard/>
          </v-tab-item>
          <v-tab-item>
            <CitiesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <TariffUsagesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <ProfitByAgeDashboard/>
          </v-tab-item>
          <v-tab-item>
            <TariffUsagesByAgeDashboard/>
          </v-tab-item>
          <v-tab-item>
            <RegisterStepsDashboard/>
          </v-tab-item>
          <v-tab-item>
            <SexDashboard/>
          </v-tab-item>
          <v-tab-item>
            <SpheresDashboard/>
          </v-tab-item>
          <v-tab-item>
            <ProfessionalStatusesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <IncomesDashboard/>
          </v-tab-item>
          <v-tab-item>
            <ReactionsDashboard/>
          </v-tab-item>
        </v-tabs>
      </v-col>

      <!--<v-col cols="1">
          <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>

      <v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher
                           :clipped="false">
          <v-app-bar-nav-icon @click="drawer = !drawer"/>

          <DashboardFilter class="mb-4"/>

          <v-divider/>

      </v-navigation-drawer>-->
    </v-row>
  </div>
</template>

<script>
import RegisterStepsDashboard from "./Dashboards/RegisterStepsDashboard/RegisterStepsDashboard";
import TariffUsagesDashboard from "./Dashboards/TariffUsagesDashboard/TariffUsagesDashboard";
import CitiesDashboard from "./Dashboards/CitiesDashboard/CitiesDashboard";
import AgeDashboard from "./Dashboards/AgeDashboard/AgeDashboard";
import ProfileStatusesDashboard from "./Dashboards/ProfileStatusesDashboard/ProfileStatusesDashboard";
import UserStatusesDashboard from "./Dashboards/UserStatusesDashboard/UserStatusesDashboard";
import ReactionsDashboard from "./Dashboards/ReactionsDashboard/ReactionsDashboard";
import {sexList} from "@/enums/enums";
import ProfessionalStatusesDashboard from "./Dashboards/ProfessionalStatusesDashboard/ProfessionalStatusesDashboard";
import IncomesDashboard from "./Dashboards/IncomesDashboard/IncomesDashboard";
import SpheresDashboard from "./Dashboards/SpheresDashboard/SpheresDashboard";
import SexDashboard from "./Dashboards/SexDashboard/SexDashboard";
import TariffUsagesByAgeDashboard from "./Dashboards/TariffUsagesByAgeDashboard/TariffUsagesByAgeDashboard";
import ProfitByAgeDashboard from "./Dashboards/ProfitByAgeDashboard/ProfitByAgeDashboard";

export default {
  name: "AdminDashboardSimplePage",
  components: {
    SexDashboard,
    AgeDashboard,
    CitiesDashboard,
    SpheresDashboard,
    IncomesDashboard,
    ReactionsDashboard,
    ProfitByAgeDashboard,
    UserStatusesDashboard,
    TariffUsagesDashboard,
    RegisterStepsDashboard,
    ProfileStatusesDashboard,
    TariffUsagesByAgeDashboard,
    ProfessionalStatusesDashboard
  },
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    sexList: function () {
      return sexList
    }
  },
}
</script>

<style scoped>

</style>
