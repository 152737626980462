<template>
  <div>
    <v-card v-if="userLocal">
      <v-card-title>
        Учетная запись:
        <!--Рег.:  {{ getRegisteredAt() }}:-->
      </v-card-title>

      <UserStatusesControl class="mt-4" :user="userLocal" v-if="isAllowed()"/>

      <v-card-text>
        <v-card class="mt-4 orange lighten-5">
          <v-card-actions @click="showStatusesHistory = !showStatusesHistory">
            История изменения статусов
            <v-spacer/>

            <v-btn icon>
              <v-icon>{{ showStatusesHistory ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showStatusesHistory">
              <v-divider/>

              <v-card-text>
                <!--todo check-->
                <StatusesHistory
                    class="mt-4"
                    :statuses_history="userLocal.statuses"
                    :dictEnum="userStatusesEnum"
                />
                <!--:statuses_history="userLocal.statuses_history"-->
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

        <template v-if="isAllowed()">
          <p class="my-4">FCM:</p>

          <p class="my-4" style="font-size:4px"> {{ userLocal.fcm_token }}</p>

          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr v-if="isAllowed()">
                <td>Пригласительный промокод</td>
                <td>{{ userLocal.promo_key }}</td>
              </tr>
              <tr v-if="isAllowed()">
                <td>Псевдоним пригласительного промокода</td>
                <td>{{ userLocal.promo_alias }}</td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
        </template>

        <template v-if="isRemoved" class="my-4">
          <v-divider/>

          <h4 class="my-2">Причины удаления</h4>

          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr v-for="(barrier, i) in userLocal.barriers" :key="barrier.id">
                <td>{{ ++i }}</td>
                <td>{{ barrier.name }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <v-divider/>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Телефон</td>
              <td>
                {{ showPhone() }}
                <v-btn icon :href="getWhatsappLink()" target="_blank" color="green">
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ showEmail() }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <AgreeDialog @remove="remove(userLocal.id)" class="ml-2" v-if="allowForDelete(userLocal.status)"/>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {crudActions, userStatusesEnum, userStatusesList} from "@/enums/enums";
import UserStatusesControl from "./UserStatusesControl";
import StatusesHistory from "../StatusesHistory";
import {converValidationServerErrorsToString} from "@/utils/errorUtil"
import {mapActions} from "vuex"
import {getWhatsappLinkByPhone} from "@/utils/helpers";
import {convertUtcToDate} from "@/utils/converters";
import AgreeDialog from "@/components/AgreeDialog";

export default {
  name: "UserDetailsPanel",
  props: ['action', 'user_id'],
  components: {
    UserStatusesControl,
    StatusesHistory,
    AgreeDialog
  },
  data() {
    return {
      showStatusesHistory: false,
      userLocal: null
    }
  },
  mounted() {
    this.getUserInfo()
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    userStatusesEnum: function () {
      return userStatusesEnum
    },
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    getRegisteredAt() {
      return convertUtcToDate(this.userLocal.created_at)
    },
    getWhatsappLink() {
      return getWhatsappLinkByPhone(this.showPhone())
    },
    isRemoved() {
      return this.userLocal.status === userStatusesList.deleted
    },
    isAllowed() {
      return !this.isRemoved()
    },
    showPhone() {
      return this.isAllowed() ? this.userLocal.phone : this.userLocal.phone_trash
    },
    showEmail() {
      return this.isAllowed() ? this.userLocal.email : this.userLocal.email_trash
    },
    getUserInfo() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${this.user_id}`)
          .then(response => this.userLocal = response.data.data.user)
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    // todo duplicate
    allowForDelete(userStatus) {
      return userStatus !== userStatusesList.deleted
    },
    // todo duplicate
    remove(userId) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${userId}`)
          .then(response => {
            console.log('user removed', response.data)
            location.reload()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
