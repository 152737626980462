import VueRouter from 'vue-router'
import {routes} from './routes';
import store from '../store/index'
import {rolesList} from "@/enums/enums";

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({x: 0, y: 0})
            }, 500)
        })
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) { // нужна авторизация
        if (store.getters.IS_LOGGED_IN) {
            next()
        } else {
            next('/login')
        }
    }

    if (to.matched.some(record => record.meta.guest)) {
        if (store.getters.GET_TOKEN) {
            next({name: 'Profile'})
        } else {
            console.log('unauth');
            next()
        }
    }

    if (to.matched.some(record => record.meta.isAdmin)) { // если требуются права админа
        if (store.getters.GET_USER.role === rolesList.admin) {
            next()
        } else {
            next({name: 'Home'})
        }
    } else if (to.matched.some(record => record.meta.isModerator)) { // если требуются права модератора
        // console.log('step1')

        if (store.getters.GET_USER.role === rolesList.moderator) {
            next()
        } else {
            next({name: 'Home'})
        }
    }  else if (to.matched.some(record => record.meta.isMatchmaker)) { // если требуются права свахи
        // console.log('step1')

        if (store.getters.GET_USER.role === rolesList.matchmaker) {
            next()
        } else {
            next({name: 'Home'})
        }
    } else if (to.matched.some(record => record.meta.isEditor)) { // если требуются права модератора
        // console.log('step1')

        if (store.getters.GET_USER.role === rolesList.editor) {
            next()
        } else {
            next({name: 'Home'})
        }
    } else { // в кабинете
        // console.log('step2')

        if ([
            rolesList.admin,
            rolesList.moderator
        ].includes(store.getters.GET_USER.role)) {
            next()
        } else {
            if (to.matched.some(record => record.meta.need_details_register)) {
                if (store.getters.GET_USER.profile.register_step !== 0) {
                    next({name: 'RegisterDetails'})
                } else {
                    next()
                }
            } else {
                next()
            }
        }
    }
})

export {router}
