<template>
  <div>
    <v-container>
      <div class="text-right">
        <v-btn color="primary" @click="switchMode(profileDisplayMode.managing)" :outlined="checkPressed(profileDisplayMode.managing)">
          <v-icon>
            mdi-account-cog
          </v-icon>
        </v-btn>

        <v-btn color="orange" @click="switchMode(profileDisplayMode.presentable)" :outlined="checkPressed(profileDisplayMode.presentable)">
          <v-icon>
            mdi-account-tie
          </v-icon>
        </v-btn>
      </div>
    </v-container>

    <v-row>
      <v-col cols="12">
        <div v-if="isSettingsMode()">
          <ProfileDetailPanel :profile_id="profile_id" v-if="profile_id"/>
        </div>
        <div v-else>
          <ProfilePresentable :profile_id="profile_id" v-if="profile_id"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileDetailPanel from "../../../../components/Profile/ProfileDetailPanel";
import ProfilePresentable from "../../../../components/Profile/ProfilePresentable";
import {profileDisplayMode} from "../../../../enums/enums";

export default {
  name: "AdminProfileDetailPage",
  components: {
    ProfileDetailPanel,
    ProfilePresentable,
  },
  mounted() {
    this.profile_id = this.$route.params.profileId

    this.switchMode(profileDisplayMode.managing)
  },
  data() {
    return {
      profile_id: null,
      mode: null,
    }
  },
  computed: {
    profileDisplayMode: function () {
      return profileDisplayMode
    }
  },
  methods: {
    switchMode(mode) {
      // Какие пельмени надо есть, чтобы также хорошо петь и не раскабанеть?
      this.mode = mode
    },
    checkPressed(mode) {
      return mode === this.mode
    },
    isSettingsMode () {
      return this.mode === this.profileDisplayMode.managing
    },
    isPresentableMode () {
      return this.mode ===  this.profileDisplayMode.presentable
    }
  }
}
</script>

<style scoped>

</style>
