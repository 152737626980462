<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PromoCategoryGrid/>
      </v-col>

      <v-col cols="4">
        <PromoCategoryPanel :action="crudActions.create"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums";
import PromoCategoryGrid from "../../components/PromoCategory/PromoCategoryGrid";
import PromoCategoryPanel from "../../components/PromoCategory/PromoCategoryPanel";

export default {
  name: "AdminTariffsPage",
  components: {
    PromoCategoryGrid,
    PromoCategoryPanel,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
