import { render, staticRenderFns } from "./RegisterStepForm.vue?vue&type=template&id=1be706ec&scoped=true&"
import script from "./RegisterStepForm.vue?vue&type=script&lang=js&"
export * from "./RegisterStepForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be706ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VCheckbox,VForm})
