<template>
  <div>
    Support

    <Whatsapp/>
  </div>
</template>

<script>
import Whatsapp from "@/components/Whatsapp/Whatsapp";

export default {
  name: "WhatsappPage",
  components: {
    Whatsapp
  }
}
</script>

<style scoped>

</style>
