function converValidationServerErrorsToString(err) {
    console.log('err >>>', err)
    console.log('err.response >>>', err.response)
    console.log('err.response >>>', err.response)
    console.log('err.response.data >>>', err.response.data)

    // if(!err.response.data) {
    //     return err.response
    // }
    let errOut = Object.keys(err.response.data).map((key) => {
        return err.response.data[key]
    })
    return errOut.join('\n')
}

export {converValidationServerErrorsToString}