<template>
  <div id="scrolled-content" style="max-height:800px; overflow-y: auto; overflow-x: hidden">
    <Dialog v-for="dialog in dialogs"
            :dialog="dialog"
            :key="dialog.id"
            :class="getCssClass(dialog.is_switch)"
            @change="change"
            style="cursor: pointer"
    />
  </div>
</template>

<script>
import Dialog from "./Detail";
import {mapActions, mapGetters} from "vuex";
import {eventBus, eventBusEvents} from "../../../eventBus/eventBus";

export default {
  name: "List",
  components: {
    Dialog
  },
  data() {
    return {
      dialogs: [],
      initFirst: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_WHATSAPP_DIALOGS, () => {
      this.getDialogs()
    })
  },
  mounted() {
    this.getDialogs()
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    change(dialog) {
      console.log('change dialog')

      this.dialogs.forEach(dl => {
        let index = this.dialogs.findIndex(it => it.id === dl.id)
        this.dialogs[index]['is_switch'] = false
      })

      let index = this.dialogs.findIndex(it => it.id === dialog.id)
      this.dialogs[index]['is_switch'] = true
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN, this.dialogs[index]['id'])
    },
    getDialogs() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/whatsapp/dialogs`)
          .then(response => {
            let dialogs = response.data.data.items
            this.dialogs = dialogs.map(it => {
              return {...it, is_switch: false}
            })
            this.dialogs[0]['is_switch'] = true

            this.EXEC_PROCESSING_REQUEST(false)

            if (!this.initFirst) {
              eventBus.$emit(eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN, this.dialogs[0]['id'])
              this.initFirst = true
            }
          })
          .catch(error => console.log(error))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    getCssClass(is_switch) {
      return is_switch ? 'color: grey' : ''
    }
  }
}
</script>

<style scoped>

</style>