<template>
  <div v-if="!!profileLocal">
    <h4>Возможные кандидаты</h4>

<!--    <v-card class="mt-4 brown lighten-5" v-if="profileLocal.is_vip">
      <v-card-actions @click="showFilter = !showFilter">
        Фильтры
        <v-spacer/>

        <v-btn icon>
          <v-icon>{{ showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showFilter">
          <v-divider/>

          <v-card-text>
            dictFloatList: {{ dictFloatList }}

            <DictFloatPanel
                v-for="dict in dictFloatList"
                :key="dict.title"
                :title="dict.title"
                :dictMethod="`/dicts/${dict.dictMethod}`"
                :postArgument="dict.postArgument"
                :value="getDictValue(dict.postArgument)"
                class="mt-4"
                @updateField="updateField"
            />

            <p>
              filter: {{ filter }}
            </p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>-->

    <v-row>
      <v-col>
        <v-btn color="deep-purple lighten-2" @click="searchCandidates" text>
          Получить список
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <div v-if="showCandidatesInfo">
          Всего:
          <v-chip>{{ candidates.length }}</v-chip>
        </div>
      </v-col>
    </v-row>

    <v-simple-table v-if="candidates">
      <template v-slot:default>
        <thead>
        <tr>
          <td></td>
          <td>Имя</td>
          <td></td>
          <td>Статус учетки</td>
          <td>Статус поиска</td>
          <td>Возраст</td>
          <td>Рост</td>
          <td>Группа</td>
          <td>Текущих предложений</td>
          <td>Рейтинг</td>
          <td></td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="person in candidates" :key="person.profile.id" :style="getStyle(person.is_allow)">
          <td>
            <v-badge bordered :color="getBadgeColor(person)" :icon="getBadgeIcon(person.profile)" overlap>
              <v-img :src="person.profile.photo.image_thumb_path"
                     width="200"
                     height="200"
                     v-if="person.profile.photo"/>
            </v-badge>
          </td>
          <td>
            {{ person.profile.first_name }}
            <br/>
            <br/>
            {{ person.profile.city_live.name }}

            <!--<template>
            :
            <v-icon v-if="person.profile.is_ufo">
              mdi-ufo
            </v-icon>
          </template>-->
          </td>
          <td>
            <ProfileControlDialog :profile_id="person.profile.id" @close="close()"/>
          </td>
          <td>
            {{ getUserStatusName(person.user.status) }}
          </td>
          <td>
            {{ getProfileSearchStatusName(person.profile.search_status) }}
          </td>
          <td>
            {{ person.profile.age }}

            <br/>
            <br/>
            ищет:
            <br/>
            {{ person.profile.search_age_from }}
            <br/>
            -
            <br/>
            {{ person.profile.search_age_to }}
          </td>
          <td>
            {{ person.profile.height }}

            <br/>
            <br/>
            ищет:
            <br/>
            {{ person.profile.search_height_from }}
            <br/>
            -
            <br/>
            {{ person.profile.search_height_to }}
          </td>
          <td>
            {{ person.profile.caste }}
          </td>
          <td>
            {{ person.daily_offers_count }}
          </td>
          <td>
            {{ person.rating }}
          </td>
          <td>
            <ProfileRouteLink :url="`${person.profile.id}`"/>
          </td>
          <td>
            <template v-if="person.is_favorite">
              <a :href="`/vip/profiles/${person.favorite.password}`" target='_blank'>
                <v-icon aria-hidden="false" color="warning">
                  mdi-account-tie
                </v-icon>
              </a>

              {{ person.favorite.reaction }}
            </template>
            <Favorite
                :searcher_id="profile.id"
                :candidate_id="person.profile.id"
                :is_favorite="person.is_favorite"
                @searchCandidates="searchCandidates"
                v-else
            />
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider/>

    <h4 class="pt-4">Возможность участия в поиске</h4>

    <p>{{ allowedForSearch }}</p>

    <v-divider/>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {
  dictFloatList,
  profileSearchStatusesEnum,
  profileSearchStatusesList,
  userStatusesEnum,
  userStatusesList
} from "@/enums/enums";
import ProfileRouteLink from "./ProfileRouteLink";
import Favorite from "../Favorite";
// import DictFloatPanel from "@/components/Dicts/Filter/DictFloatFilterPanel";
import {objectToQueryString} from "@/utils/helpers";

export default {
  name: "ProfileSearchCandidatesInfo",
  components: {
    ProfileControlDialog: () => import('./ProfileControlDialog'),
    ProfileRouteLink,
    // DictFloatPanel,
    Favorite
  },
  props: ['profile', 'mode'],
  data() {
    return {
      showFilter: false,
      profileLocal: null,
      showCandidatesInfo: false,
      candidates: [],
      allowedForSearch: '',
      filter: {}
    }
  },
  mounted() {
    this.profileLocal = this.profile
    this.checkAllowedForSearch()
  },
  computed: {
    profileSearchStatusesEnum: function () {
      return profileSearchStatusesEnum
    },
    userStatusesEnum: function () {
      return userStatusesEnum
    },
    dictFloatList: function () {
      return dictFloatList.filter(it => ['children_status_id', 'smoke_status_id', 'religion_id', 'marital_status_id'].includes(it.postArgument))
    },
    profileSearchStatusesList: function () {
      return profileSearchStatusesList
    },
    userStatusesList: function () {
      return userStatusesList
    }
  },
  methods: {
    getBadgeColor(person) {
      if (
          person.profile.search_status === this.profileSearchStatusesList.status_frozen ||
          person.user.status === this.userStatusesList.deleted
      ) return 'error'

      return 'success'
    },
    getBadgeIcon(profile) {
      return profile.is_ufo ? 'mdi-ufo' : 'mdi-check'
    },
    updateField({key, value}) {
      this.filter[key] = value
    },
    resetFilter() {
      this.filter = {}
    },

    getStyle(is_allow) {
      return {'background': is_allow ? 'inherit' : '#e91e63'}
    },
    getProfileSearchStatusName(status) {
      return this.profileSearchStatusesEnum.find(it => it.id === status).title
    },
    getUserStatusName(status) {
      return this.userStatusesEnum.find(it => it.id === status).title
    },
    searchCandidates() {
      console.log(`is_vip_ ${this.profileLocal.is_vip}`)

      /*let suffix = this.profileLocal.is_vip ?
          `/matches/search-vip-candidates/${this.mode}` :
          `/matches/search-candidates/${this.mode}`*/

      let suffix = this.profileLocal.is_vip ?
          `/matches/search-vip-candidates` :
          `/matches/search-candidates`

      let q = objectToQueryString(this.filter)

      console.log('q_1: ', {q})

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}${suffix}?${q}`)
          .then(response => {
            this.candidates = response.data.data.items
            this.showCandidatesInfo = true
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    checkAllowedForSearch() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/matches/allowed-for-search`)
          .then(response => this.allowedForSearch = response.data.data.answer)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    getDictValue(argName) {
      return this.profileLocal[argName]
    },
    // todo repair
    // findBestPair() {
    //   this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/matches/search-best-candidate`)
    //       .then(response => {
    //         this.bestPair = response.data.data
    //       })
    //       .catch(err => alert(converValidationServerErrorsToString(err)));
    // }
    // todo deprecated
    // searchFavorites() {
    //     this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/matches/search-favorites`)
    //         .then(response => this.favorites = response.data.data.items)
    //         .catch(err => alert(converValidationServerErrorsToString(err)));
    // },
  }
}
</script>

<style scoped>

</style>
