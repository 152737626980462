<template>
  <v-card>
    <v-card-title>
      BooleanSettingGrid

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="settings"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          class="elevation-1"
          item-key="id"
          show-select
      >
        <!--items-per-page="30"-->

        <template v-slot:item.actions="{ item }">
          <BooleanSettingControlDialog
              :setting="item"
              :action="crudActions.update"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";
import BooleanSettingControlDialog from "./BooleanSettingControlDialog";

const queryString = require('query-string');

export default {
  name: "BooleanSettingGrid",
  mounted() {
    this.getSettingsList()
  },
  components: {
    BooleanSettingControlDialog
  },
  data() {
    return {
      settings: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Value',
          align: 'start',
          sortable: true,
          value: 'value',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_BOOLEAN_SETTING_BY_ADMIN, async () => {
      this.getSettingsList()
    });
  },
  methods: {
    getSettingsList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/boolean/?${q}`)
          .then(response => this.settings = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
