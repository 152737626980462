<template>
  <div>
    <header :class="{color: chat_type === 'spam' ? 'red' : 'black'}">
      {{ chat_type }}
    </header>

    <div id="scrolled-content" style="max-height:500px; overflow-y: auto;">
      <v-col
          cols="8"
          v-for="item in messages"
          :key="item.id"
          :offset="getOffset(item.id)"
          class="mt-4"
      >
        <Detail :message="item" @update="update"/>
      </v-col>
    </div>

    <Form :chat_id="chatId" v-if="chatId" :chat_type="chat_type"/>

      <!--<v-virtual-scroll height="500" item-height="250" :items="messages" benched="10">
        <template v-slot:default="{ item }">
          <Detail :message="item" @update="update" class="mt-4"/>
        </template>
      </v-virtual-scroll>-->
  </div>
</template>

<script>
import Detail from "./Detail";
import {mapActions, mapGetters} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import Form from "./Form";
import {whatsappTypes} from "../../../enums/enums";

export default {
  name: "List",
  props: ['chat_type'],
  components: {
    Detail,
    Form
  },
  data() {
    return {
      // winHeight: window.innerHeight * 0.7 + "px",
      winHeight: 200 + "px",
      container: null,
      elem: null,
      scrollTop: 400,

      messages: [],
      chatId: null,
    }
  },
  created() {
    let eventName = this.chat_type === this.whatsappTypes.main ?
        eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN :
        eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_SPAM

    eventBus.$on(eventName, async chatId => {
      console.log('created.chatId:', chatId)
      this.getMessages(chatId)
      console.log('xyz.')
      this.chatId = chatId
    })
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
    whatsappTypes: function () {
      return whatsappTypes
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    scroll() {
      let container = this.$el.querySelector("#scrolled-content");
      container.scrollTop = container.scrollHeight;
    },
    update(chatId) {
      console.log('update. foo')
      this.getMessages(chatId)
      console.log('update. bar')
    },
    getMessages(chatId) {
      this.EXEC_PROCESSING_REQUEST(true)

      let method = this.chat_type === this.whatsappTypes.main ? 'messages' : 'messages-spam'

      let url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/whatsapp/dialogs/${chatId}/${method}`

      console.log('url: ', url)

      this.$http.get(url)
          .then(response => {
            this.messages = response.data.data.items
            setTimeout(() => {
              this.scroll()
            }, 1000)
          })
          .catch(error => console.log(error))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    getOffset(messageId) {
      return this.isConsumer(messageId) ? 0 : 4
    },
    isConsumer(messageId) {
      return messageId.includes('false')
    }
  }
}
</script>

<style scoped>
</style>
