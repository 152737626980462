<template>
  <v-card>
    <v-card-title>
      RegisterPreferConnections
    </v-card-title>

    <v-card-text>
      <v-checkbox
          v-model="checked"
          :label="it.label"
          color="red"
          :value="it.code"
          v-for="it in preferConnections"
          :key="it.code"
          hide-details
      />
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {preferConnectionsEnum} from "@/enums/enums";

export default {
  name: "PreferConnectionsPanel",
  props: ['connections', 'profile'],
  data() {
    return {
      checked: [],
    }
  },
  computed: {
    preferConnections: function () {
      return preferConnectionsEnum
    }
  },
  mounted() {
    if (this.connections) {
      Object.keys(this.connections).forEach(key => {
        let index = this.preferConnections.findIndex(loc => loc.label === key)
        if (this.connections[key]) {
          this.checked.push(this.preferConnections[index].code)
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    change() {
      let url = this.profile ? `/admin/profiles/${this.profile.id}/prefer-connections` : `/profile/prefer-connections`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        connections: this.checked
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_PREFER_CONNECTIONS, this.checked))
          .catch(err => alert(converValidationServerErrorsToString(err)))
    }
  }

}
</script>

<style scoped>

</style>
