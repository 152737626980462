<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <div class="form-group py-2">
            <input type="file"
                   name="picture"
                   class="form-control-file"
                   ref="fileupload"
                   id="image"
                   @change="onFileChange"/>
          </div>
        </v-col>

        <v-col cols="3">
          <div class="form-group py-2">
            <img :src="imagePreview" style="height: 100px" v-show="showPreview"/>
          </div>
        </v-col>

        <v-col cols="3">
          <v-card
              class="mx-auto"
              color="#000"
              dark
              max-width="400"
              v-if="localDocument"
          >
            <!-- localDocument: {{ localDocument }}-->
            <v-hover v-slot:default="{ hover }">
              <v-img :src="localDocument.image"
                     height="125"
                     class="grey darken-4 mb-4">
                <v-expand-transition>
                  <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out red darken-2 v-card--reveal display-3 white--text"
                      style="height: 30%;"
                  >
                    <v-icon @click="remove(photo)" large center style="cursor: pointer;">
                      mdi-close
                    </v-icon>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple darken-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "DocumentPanel",
  props: ['title', 'type', 'document', 'url'],
  data() {
    return {
      localDocument: null,
      formFields: {
        photo: null
      },
      imagePreview: null,
      showPreview: false,
    }
  },
  mounted() {
    this.localDocument = this.document
  },
  methods: {
    ...mapActions([
      'EXEC_CREATE_PROFILE_DOCUMENT',
      'EXEC_DELETE_PROFILE_DOCUMENT'
    ]),
    refresh() {
      this.formFields.photo = null
      this.imagePreview = null
      this.showPreview = false
      this.$refs.fileupload.value = null;
    },
    remove() {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}/${this.localDocument.id}`).then(() => {
        this.localDocument = null
        this.EXEC_DELETE_PROFILE_DOCUMENT({document: this.localDocument, type: this.type})

        // eventBus.$emit(eventBusEvents.EVENT_REMOVE_PHOTO, this.localPhotos)
      }).catch(err => {
        console.log('upload_err___', err);
        alert(converValidationServerErrorsToString(err))
      })
    },
    change() {
      let formData = new FormData();
      if (!this.formFields.photo) {
        alert('image is empty')
        return false
      }
      formData.append('image', this.formFields.photo)
      formData.append('type', this.type)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}`, formData)
          .then(res => {
            this.localDocument = res.data.data.user.profile.documents.filter(it => it.d_type === this.type)[0] || null
            this.EXEC_CREATE_PROFILE_DOCUMENT({document: this.localDocument, type: this.type})
            this.refresh()
            eventBus.$emit(eventBusEvents.EVENT_LOAD_DOCUMENT, this.localDocument)
          }).catch(err => {
        console.log('upload_err___', err)
        alert(converValidationServerErrorsToString(err))
      })
    },
    onFileChange(event) {
      this.formFields.photo = event.target.files[0];

      let reader = new FileReader();

      reader.addEventListener('load', function () {
        this.showPreview = true;
        this.imagePreview = reader.result;
      }.bind(this), false);

      if (this.formFields.photo) {
        if (/\.(jpe?g|png|gif)$/i.test(this.formFields.photo.name)) {
          reader.readAsDataURL(this.formFields.photo);
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
