<template>
  <v-card>
    <v-card-title>
      История ежедневных подборов

      <v-spacer/>

      <v-btn color="orange" @click="getDailyMatchesList()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-spacer/>

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details/>
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="users"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          class="elevation-1"
          item-key="id"
          :items-per-page="itemsPerPage"
          :item-class="itemRowBackground"
          show-select
      >
        <template v-slot:item.city_id="{ item }">
          {{ item.city.name }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ convertUtcToDate(item.started_at, item.city) }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ convertUtcToDate(item.completed_at, item.city) }}
        </template>

        <template v-slot:item.role="{ item }">
          <UserRole :role="item.role"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";
import {mapActions, mapGetters} from "vuex";
import {convertUtcToDate} from "@/utils/converters";
import UserRole from "../User/UserRole";

const queryString = require('query-string');

export default {
  name: "DailyMatchGrid",
  mounted() {
    this.getDailyMatchesList()
  },
  components: {
    UserRole
    // ProfileControlDialog,
  },
  data() {
    return {
      users: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',
      itemsPerPage: 100,

      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Город',
          align: 'start',
          sortable: true,
          value: 'city_id',
        },
        {
          text: 'День запуска',
          sortable: true,
          value: 'day',
        },
        {
          text: 'Все жители города',
          align: 'start',
          sortable: true,
          value: 'citizens_count',
        },
        {
          text: 'Пользователи из расформированных пар',
          align: 'start',
          sortable: true,
          value: 'broken_count',
        },
        {
          text: 'Доступные для подбора',
          align: 'start',
          sortable: true,
          value: 'waited_for_search_count',
        },
        {
          text: 'С парой',
          align: 'start',
          sortable: true,
          value: 'paired_count',
        },
        {
          text: 'Без пары',
          align: 'start',
          sortable: true,
          value: 'not_paired_count',
        },
        {
          text: 'Начало',
          align: 'start',
          sortable: true,
          value: 'started_at',
        },
        {
          text: 'Завершение',
          sortable: true,
          value: 'completed_at',
        },
        {
          text: 'Фаза выполнения',
          sortable: true,
          value: 'status',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
    }),
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN, async () => {
      this.getDailyMatchesList()
    });
    eventBus.$on(eventBusEvents.EVENT_FILTER_PROFILE, async search => {
      this.queryParams = search
      this.getDailyMatchesList()
    });
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    // todo move to util
    convertUtcToDate(matchDate, city) {
      return convertUtcToDate(matchDate, city?.timezone)
    },
    itemRowBackground(item) {
      return item.status === 'completed' ? 'cyan lighten-5' : 'red lighten-5'
    },
    getDailyMatchesList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/daily-matches?${q}`)
          .then(response => this.users = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>
