<template>
  <div>
    <v-row>
      <v-col cols="6" >
        <v-text-field
            v-model="message"
            :rules="rules.messageRules"
            label="Ваше сообщение"
            type="text"
            @keyup.enter="send"
            onfocus="this.removeAttribute('readonly')"
            append-outer-icon='mdi-send'
            @click:append-outer="send"
            append-icon="mdi-close-circle"
            @click:append="showForm=false"
            required
            readonly
        />
      </v-col>
    </v-row>

<!--todo prepare tiptap editor-->
    <br/>
    <br/>
<!--    <TiptapVuetify
        v-model="content"
        :extensions="extensions"
    />-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {whatsappTypes} from "../../../enums/enums";
// import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  name: "Form",
  props: ['chat_id', 'chat_type'],
  components: {
    // TiptapVuetify
  },
  data() {
    return {
      // declare extensions you want to use
      extensions: [
        History,
        // Blockquote,
        // Link,
        // Underline,
        // Strike,
        // Italic,
        // ListItem,
        // BulletList,
        // OrderedList,
        // [Heading, {
        //   options: {
        //     levels: [1, 2, 3]
        //   }
        // }],
        // Bold,
        // Code,
        // HorizontalRule,
        // Paragraph,
        // HardBreak
      ],
      // starting editor's content
      content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,

      showForm: false,
      message: null,
      rules: {
        messageRules: [
          v => !!v || 'Message must be valid',
          v => !!v && v.length > 2 || 'Message length must be more than 2 characters',
        ],
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
    phone: function () {
      let foo = this.chat_id.split('@')[0]
      return `+${foo[0]} (${foo[1]}${foo[2]}${foo[3]}) ${foo[4]}${foo[5]}${foo[6]}-${foo[7]}${foo[8]}-${foo[9]}${foo[10]}`
    },
    whatsappTypes: function () {
      return whatsappTypes
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    send() {
      if (!this.message) {
        alert('message not valid')
      }
      if (!this.phone) {
        alert('phone not valid')
      }

      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        phone: this.phone,
        body: this.message
      }

      let method = this.chat_type === this.whatsappTypes.main ? 'messages' : 'messages-spam'

      let url = `${process.env.VUE_APP_SERVER_BASE_URL}/admin/whatsapp/dialogs/${this.chat_id}/${method}`

      this.$http.post(url, data)
          .then(() => {
            this.message = null

            let eventNameFoo = this.chat_type === this.whatsappTypes.main ?
                eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN :
                eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_SPAM

            let eventNameBar = this.chat_type === this.whatsappTypes.main ?
                eventBusEvents.EVENT_REFRESH_WHATSAPP_DIALOGS_MAIN :
                eventBusEvents.EVENT_REFRESH_WHATSAPP_DIALOGS_SPAM

            setTimeout(() => {
              eventBus.$emit(eventNameFoo, this.chat_id)
              eventBus.$emit(eventNameBar)
            }, 9000)
          })
          .catch(error => console.log(error))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>
