<template>
  <v-card v-if="profileLocal">
    <v-card-title :style="{color: profile.sex === 1 ? 'blue' : 'pink'}">
      {{ profile.first_name }} - {{ profile.email }}
    </v-card-title>

    <v-card-text>
      <v-btn icon :href="getWhatsappLink(profile.phone)" target="_blank" color="green">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>

      &nbsp;
      &nbsp;
      &nbsp;

      <v-btn
          :href="getTelegramLink(profile.telegram_username)"
          v-if="profile.telegram_user_id && profile.telegram_username"
          icon
          target="_blank"
          color="green"
          style="cursor: pointer;"
      >
        <v-chip color="blue">
          tg
        </v-chip>
      </v-btn>

      <h3>
        <v-chip class="mt-2 mb-2 lighten-4" color="secondary">
          {{ getRegisteredAt() }}
        </v-chip>
      </h3>

      <h3>
        <OsTypeShop :os_type="getOs()" v-if="profile.devices.length > 0"/>
      </h3>

      <CoolLightBox
          :items="getCoolLightBoxPhotos(profile)"
          :index="index"
          @close="index = null"/>

      <v-row>
        <v-col>
          <v-row>
            <v-col cols="2">
              <div style="width: 125px">
                <v-img :src="profile.examinations[0].biometric.photos[0].image_thumb_path"
                       height="125"
                       class="grey darken-4 mb-4"
                       @click="popupImage(0)"
                />
              </div>
            </v-col>

            <v-col cols="2">
              <div style="width: 125px">
                <v-img :src="profile.examinations[0].photos[0].image_thumb_path"
                       height="125"
                       class="grey darken-4 mb-4"
                       @click="popupImage(1)"
                />
              </div>
            </v-col>

            <v-col cols="2" v-for="(photo, i) in profile.photos" :key="photo.id">
              <div style="width: 125px">
                <div>
                  <v-img :src="photo.image_thumb_path"
                         height="125"
                         class="grey darken-4 mb-4"
                         @click="popupImage(i + 2)"
                  />
                </div>

                <v-btn @click="remove(photo)" color="red">
                    Удалить
                </v-btn>
                <!-- @click="popupImage(i)"-->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div>
        <p>
          Описание: {{ profile.about }}
        </p>
        <p>
          Описание черновик: {{ profile.about_draft }}
        </p>

        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Город</td>
                  <td>
                    {{ profile.city_live_name }}
                  </td>
                </tr>
                <tr>
                  <td>Возраст</td>
                  <td>
                    {{ profile.age }}
                  </td>
                </tr>
                <tr>
                  <td>Возраст кандидата</td>
                  <td>
                    {{ profile.search_age_from }} - {{ profile.search_age_to }}
                  </td>
                </tr>
                <tr>
                  <td>Куплено свиданий в приложении</td>
                  <td>
                    {{ profile.purchase_meetings }}
                  </td>
                </tr>
                <tr>
                  <td>Баланс</td>
                  <td>
                    {{ profile.balance }}
                  </td>
                </tr>
                <tr>
                  <td>Религия</td>
                  <td>
                    {{ profile.religion }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col>

          </v-col>
        </v-row>
      </div>

      <div>
        <v-progress-linear color="blue" height="30" :value="profile.filling_profile"
                           striped>
          <template>
            <strong>Профиль заполнен на {{ profile.filling_profile }}%</strong>
          </template>
        </v-progress-linear>
      </div>

      <div>
        <ProfileComments class="mt-4" :profile="profile"/>
      </div>

      <v-row class="my-4">

        <v-col>
          <v-row>
            <v-col>
              <UfoForm :profile="profile" :is_ufo="profile.is_ufo"/>
            </v-col>
            <v-col>
              <CasteForm :profile="profile" :caste="profile.caste"/>
            </v-col>
            <v-col>
              <ProfileRouteLink :url="`profiles/${profile.id}`"/>
            </v-col>
            <v-col>
              <ViewedByModeratorForm :profile="profile"
                                     :is_viewed_by_moderator="profile.is_viewed_by_moderator"
                                     @update="update"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div>
        <UserStatusesControl class="mt-4" :user="profile.user"/>
      </div>

      <div>
        <v-row>
          <v-col>
            <v-card class="mt-4 grey lighten-5">
              <v-card-actions @click="showPairs = !showPairs">
                История
                <v-spacer/>

                <v-btn icon>
                  <v-icon>
                    {{ showPairs ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showPairs">
                  <v-divider/>

                  <v-card-text>
                    <PairGrid :url="`/admin/profiles/${profile.id}/pairs`"
                              :allow_pair_filters="false"/>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {crudActions, userModesList} from "@/enums/enums";
import {mapActions, mapGetters} from "vuex";
import {convertUtcToDate} from "@/utils/converters";
import ProfileComments from "../User/ProfileModerationComments";
import UfoForm from "./Forms/UfoForm";
import CasteForm from "./Forms/CasteForm";
import ViewedByModeratorForm from "./Forms/ViewedByModeratorForm";
import ProfileRouteLink from "./ProfileRouteLink";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import UserStatusesControl from "../User/UserStatusesControl";
import {converValidationServerErrorsToString} from "../../utils/errorUtil";
import {getWhatsappLinkByPhone} from "../../utils/helpers";
import OsTypeShop from "../TariffUsage/OsTypeShop";
import {userStatusesList} from "../../enums/enums";
import PairGrid from "@/components/Pair/PairGrid.vue";

export default {
  name: 'ProfileOnModerationList',
  components: {
    PairGrid,
    UfoForm,
    CasteForm,
    CoolLightBox,
    ProfileComments,
    ProfileRouteLink,
    UserStatusesControl,
    ViewedByModeratorForm,
    OsTypeShop,
  },
  props: ['profile'],
  data() {
    return {
      // todo to component
      index: null,
      profileLocal: null,

      showPairs: false,
    }
  },
  mounted() {
    this.profileLocal = this.profile
  },
  created() {
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
      user: 'GET_USER',
    }),
    crudActions: function () {
      return crudActions
    },
    userModesList: function () {
      return userModesList
    },
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    update(val) {
      this.$emit('update', val)
    },
    // todo move to service
    getCover(examination) {
      let photos = examination?.biometric?.photos || []
      if (!photos.length) return null
      let elem = photos[Math.floor(Math.random() * photos.length)]
      return elem.image
    },

    popupImage(i) {
      this.index = i
    },
    getPhone(phone) {
      return phone.includes('+7') ? phone : ''
    },
    convertUtcToDate(profile) {
      return convertUtcToDate(profile.created_at, profile.city?.timezone)
    },
    getRealConditionByAge() {
      return it => it.age < 70
    },
    isViewedByModerator(profile) {
      return profile.is_viewed_by_moderator || this.isUserStatusDeleted(profile.user.status) || this.isUserStatusOnRegister(profile.user.status)
    },
    getCityName(profile) {
      return !profile.city_live ? profile?.missing_cities?.map(it => it.name)?.join(',') : profile.city_live.name
    },

    getCoolLightBoxPhotos(profile) {
      return [
        profile.examinations[0].biometric.photos[0].image_thumb_path,
        profile.examinations[0].photos[0].image_thumb_path,
        ...profile.photos.map(it => it.image_thumb_path),
      ]
    },
    // todo duplicate
    remove(photo) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/photos/${photo.id}`).then(() => {
        /*let index = this.localPhotos.findIndex(it => it.id === photo.id)
        this.localPhotos.splice(index, 1)
        this.EXEC_REFRESH_PHOTOS(this.localPhotos)*/

        let index = this.profileLocal.photos.findIndex(it => it.id === photo.id)
        this.profileLocal.photos.splice(index, 1)

        // eventBus.$emit(eventBusEvents.EVENT_REMOVE_PHOTO, this.localPhotos)
      }).catch(err => {
        console.log('upload_err___', err);
        alert(converValidationServerErrorsToString(err))
      })
    },
    getWhatsappLink() {
      return getWhatsappLinkByPhone(this.showPhone())
    },
    showEmail() {
      return this.isAllowed() ? this.profileLocal.user.email : this.profileLocal.user.email_trash
    },
    showPhone() {
      // return this.isAllowed() ?
      return !this.isDeleted() ?
          this.profileLocal.user.phone :
          this.profileLocal.user.phone_trash
    },
    isAllowed() {
      return !this.isDeleted()
    },
    isDeleted() {
      console.log('ololo: ', this.profileLocal)
      return this.profileLocal.user.status === userStatusesList.deleted
    },
    getTelegramLink(telegram_username) {
      return `https://t.me/${telegram_username}`
    },
    getRegisteredAt() {
      return convertUtcToDate(this.profileLocal.created_at)
    },
    getOs() {
      return this.profileLocal?.devices.length > 0 ? this.profileLocal.devices[0].os : null
    },
  }
}
</script>

<style scoped>

</style>
