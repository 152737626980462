<template>
  <v-card>
    <v-card-title>
      История изменения статусов:
      <v-divider/>
    </v-card-title>

    <v-card-text>
      <v-timeline>
        <v-timeline-item
            v-for="(it, i) in this.statuses_history"
            :key="i"
            :class="{textRight: !(i % 2)}"
            :color="getColor(it.status)"
        >
          <v-card class="elevation-2">
            <v-card-title class="headline text-sm-body-2">
              {{ getName(it.status) }}
            </v-card-title>
            <v-card-text>
              <strong>
                {{ convertUtcToDate(it.created_at) }}
              </strong>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import {convertUtcToDate} from "../utils/converters";

export default {
  name: "StatusesControl",
  props: ['statuses_history', 'dictEnum', 'timezone'],
  methods: {
    getName(status) {
      return this.getElem(status).name
    },
    getColor(status) {
      return this.getElem(status).color
    },
    getElem(status) {
      return this.dictEnum.find(it => it.id === status)
    },
    convertUtcToDate(e_date) {
      return convertUtcToDate(e_date, this.timezone)
    },
  }
}
</script>

<style scoped>

</style>
