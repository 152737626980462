<template>
  <v-card>
    <v-card-title>
      Фильтр
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field type="number" v-model="search.pair.id" placeholder="ID"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-checkbox v-model="search.pair.is_ufo" label="Ufo"/>
        </v-col>
        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.pair.is_ufo = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <header class="pt-6 font-weight-bold">
            Диапазон поиска статусов в истории:
          </header>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <FilterDatepicker
              :fieldName="'statuses_created_at_from'"
              :label="'От'"
              @update="updateStatusesCreatedAtFrom"
          />
        </v-col>
        <v-col>
          <FilterDatepicker
              :fieldName="'statuses_created_at_to'"
              :label="'До'"
              @update="updateStatusesCreatedAtTo"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.pair.agreement"
                    :items="agreements"
                    label="Согласились"
                    item-text="name"
                    item-value="id"
          />
        </v-col>

        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.pair.agreement = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.pair.status"
                    :items="pairStatuses"
                    label="Статус"
                    item-text="name"
                    item-value="id"
          />
        </v-col>

        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.pair.status = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.pair.history_status"
                    :items="pairStatuses"
                    label="Статус в истории"
                    item-text="name"
                    item-value="id"
          />
        </v-col>

        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.pair.history_status = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-range-slider
          class="mt-8"
          v-model="search.pair.man_age_range"
          label="Возраст М."
          :min="ageMin"
          :max="ageMax"
          color="red"
          thumb-label="always"
      />

      <v-range-slider
          class="mt-8"
          v-model="search.pair.woman_age_range"
          label="Возраст Ж."
          :min="ageMin"
          :max="ageMax"
          color="red"
          thumb-label="always"
      />

      <v-range-slider
          class="mt-8"
          v-model="search.pair.rating_range"
          label="Рейтинг"
          :min="ratingMin"
          :max="ratingMax"
          color="red"
          thumb-label="always"
      />

      <v-row>
        <v-col>
          <v-select v-model="search.pair.city_live_id"
                    :items="cities"
                    label="Город"
                    item-text="name"
                    item-value="id"
          />
        </v-col>

        <v-col
            justify="center"
            x-small
        >
          <aside class="pt-5 font-weight-bold">
            <v-btn
                @click="search.pair.city_live_id = null"
                color="warning"
                x-small
                dark
            >
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import FilterDatepicker from "../FilterDatePicker"
import {agreementsEnum, cities, pairStatusesEnum} from "@/enums/enums"

const _ = require('lodash')

export default {
  name: "pairFilter",
  components: {
    FilterDatepicker
  },
  mounted() {
    // todo для Толика после обкатки
    // setTimeout(() => {
    //     this.search.pair.status = pairStatusesList.created
    // }, 1000)
  },
  data() {
    return {
      ageMin: 18,
      ageMax: 84,
      ratingMin: 0,
      ratingMax: 36,

      search: {
        pair: {
          id: null,
          name: null,
          status: null,
          history_status: null,
          is_wary: null,
          statuses_created_at_from: null,
          statuses_created_at_to: null,
          city_live_id: null,

          is_ufo: null, // from offers

          man_age_range: [
            18,
            84
          ],
          woman_age_range: [
            18,
            84
          ],
          rating_range: [
            0,
            36
          ],
        },

      }
    }
  },
  computed: {
    cities: function () {
      return cities
    },
    pairStatuses: function () {
      return pairStatusesEnum
    },
    agreements: function () {
      return agreementsEnum
    },
  },
  methods: {
    updateStatusesCreatedAtFrom(val) {
      this.search.pair.statuses_created_at_from = val
    },
    updateStatusesCreatedAtTo(val) {
      this.search.pair.statuses_created_at_to = val
    },
    commitFilter(data) {
      let modifyPair = {}

      Object.keys(data.pair)
          .filter(key => data.pair[key] !== null && data.pair[key] !== '')
          .forEach(key => modifyPair[`${key}`] = data.pair[key])

      let result = {...modifyPair}

      result.man_age_from = data.pair.man_age_range[0]
      result.man_age_to = data.pair.man_age_range[1]
      result.woman_age_from = data.pair.woman_age_range[0]
      result.woman_age_to = data.pair.woman_age_range[1]

      console.log('resultPairSearchParams: ', result)

      eventBus.$emit(eventBusEvents.EVENT_FILTER_PAIR, result)
    }
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.commitFilter(v)
      }, 1000),
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
