<template>
  <div>
    <PhotoForm
        v-for="examination in examinations"
        :key="examination.id"
        :photos="examination.photos ? examination.photos : []"
        :min="min"
        :max="max"
        :url="`/profile/examinations/${examination.id}/photos`"
        :title="'Проверка'"
    >
      <v-img :src="getCover(examination)" width="200"/>
    </PhotoForm>

    <v-container>
      <v-btn color="deep-purple lighten-2" @click="next" v-if="allowToChange()" text>
        Next
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";
import PhotoForm from "../../components/Profile/Forms/PhotoForm";

export default {
  name: "RegisterPhoto",
  props: ['examinations'],
  components: {
    PhotoForm
  },
  created() {
    // this.localPhotos = this.photos.length ? this.photos : []

    eventBus.$on(eventBusEvents.EVENT_LOAD_PHOTO, async photos => {
      this.localPhotos = photos
    })
  },
  data() {
    return {
      localPhotos: [],
      min: 1,
      max: 3
    }
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_REFRESH_USER',
    ]),
    // todo move to service
    getCover(examination) {
      let photos = examination?.biometric?.photos || []

      if (!photos.length) return null

      let elem = photos[Math.floor(Math.random() * photos.length)]

      return elem.image
    },
    allowToChange() {
      return this.localPhotos.length >= this.min && this.localPhotos.length <= this.max
    },
    next() {
      if (!this.localPhotos.length) {
        alert('Photos not uploaded')
        return false
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/profiles/increment-register-step`, {
        _method: 'put'
      })
          .then((resp) => {
            this.EXEC_INCREMENT_REGISTER_STEP()
            this.EXEC_REFRESH_USER(resp.data.data.user)
          }).catch(err => alert(converValidationServerErrorsToString(err)))
    }
  }
}
</script>

<style scoped>

</style>
