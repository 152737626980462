<template>
  <v-card>
    <v-card-title>
      Фильтр
    </v-card-title>

    <v-card-text>
      <!--<h2 >Profile</h2>-->

      <v-range-slider
          class="mt-8"
          v-model="search.profile.age_range"
          label="Возраст"
          :min="ageMin"
          :max="ageMax"
          color="red"
          thumb-label="always"
      />

      <v-row>
        <v-col>
          <v-select v-model="search.profile.sex" :items="sexList" label="Пол" item-text="name"
                    item-value="id"/>
        </v-col>
        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.profile.sex = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.profile.city_live_id"
                    :items="cities"
                    label="Город"
                    item-text="name"
                    item-value="id"
          />
        </v-col>
        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.profile.city_live_id = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {castes, cities, profilePairStatusesEnum, sexEnum, userModesList, userStatusesEnum} from "@/enums/enums"
import FilterDatepicker from "../FilterDatePicker"

const _ = require('lodash')

export default {
  name: "TariffUsageFilter",
  data() {
    return {
      ageMin: 18,
      ageMax: 84,

      search: {
        profile: {
          age_range: [
            18,
            84
          ],
          sex: null,
          city_live_id: null,
        }
      }
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_TARIFF_USAGE_RESET, async () => {
      console.log('eventBusEvents.EVENT_FILTER_TARIFF_USAGE_RESET_____')
      this.resetFilter()
    });
  },
  computed: {
    cities: function () {
      return cities
    },
    sexList: function () {
      return sexEnum
    }
  },
  methods: {
    resetCalendar() {
      eventBus.$emit(eventBusEvents.EVENT_CALENDAR_FILTER_RESET)
    },
    resetFilter() {
      this.resetCalendar()

      this.search = {
        profile: {
          age_range: [
            18,
            84
          ],
          sex: null,
          city_live_id: null,
        }
      }
    },
    commitFilter(data) {
      let modifyProfile = {}

      Object.keys(data.profile)
          .filter(key => key !== 'age_range' && !!data.profile[key])
          .filter(key => data.profile[key] !== null && data.profile[key] !== '')
          .forEach(key => modifyProfile[`profile_${key}`] = data.profile[key])

      let result = {...modifyProfile}
      result.profile_age_from = data.profile.age_range[0]
      result.profile_age_to = data.profile.age_range[1]

      eventBus.$emit(eventBusEvents.EVENT_FILTER_TARIFF_USAGE, result)
    }
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.commitFilter(v)
      }, 1000),
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
