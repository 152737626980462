<template>
  <v-dialog
      v-model="dialog"
      @input="onInput()"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" color="grey" v-bind="attrs" v-on="on" fab x-small dark>
        <v-icon aria-hidden="false">
          mdi-account
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          Профиль
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn @click="closeDialog()" dark text>
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <ProfilePanel :profile_id="profile_id"/>

      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfilePanel from "./ProfileDetailPanel";

export default {
  name: "ProfileControlDialog",
  props: [
    'profile_id'
  ],
  components: {
    ProfilePanel
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    onInput() {
      console.log('EVENT_REFRESH_PROFILE_BY_ADMIN___onInput')

      // todo данный подход сразу закрывает модальное окно
      // eventBus.$emit(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN)
    },
    closeDialog() {
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
