<template>
  <v-card v-if="pairLocal">
    <v-card-title>
      Статус пары:
      <v-divider/>
      <PairStatusChip :status="pairLocal.status"/>
    </v-card-title>

    <v-card-actions>
      <v-btn
          @click="changeStatusDecline()"
          v-if="!isDeclined(pairLocal.status)"
      >
        <v-icon aria-hidden="false" color="red"> mdi-alert-decagram</v-icon>
        Разорвать пару
      </v-btn>

      <!--todo тестовые кнопки. Истинным триггером выступают пользовательские решения-->
      <!--<v-btn
              @click="changeStatusCreate()"
              v-if="!isCreated(pairLocal.status)"
              color="secondary"
              dark
      >
          <v-icon aria-hidden="false" light>
              mdi-clock-time-seven-outline
          </v-icon>
          Создана
      </v-btn>

      <v-btn
              @click="changeStatusMatch()"
              v-if="!isMatched(pairLocal.status)"
              color="green"
      >
          <v-icon aria-hidden="false" light>
              mdi-check
          </v-icon>
          Совпала
      </v-btn>

      <v-btn
              @click="changeStatusMismatch()"
              v-if="!isMismatch(pairLocal.status)"
              color="primary"
      >
          <v-icon aria-hidden="false" light>
              mdi-check
          </v-icon>
          Не совпала
      </v-btn>
      <v-btn
              @click="changeStatusResumed()"
              v-if="!isResumed(pairLocal.status)"
              color="orange"
      >
          <v-icon aria-hidden="false" light>
              mdi-repeat
          </v-icon>
          На повтор
      </v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import {pairStatusesList} from "@/enums/enums";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import PairStatusChip from "./PairStatusChip";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";

export default {
  name: "PairStatusesControl",
  props: ['pair'],
  components: {
    PairStatusChip
  },
  data() {
    return {
      pairLocal: null
    }
  },
  computed: {
    pairStatusesList: function () {
      return pairStatusesList
    },
  },
  mounted() {
    this.pairLocal = this.pair
  },
  methods: {
    isCreated(status) {
      return status === pairStatusesList.created
    },
    isMatched(status) {
      return status === pairStatusesList.matched
    },
    isMismatch(status) {
      return status === pairStatusesList.mismatch
    },
    isDeclined(status) {
      // pairLocal.declined_at
      return status === pairStatusesList.declined
    },
    isResumed(status) {
      return status === pairStatusesList.resumed
    },
    changeStatusDecline() {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${this.pair.id}/decline}`, {
        _method: 'put'
      })
          .then(resp => {
            let bufferPair = resp.data.data.pair
            this.pairLocal.status = bufferPair.status
            eventBus.$emit(eventBusEvents.EVENT_REFRESH_PAIR_BY_ADMIN, bufferPair)
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));

      //this.defineStatus(pair, 'decline')
    },
    /*changeStatusCreate(pair) {
      this.defineStatus(pair, 'reset')
    },
    changeStatusMatch(pair) {
      this.defineStatus(pair, 'match')
    },
    changeStatusMismatch(pair) {
      this.defineStatus(pair, 'mismatch')
    },
    changeStatusResumed(pair) {
      this.defineStatus(pair, 'resume')
    },*/
    defineStatus(pair, statusAction) {
      /*this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${this.pair.id}/${statusAction}`, {
        _method: 'put'
      })
          .then(resp => {
            let bufferPair = resp.data.data.pair
            this.pairLocal.status = bufferPair.status
            eventBus.$emit(eventBusEvents.EVENT_REFRESH_PAIR_BY_ADMIN, bufferPair)
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));*/
    },
    decline() {
      console.log('decline........')

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${this.pair.id}/decline`, {
        _method: 'put'
      })
          .then(res => this.pairLocal = res.data.data.pair)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
