<template>
  <div>
    <ProfilePresentable :profile="profile" v-if="profile"/>
  </div>
</template>

<script>

import ProfilePresentable from "../../../components/Profile/ProfilePresentable";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: "VipPersonCandidateDetailPage",
  components: {
    ProfilePresentable
  },
  mounted() {
    console.log('params', this.$route.params)
    this.profileId = this.$route.params.profileId
    this.keyHash = this.$route.params.keyHash
    this.invokeProfile()
  },
  data() {
    return {
      profileId: null,
      profile: null,
      keyHash: null,
    }
  },
  methods: {
    invokeProfile() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/vip/${this.keyHash}/profiles/${this.profileId}`)
          .then(res => {
            this.profile = res.data.data.profile
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
    }
  }
}
</script>

<style scoped>

</style>
