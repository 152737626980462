<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="promoLocal.name"
          :rules="rules.nameRules"
          label="Название"
          type="text"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-text-field
          v-model="promoLocal.meetings"
          :rules="rules.meetingsRules"
          label="Кол-во свиданий"
          type="number"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-text-field
          v-model="promoLocal.quantity"
          :rules="rules.quantityRules"
          label="Количество"
          type="number"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <FilterDatepicker
          :fieldName="'started_at'"
          :label="'Действует до'"
          :value="promoLocal.finished_at"
          @update="updateFinishedAt"
      />

      <v-select v-model="promoLocal.status"
                :items="statuses"
                label="Статус"
                item-text="name"
                item-value="id"
      />

      <!--
      // todo автоматически берется из родителя
      <v-select v-model="promoLocal.category_id"
                :items="parentsList"
                label="Родитель"
                item-text="name"
                item-value="id"
      />-->

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {mapActions} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, defaultStatusesEnum} from "@/enums/enums";
import FilterDatepicker from "../../components/FilterDatePicker"

require('dotenv').config()

export default {
  name: "promoForm",
  props: ['action', 'promo', 'promoCategory'],
  components: {
    FilterDatepicker
  },
  data() {
    return {
      valid: true,
      actionLocal: null,

      parents: [],

      promoLocal: {
        id: null,
        name: "",
        status: null,
        finished_at: null,
        quantity: null,
        meetings: null,
        category_id: null,
      },
      rules: {
        nameRules: [
          v => !!v || 'Name must be valid',
          v => !!v && v.length > 3 || 'Name length must be more than 3 characters',
        ],
        quantityRules: [
          v => !!v || 'Quantity must be valid',
          v => !!v && v.toString().length >= 1 || 'Quantity length must be more than 3 characters',
        ],
        meetingsRules: [
          v => !!v || 'Meetings must be valid',
          v => !!v && v.toString().length >= 1 || 'Meetings length must be more than 1 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    statuses: function () {
      return defaultStatusesEnum
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update';
    },
    parentsList: function () {
      if (!this.parents || !this.parents.length)
        return []

      if (this.actionLocal === this.crudActions.create) {
        return this.parents
      } else {
        return this.parents
            .filter(it => it.id !== this.promoLocal.id)
            .map(it => ({
              id: it.id,
              name: it.name,
            }))
      }
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_PROMO_BY_ADMIN, async promo => {
      console.log('EVENT_EDIT_PROMO_BY_ADMIN', promo)
      this.edit(promo)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PROMO_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    this.actionLocal = this.action

    if (this.actionLocal === this.crudActions.update) {
      this.promoLocal = this.promo
    }

    this.promoLocal.category_id = this.promoCategory.id
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
      'EXEC_AUTH_SUCCESS',
      'EXEC_LOGOUT'
    ]),
    updateFinishedAt(val) {
      this.promoLocal.finished_at = val
    },
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.promoLocal.id = data.id
      this.promoLocal.name = data.name
      this.promoLocal.meetings = data.meetings
      this.promoLocal.status = data.status
      this.promoLocal.quantity = data.quantity

      console.log('data.finished_at___', data.finished_at)

      let date = moment(data.finished_at).format('YYYY-MM-DD');
      this.promoLocal.finished_at = date

      // this.promoLocal.category_id = data.parent_id
    },
    refresh() {
      this.actionLocal = this.crudActions.create

      this.promoLocal.id = null
      this.promoLocal.name = ""
      this.promoLocal.meetings = null
      this.promoLocal.status = null
      this.promoLocal.quantity = null
      this.promoLocal.finished_at = null
      // this.promoLocal.category_id = null
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    // todo my be move to UserForm
    create() {
      console.log('create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promos`,
          this.promoLocal
      ).then(resp => {
        console.log('New promo: ', resp.data)
        eventBus.$emit(eventBusEvents.EVENT_CREATE_PROMO_BY_ADMIN)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },

    // todo my be move to UserForm
    update() {
      console.log('update PromoForm')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.promoLocal,
        ...{
          _method: 'put'
        }
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promos/${this.promoLocal.id}`, data)
          .then(() => eventBus.$emit(eventBusEvents.EVENT_REFRESH_PROMO_BY_ADMIN))
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.promoLocal.category_id) {
        alert('category_id is empty')
        return false
      }
      if (!this.promoLocal.name) {
        alert('name is name')
        return false
      }
      if (!this.promoLocal.status) {
        alert('status is empty')
        return false
      }
      if (!this.promoLocal.finished_at) {
        alert('finished_at is empty')
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
