import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import LoadScript from "vue-plugin-load-script";
// todo auth move to Cookie httpOnly
import {VueMaskDirective} from 'v-mask'
import VueDadata from 'vue-dadata'
import {store} from './store'
import {router} from './router/router'
import VueApexCharts from 'vue-apexcharts'

// todo prepare tiptap editor
// import plugin
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'md'
})

// sockets
//import Echo from "laravel-echo";

window.io = require('socket.io-client')

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.directive('mask', VueMaskDirective)
Vue.use(VueDadata)
Vue.prototype.$http = Axios
Vue.config.productionTip = false
Vue.use(require('vue-moment'))
Vue.use(VueRouter)
Vue.use(LoadScript);

require('dotenv').config()

store.commit('initialiseStore')

// sockets

// todo socket blocking on time
// if (typeof io !== 'undefined') {
//     window.Echo = new Echo({
//         broadcaster: 'socket.io',
//         host: process.env.VUE_APP_ENV === 'dev' ?
//             'http://drsvat-api:6001' :
//             'https://api.drsvat.com:6001'
//     })
// }

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

Promise.resolve()
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-app.js"))
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-messaging.js"))
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-analytics.js"))
    .then(() => {
        window.firebase.initializeApp(firebaseConfig);
        const messaging = window.firebase.messaging();

        messaging.requestPermission()
            .then(() => {
                console.log("Have permission")
                return messaging.getToken()
            }).then(token => console.log(`FCM-Token: ${token}`)) // todo save to storage
            .catch(err => console.log("Error Occured: ", err))

        messaging.onMessage(payload => {
            // todo need to service worker implementing for work
            console.log('onMessage: ', payload)
        })
    });

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    // todo check
    // mounted() {
    //     window.Echo.channel('notifications')
    //         .listen('NotificationEvent', function (data) {
    //             /* eslint-disable no-console */
    //             console.log(data)
    //             console.log('data')
    //         })
    // }
}).$mount('#app')
