<template>
  <v-card>
    <v-card-title>
      RegisterBirthday
    </v-card-title>

    <v-card-text>
      {{ birthdayLocal }}

      <v-text-field type="date" v-model="birthdayLocal"/>

      <!--<v-date-picker
              v-model="birthdayLocal"
              year-icon="mdi-calendar-blank"
              prev-icon="mdi-skip-previous"
              next-icon="mdi-skip-next"
      />-->
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";

export default {
  name: "BirthdayPanel",
  props: ['birthday', 'profile'],
  data() {
    return {
      birthdayLocal: null
    }
  },
  mounted() {
    this.birthdayLocal = this.birthday
  },
  methods: {
    change() {
      if (!this.birthdayLocal) {
        alert('Birthday is empty')
        return false
      }

      if (!this.birthdayLocal.match(/\d{4}-\d{2}-\d{2}/)) {
        alert('Data is invalid')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/birthday` : `/profile/birthday`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        birthday: this.birthdayLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_BIRTHDAY, this.birthdayLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
