<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="tariffLocal.name"
          :rules="rules.nameRules"
          label="name"
          type="text"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <p>
        OS_TYPE TODO
      </p>
      <p>
        CITY_TYPE TODO
      </p>
      <p>
        AGE_TYPE TODO
      </p>

      <v-text-field
          v-model="tariffLocal.product_id"
          :rules="rules.productRules"
          label="Код продукта"
          type="text"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-text-field
          v-model="tariffLocal.price"
          :rules="rules.priceRules"
          label="Price"
          type="number"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-text-field
          v-model="tariffLocal.sale"
          :rules="rules.saleRules"
          :counter="1"
          label="Sale"
          type="number"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          readonly
          required
      />
      <!--  v-mask="'##'" -->


      <v-text-field
          v-model="tariffLocal.meetings"
          :counter="3"
          :rules="rules.meetingsRules"
          v-mask="'##'"
          label="Meetings"
          type="text"
          @keyup.enter="handleSubmit"
          required
      />

      <v-text-field
          v-model="tariffLocal.body"
          :rules="rules.valueRules"
          label="Body"
          type="text"
          @keyup.enter="handleSubmit"
          required
      />

      <v-select v-model="tariffLocal.status"
                :items="statuses"
                label="Статус"
                item-text="name"
                item-value="id"
      />

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>

      <!--
      <v-text-field
            v-model="tariffLocal.google_sub_id"
            :rules="rules.nameRules"
            label="Код продукта в Google"
            type="text"
            @keyup.enter="handleSubmit"
            onfocus="this.removeAttribute('readonly')"
            required
            readonly
        />

        <v-text-field
            v-model="tariffLocal.apple_sub_id"
            :rules="rules.nameRules"
            label="Код продукта в Apple"
            type="text"
            @keyup.enter="handleSubmit"
            onfocus="this.removeAttribute('readonly')"
            required
            readonly
        />

        <v-text-field
          v-model="tariffLocal.man_rate"
          :rules="rules.rateRules"
          :counter="2"
          v-mask="'##'"
          label="Man rate"
          type="text"
          @keyup.enter="handleSubmit"
          required
      />

      <v-text-field
          v-model="tariffLocal.woman_rate"
          :rules="rules.rateRules"
          :counter="2"
          v-mask="'##'"
          label="Woman rate"
          type="text"
          @keyup.enter="handleSubmit"
          required
      />-->

    </v-form>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {mapActions} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, defaultStatusesEnum} from "@/enums/enums";

require('dotenv').config()

export default {
  name: "TariffForm",
  props: ['action', 'tariff'],
  data() {
    return {
      valid: true,
      actionLocal: null,

      tariffLocal: {
        name: "",
        body: "",
        price: null,
        sale: null,
        man_rate: null,
        woman_rate: null,
        meetings: null,
        status: null,
      },
      rules: {
        nameRules: [
          v => !!v || 'Name must be valid',
          v => v.length > 3 || 'Name length must be more than 3 characters',
        ],
        productRules: [
          v => !!v || 'Product must be valid',
          v => v.length > 3 || 'Product length must be more than 3 characters',
        ],
        priceRules: [
          v => !!v || 'Price must be valid',
          v => v.toString().length > 1 || 'Price length must be more than 1 characters',
        ],
        saleRules: [
          v => !!v || 'Sale must be valid',
          v => v.toInteger() >= 0 || 'Sale length must be more than 1 characters'
        ],
        rateRules: [
          // v => !!v || 'Rate must be valid',
          // v => v.toString().length >= 1 || 'Rate length must be more than 1 characters',
        ],
        meetingsRules: [
          v => !!v || 'Meetings must be valid',
          v => v.toString().length >= 1 || 'Meetings length must be more than 1 characters',
        ],
        // bodyRules: [
        //     v => !!v || 'Body must be valid',
        //     v => v.length > 2 || 'Body length must be more than 2 characters',
        // ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    statuses: function () {
      return defaultStatusesEnum;
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update';
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_TARIFF_BY_ADMIN, async tariff => {
      console.log('EVENT_EDIT_TARIFF_BY_ADMIN', tariff)
      this.edit(tariff)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_TARIFF_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    this.actionLocal = this.action

    if (this.actionLocal === this.crudActions.update) {
      this.tariffLocal = this.tariff
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
      'EXEC_AUTH_SUCCESS',
      'EXEC_LOGOUT'
    ]),
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.tariffLocal.id = data.id
      this.tariffLocal.name = data.name
      this.tariffLocal.body = data.body
      this.tariffLocal.price = data.price
      this.tariffLocal.sale = data.sale
      // this.tariffLocal.man_rate = data.man_rate
      // this.tariffLocal.woman_rate = data.woman_rate
      this.tariffLocal.meetings = data.meetings
      this.tariffLocal.status = data.status

      this.tariffLocal.product_id = data.product_id
      // this.tariffLocal.google_sub_id = data.google_sub_id
      // this.tariffLocal.apple_sub_id = data.apple_sub_id
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.tariffLocal.name = null
      this.tariffLocal.body = null
      this.tariffLocal.price = null
      this.tariffLocal.sale = null
      // this.tariffLocal.man_rate = null
      // this.tariffLocal.woman_rate = null
      this.tariffLocal.meetings = null
      this.tariffLocal.status = null
      // this.tariffLocal.google_sub_id = null
      // this.tariffLocal.apple_sub_id = null
      this.tariffLocal.product_id = null
    },
    handleSubmit(e) {
      e.preventDefault()

      this.tariffLocal.price.replace(',', '.')

      // this.tariffLocal.man_rate = parseFloat(this.tariffLocal.man_rate)
      // this.tariffLocal.woman_rate = parseFloat(this.tariffLocal.woman_rate)

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    // todo my be move to UserForm
    create() {
      console.log('create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/tariffs`,
          this.tariffLocal
      ).then(resp => {
        console.log('New Tariff: ', resp.data)
        eventBus.$emit(eventBusEvents.EVENT_CREATE_TARIFF_BY_ADMIN)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },

    // todo my be move to UserForm
    update() {
      console.log('update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.tariffLocal,
        ...{
          _method: 'put'
        }
      }

      console.log('data___', data)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/tariffs/${this.tariffLocal.id}`, data)
          .then(() => eventBus.$emit(eventBusEvents.EVENT_REFRESH_TARIFF_BY_ADMIN))
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.tariffLocal.name) {
        alert('name is name')
        return false
      }
      if (!this.tariffLocal.price) {
        alert('phone is price')
        return false
      }
      if (!this.tariffLocal.sale) {
        alert('password sale empty')
        return false
      }
      if (!this.tariffLocal.man_rate) {
        alert('man_rate is empty')
        return false
      }
      if (!this.tariffLocal.woman_rate) {
        alert('woman_rate is empty')
        return false
      }
      if (!this.tariffLocal.meetings) {
        alert('meetings is empty')
        return false
      }
      if (!this.tariffLocal.status) {
        alert('status is empty')
        return false
      }
      /*if (!this.tariffLocal.body) {
          alert('email is body')
          return false
      }*/

      return true
    }
  }
}
</script>

<style scoped>

</style>
