<template>
  <v-card>
    <v-card-title>
      RegisterSearchHeight
    </v-card-title>

    <v-card-text>
      <v-range-slider
          v-model="heightRangeLocal"
          hint="Im a hint"
          label="Height"
          :max="max"
          :min="min"
          color="red"
          thumb-label="always"
      ></v-range-slider>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "SearchHeightPanel",
  props: ['heightRange', 'profile'],
  data() {
    return {
      heightRangeLocal: [120, 185],
      min: 100,
      max: 250,
    }
  },
  mounted() {
    this.heightRangeLocal = this.heightRange || [120, 185]
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    change() {
      if (!this.heightRangeLocal) {
        alert('heightRange is empty')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/search-height` : `/profile/search-height`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        search_height_from: this.heightRangeLocal[0],
        search_height_to: this.heightRangeLocal[1],
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_SEARCH_HEIGHT, this.heightRangeLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
