require('dotenv').config()
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export const state = () => ({
    processingRequest: false,
    is_logged: false,
    token: null,
    user: {},
})

export const getters = {
    IS_LOGGED_IN(state) {
        return !!state.token
    },

    GET_USER(state) {
        return state.user
    },

    GET_TOKEN(state) {
        return state.token
    },

    GET_PROCESSING_REQUEST: state => {
        return state.processingRequest
    },
}

export const mutations = {
    initialiseStore(state) {
        let token = localStorage.getItem('token')
        // console.log('foo token', token)

        if (token) {
            state.token = token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        }

        let user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            state.user = user;
        }
    },

    SET_USER(state, user) {
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
    },

    SET_TOKEN(state, token) {
        state.token = token
        localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },

    SET_LOGOUT(state) {
        state.user = {}
        state.token = null
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
    },

    SET_PROCESSING_REQUEST(state, bool) {
        state.processingRequest = bool;
    },
};

export const actions = {
    EXEC_PROCESSING_REQUEST({commit}, bool) {
        commit('SET_PROCESSING_REQUEST', bool)
    },

    EXEC_AUTH_SUCCESS({commit}, {token, user}) {
        commit('SET_USER', user)
        commit('SET_TOKEN', token)
    },

    EXEC_CHANGE_PROFILE_PARAM({commit, getters}, {key, value}) {
        // console.log(key + ' => ' + value)
        let profile = {...getters.GET_USER.profile, ...{[key]: value}}
        let reformatUser = {...getters.GET_USER, ...{profile}};
        commit('SET_USER', reformatUser)
    },

    EXEC_INCREMENT_REGISTER_STEP({getters, dispatch}) {
        dispatch('EXEC_CHANGE_PROFILE_PARAM', {
            key: 'register_step',
            value: ++getters.GET_USER.profile.register_step
        })
    },

    EXEC_DECREMENT_REGISTER_STEP({getters, dispatch}) {
        dispatch('EXEC_CHANGE_PROFILE_PARAM', {
            key: 'register_step',
            value: --getters.GET_USER.profile.register_step
        })
    },

    EXEC_REFRESH_PHOTOS({dispatch}, photos) {
        dispatch('EXEC_CHANGE_PROFILE_PARAM', {
            key: 'photos',
            value: photos
        })
    },

    EXEC_CREATE_PROFILE_DOCUMENT({getters, commit}, {document, type}) {
        let user = getters.GET_USER
        let profile = user.profile
        let documents = profile.documents
        let documentsArr = documents.filter(it => it.d_type !== type)

        documentsArr.push(document)

        profile.documents = documentsArr

        let reformatUser = {...getters.GET_USER, ...{profile}};

        commit('SET_USER', reformatUser)
    },

    EXEC_DELETE_PROFILE_DOCUMENT({getters, commit}, {document, type}) {
        let profile = getters.GET_USER.profile
        let documents = profile.documents
        let documentsArr = profile.documents = documents.findIndex(it => it.d_type !== type)
        let index = documentsArr.findIndex(it => it.id === document.id)

        if (index) {
            documentsArr.slice(index, 1)
        }

        profile.documents = documentsArr

        let reformatUser = {...getters.GET_USER, ...{profile}};
        commit('SET_USER', reformatUser)
    },

    COMPLETE_REGISTER({dispatch}) {
        dispatch('EXEC_CHANGE_PROFILE_PARAM', {
            key: 'register_step',
            value: 0
        })
    },

    EXEC_CHANGE_USER_PARAM({commit}, {param, val}) {
        commit('SET_USER', {...getters.GET_USER, ...{[param]: val}})
    },

    EXEC_REFRESH_USER({commit}, user) {
        commit('SET_USER', user)
    },

    EXEC_LOGOUT({commit}) {
        return new Promise(resolve => {
            commit('SET_LOGOUT')
            resolve()
        })
    },
};

const storeTotal = {
    state,
    mutations,
    getters,
    actions
}

export const store = new Vuex.Store(storeTotal)

export default store
