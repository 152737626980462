<template>
  <div v-if="registerStepLocal">
    <!--<v-row>
        <v-col>
            <RegisterStepStatusesControl :register_step="registerStepLocal" />
        </v-col>
    </v-row>-->

    <v-card>
      <v-card-title>
        {{ registerStepLocal.id }} - {{ registerStepLocal.name }}
      </v-card-title>

      <v-card-text>
        <RegisterStepForm
            v-if="this.actionLocal"
            :action="actionLocal"
            :register_step="registerStepLocal"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import RegisterStepForm from "./RegisterStepForm";

export default {
  name: "RegisterStepPanel",
  props: ['register_step', 'action'],
  components: {
    RegisterStepForm
  },
  data() {
    return {
      registerStepLocal: null,
      actionLocal: null,
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_BOOLEAN_REGISTER_STEP_BY_ADMIN, async regStep => {
      console.log('oppa___', regStep)
      this.registerStepLocal = regStep
      this.getRegisterSteps()
    })
  },
  mounted() {
    this.actionLocal = this.action

    this.getRegisterSteps()
  },
  computed: {},
  methods: {
    getRegisterSteps() {
      console.log('getRegisterSteps________')

      console.log('this.register_step______________________________: ', this.register_step)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/register-steps/${this.register_step.id}`)
          .then(res => this.registerStepLocal = res.data.data.register_step)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
