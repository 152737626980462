const queryString = require('query-string');

const getWhatsappLinkByPhone = phone => {
    let phoneModify = phone
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('+', '')
        .replaceAll(' ', '')

    return `https://api.whatsapp.com/send?phone=${phoneModify}`
}

const objectToQueryString = data => {
    return queryString.stringify(data, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
    })
}

export {getWhatsappLinkByPhone, objectToQueryString}
