<template>
  <v-container class="col-md-6 offset-md-3">
    <v-card>
      <v-card-title>
        Cabinet
      </v-card-title>

      <v-card-text>
        <p>
          Info about cabinet
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>

</style>
