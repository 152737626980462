<template>
  <div v-if="!!profile">
    <h4>Предложения</h4>

    <v-row>
      <v-col>
        <v-btn color="deep-purple lighten-2" @click="searchOffers" text>
          Получить список
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <div v-if="showOffersInfo">
          Всего:
          <v-chip>{{ offers.length }}</v-chip>
        </div>
      </v-col>
    </v-row>

    <v-simple-table v-if="offers">
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Кандидат</td>
          <td>History</td>
          <td>Daily</td>
        </tr>

        <tr v-for="offer in offers" :key="offer.id">
          <td>
            {{ offer.profile.first_name }} - {{ offer.id }}
          </td>
          <td>
            <VueJsonPretty :path="'res'" :data="offer.history"/>
          </td>
          <td>
            <VueJsonPretty :path="'res'" :data="offer.daily"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider/>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: "ProfileOffers",
  props: ['profile'],
  components: {
    VueJsonPretty
  },
  data() {
    return {
      showOffersInfo: false,
      offers: [],
    }
  },
  mounted() {
    this.searchOffers()
  },
  methods: {
    searchOffers() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profile.id}/offers`)
          .then(response => {
            this.offers = response.data.data.items
            this.showOffersInfo = true
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
