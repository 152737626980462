<template>
  <v-card>
    <v-card-text>
      <v-btn @click="change()" :class="isVipLocal ? 'orange' : 'grey'">
        VIP
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {casteEnum} from "@/enums/enums";

export default {
  name: "VipForm",
  props: ['profile', 'is_vip'],
  data() {
    return {
      isVipLocal: null
    }
  },
  mounted() {
    this.isVipLocal = this.is_vip
  },
  computed: {
    casteList: function () {
      return casteEnum
    }
  },
  methods: {
    change() {
      let url = `/admin/profiles/${this.profile.id}/vip`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        _method: 'put'
      })
          .then(() => {
            this.isVipLocal = !this.isVipLocal
            eventBus.$emit(eventBusEvents.EVENT_CHANGE_PRESENTABLE, this.isVipLocal);
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
