<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" :color="getIconColor()" v-bind="attrs" v-on="on" fab x-small dark>
        <v-icon aria-hidden="false">
          {{ getIcon() }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          Faq
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn @click="dialog = false" dark text>
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row>
        <v-col>
          <FaqPanel :action="action" :faq="faq"/>
        </v-col>
      </v-row>

      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import {crudActions} from "@/enums/enums";
import FaqPanel from "./FaqPanel";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";

export default {
  name: 'FaqControlDialog',
  props: ['faq', 'action'],
  components: {
    FaqPanel,
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CREATE_FAQ_BY_ADMIN, async () => {
      this.close()
    })
  },
  methods: {
    getIcon() {
      return this.faq ? 'mdi-pencil' : 'mdi-plus'
    },
    getIconColor() {
      return this.faq ? 'orange' : 'info'
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
