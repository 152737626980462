<template>
  <div>
    <v-row>
      <v-col cols="11">
        <FaqGrid/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <!--<v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher :clipped="false">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>

        <BiometricFilter
                class="mb-4"
        />

        <v-divider/>
    </v-navigation-drawer>-->
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import FaqGrid from "../../components/Faq/FaqGrid";

export default {
  name: "AdminFaqPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    FaqGrid,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
