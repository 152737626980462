<template>
<!--  v-if="userLocal"  -->
  <v-card>
    <v-card-title>
      Пульт управления: {{ getInfo() }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          actionLocal: {{ actionLocal }}

          <!--<template v-if="isAllowed()">-->
          <template v-if="isAllowed() && userLocal"> <!--Вероятно с випами так не получится работать-->
            <UserForm
                v-if="actionLocal"
                :action="actionLocal"
                :user="userLocal"
                :profile="profileLocal"
            />
          </template>

          <!--UserPanel.userLocal: {{ userLocal }}-->
        </v-col>

        <v-col>
          <UserDetailsPanel :user_id="user_id" v-if="user_id"/>

          <!--<UserDetailsPanel :user="user" v-if="userLocal"/>-->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {crudActions, userStatusesList} from "@/enums/enums";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import UserForm from "./UserForm";
import UserDetailsPanel from "./UserDetailsPanel";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {mapActions} from "vuex";

export default {
  name: "UserPanel",
  props: ['action', 'user_id', 'profile'],
  components: {
    UserForm,
    UserDetailsPanel
  },
  data() {
    return {
      actionLocal: null,
      userLocal: null,
      profileLocal: null,
    }
  },
  mounted() {
    this.actionLocal = this.action
    // this.userLocal = this.user
    this.profileLocal = this.profile

    if(this.action === crudActions.update) {
      this.getUserInfo()
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_EDIT_USER_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.update
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN, async () => {
      this.actionLocal = this.crudActions.create
    });
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    getInfo() {
      return `${this.userLocal?.id} ${this.userLocal?.name}`
    },
    isDeleted() {
      return this.userLocal?.status === userStatusesList.deleted
    },
    isAllowed() {
      return !this.isDeleted()
    },
    goToCreate() {
      this.actionLocal = this.crudActions.create
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN)
    },
    isActionCreate() {
      return this.actionLocal === this.crudActions.create
    },
    isActionUpdate() {
      return !this.isActionCreate()
    },
    getUserInfo() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${this.user_id}`)
          .then(response => {
            console.log('UserPanel.resp. ', response.data.data.user)
            this.userLocal = response.data.data.user
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>
