<template>
  <v-btn :to="`sheets/${sheet_id}`" class="ml-2" color="orange" fab x-small dark>
    <v-icon aria-hidden="false">
      mdi-pencil
    </v-icon>
  </v-btn>
</template>

<script>

export default {
  name: "SheetRouteLink",
  props: [
    'sheet_id'
  ],
}
</script>

<style scoped>

</style>
