<template>
  <v-card>
    <v-card-title>
      RegisterHeight
    </v-card-title>

    <v-card-text>
      <v-slider
          v-model="heightLocal"
          hint="Im a hint"
          label="height"
          :max="max"
          :min="min"
          color="red"
          thumb-label="always"
      ></v-slider>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: "BirthdayPanel",
  props: ['height', 'profile'],
  data() {
    return {
      heightLocal: 170,
      min: 100,
      max: 250,
    }
  },
  mounted() {
    this.heightLocal = this.height
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    change() {
      if (!this.heightLocal) {
        alert('Height is empty')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/height` : `/profile/height`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        height: this.heightLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_HEIGHT, this.heightLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
