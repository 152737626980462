<template>
  <v-card>
    <v-card-title>
      Свидания

      <v-btn class="ml-2" color="green" fab x-small dark @click="addMeeting()" v-if="!!profile_id">
        <v-icon aria-hidden="false">
          mdi-plus
        </v-icon>
        1
      </v-btn>

      <v-btn class="ml-2" color="red" fab x-small dark @click="subtractMeeting()" v-if="!!profile_id">
        <v-icon aria-hidden="false">
          mdi-minus
        </v-icon>
        1
      </v-btn>

      <v-btn class="ml-4" color="orange" @click="getMeetingsList()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-spacer/>

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details/>
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="meetings"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          class="elevation-1"
          item-key="id"
          :items-per-page="itemsPerPage"
          :item-class="itemRowBackground"
          show-select
      >
        <template v-slot:item.profile="{ item }">
          <ProfileControlDialog :profile_id="item.profile.id" @close="close()"/>
          {{ item.profile.first_name }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at }}
<!--          <br/>-->
<!--          {{ convertUtcToDate(item.created_at) }}-->
        </template>

        <template v-slot:item.m_type="{ item }">
          <MeetingType :meeting_type="item.m_type"/>
        </template>

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, sexList} from "@/enums/enums";
import DefaultStatus from "../DefaultStatus";
import MeetingType from "./MeetingType";
import {mapActions, mapGetters} from "vuex"
import {convertUtcToDate} from "../../utils/converters";

const queryString = require('query-string');

export default {
  name: "MeetingGrid",
  props: ['url', 'profile_id'],
  mounted() {
    this.getMeetingsList()
  },
  components: {
    DefaultStatus,
    ProfileControlDialog: () => import('../Profile/ProfileControlDialog'),
    MeetingType
  },
  data() {
    return {
      meetings: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},
      itemsPerPage: 100,

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Дата',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Профиль',
          align: 'start',
          sortable: true,
          value: 'profile',
        },
        {
          text: 'Тип',
          align: 'start',
          sortable: true,
          value: 'm_type',
        },
        {
          text: 'Номер заказа',
          align: 'start',
          sortable: true,
          value: 'order_id',
        },
        /*{
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
        },*/
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
    }),
    convertUtcToDate(created_at) {
      console.log('created_at: ', created_at)
      return convertUtcToDate(created_at)
    },
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_MEETINGS_BY_ADMIN, async () => {
      this.getMeetingsList()
    });
    eventBus.$on(eventBusEvents.EVENT_CREATE_MEETINGS_BY_ADMIN, async () => {
      this.getMeetingsList()
    });
    eventBus.$on(eventBusEvents.EVENT_FILTER_MEETINGS, async search => {
      this.queryParams = search
      this.getMeetingsList()
    });
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    itemRowBackground(profile) {
      return profile.sex === sexList.man ? 'cyan lighten-5' : 'red lighten-5'
    },
    getMeetingsList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}?${q}`)
          .then(response => this.meetings = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    addMeeting() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profile_id}/meetings`).then(() => {
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_MEETINGS_BY_ADMIN)
        this.getMeetingsList()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    subtractMeeting() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profile_id}/meetings`).then(() => {
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_MEETINGS_BY_ADMIN)
        this.getMeetingsList()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>
