<template>
  <div>
    <v-row>
      <v-col cols="12">
        <SheetPanel :sheet_id="sheet_id"
                    :action="crudActions.update"
                    v-if="sheet_id"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import SheetPanel from "@/components/Sheet/SheetPanel";
import {crudActions} from "@/enums/enums";

export default {
  name: "EditorSheetDetailPage",
  mounted() {
    this.sheet_id = this.$route.params.sheetId
  },
  data() {
    return {
      sheet_id: null
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  components: {
    SheetPanel
  }
}
</script>

<style scoped>

</style>
