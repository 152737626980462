<template>
  <v-card>
    <v-card-title>
      UsersPage

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="promoCategories"
          :expanded.sync="expanded"
          :search="search"
          item-key="id"
          show-select
          :sort-by="['_lft']"
          :sort-desc="[false]"
          class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <PromoControlDialog
              :promoCategory="item"
          />

          <v-btn class="ml-2"
                 @click="edit(item)"
                 color="info"
                 fab
                 x-small
                 dark>

            <v-icon aria-hidden="false">
              mdi-pencil
            </v-icon>
          </v-btn>

          <AgreeDialog
              @remove="remove(item)"
              class="ml-2"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import AgreeDialog from "../AgreeDialog";
import PromoControlDialog from "../Promo/PromoControlDialog";

const queryString = require('query-string');

export default {
  name: "PromoCategoryGrid",
  mounted() {
    this.getPromoCategoriesList()
  },
  components: {
    AgreeDialog,
    PromoControlDialog,
  },
  data() {
    return {
      promoCategories: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'alias',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Parent',
          sortable: true,
          value: 'parent_id',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '25%'
        },
      ]
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CREATE_PROMO_CATEGORY_BY_ADMIN, async () =>
        this.getPromoCategoriesList()
    );
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PROMO_CATEGORY_BY_ADMIN, async () =>
        this.getPromoCategoriesList()
    );
    eventBus.$on(eventBusEvents.EVENT_FILTER_PROMO_CATEGORY, async search => {
      this.queryParams = search
      this.getPromoCategoriesList()
    });
  },
  methods: {
    getPromoCategoriesList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promo-categories?${q}`)
          .then(response => this.promoCategories = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    edit(promoCategory) {
      console.log('step1...............')
      eventBus.$emit(eventBusEvents.EVENT_EDIT_PROMO_CATEGORY_BY_ADMIN, promoCategory)
    },
    remove(promoCategory) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/promo-categories/${promoCategory.id}`)
          .then(response => {
            console.log('promoCategory removed', response.data)
            this.getPromoCategoriesList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    changeProfile(promoCategory) {
      eventBus.$emit(eventBusEvents.EVENT_EDIT_PROFILE_BY_ADMIN, promoCategory.id)
    }
  }
}
</script>

<style scoped>

</style>
