<template>
  <v-card>
    <!--<v-card-title>
        Презентабельность
    </v-card-title>-->

    <v-card-text>
      <v-btn @click="change()" :class="isUfoLocal ? 'green' : 'grey'">
        <v-icon>
          mdi-ufo
        </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";

export default {
  name: "UfoForm",
  props: ['profile', 'is_ufo'],
  data() {
    return {
      isUfoLocal: null
    }
  },
  mounted() {
    this.isUfoLocal = this.is_ufo
  },
  methods: {
    change() {
      let url = `/admin/profiles/${this.profile.id}/ufo`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        _method: 'put'
      })
          .then(() => {
            this.isUfoLocal = !this.isUfoLocal
            eventBus.$emit(eventBusEvents.EVENT_CHANGE_PRESENTABLE, this.isUfoLocal);
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
