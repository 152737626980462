<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
    >
      <v-text-field
          v-model="settingLocal.value"
          :rules="rules.valueRules"
          label="Value"
          type="number"
          @keyup.enter="handleSubmit"
          onfocus="this.removeAttribute('readonly')"
          required
          readonly
      />

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="handleSubmit"> {{ btnText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";

require('dotenv').config()

export default {
  name: "NumericSettingForm",
  props: ['action', 'setting'],
  data() {
    return {
      valid: true,

      actionLocal: null,

      settingLocal: {
        name: "",
        value: "",
      },
      rules: {
        valueRules: [
          v => !!v || 'Value must be valid',
          // v => v.length > 20 || 'Value length must be more than 20 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
    }
  },
  created() {
    this.actionLocal = this.action

    if (this.setting) {
      this.settingLocal = this.setting
      this.edit(this.setting)
    }

    eventBus.$on(eventBusEvents.EVENT_EDIT_NUMERIC_SETTING_BY_ADMIN, async setting => {
      console.log('EDIT_NUMERIC_SETTING_BY_ADMIN', setting)
      this.edit(setting)
    });
    eventBus.$on(eventBusEvents.EVENT_REFRESH_NUMERIC_SETTING_BY_ADMIN, async () => {
      this.refresh()
    });
  },
  mounted() {
    // if (this.actionLocal === this.crudActions.update) {
    //     this.settingLocal = this.setting
    // }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.settingLocal.id = data.id
      this.settingLocal.name = data.name
      this.settingLocal.value = data.value
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.settingLocal.id = null
      this.settingLocal.name = null
      this.settingLocal.value = null
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    update() {
      console.log('setting_form_update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.settingLocal,
        ...{_method: 'put'}
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/settings/numeric/${this.settingLocal.id}`,
          data
      ).then(() => {
        eventBus.$emit(eventBusEvents.EVENT_REFRESH_NUMERIC_SETTING_BY_ADMIN)
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.settingLocal.value) {
        alert('value is empty')
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
