<template>
  <CityBornForm/>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import CityBornForm from "../../components/Profile/Forms/CityBornForm";

export default {
  name: "RegisterCityBorn",
  components: {
    CityBornForm
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CHANGE_CITY_BORN, async sex => {
      console.log('sex', sex)
      this.next()
    });
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_REFRESH_USER',

    ]),
    next() {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/profile/increment-register-step`, {
        _method: 'put'
      })
          .then((resp) => {
            this.EXEC_INCREMENT_REGISTER_STEP()
            this.EXEC_REFRESH_USER(resp.data.data.user)
          }).catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
