<template>
  <v-card v-if="profileLocal">
    <v-card-title>
      Комментарии модератора
    </v-card-title>

    <v-card-text>
      <template v-if="profileLocal.comment">
        ```
        {{ profileLocal.comment }}
        ```
      </template>
      <br>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="sendComment"
      >
        <v-text-field
            v-model="comment.body"
            :rules="rules.bodyRules"
            label="Коммент"
            type="text"
            @keyup.enter="sendComment"
            onfocus="this.removeAttribute('readonly')"
            required
            readonly
        />

        <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="sendComment"> Отправить
        </v-btn>
      </v-form>

      <v-divider/>

      <!--<br/>

      <v-btn
          v-if="showBadSelfieBtn"
          color="red"
          class="mr-4"
          @click="badSelfieSend()"> Плохое селфи
      </v-btn>

      <v-divider/>

      <br/>

      <v-btn
          v-if="showBadPhotosBtn"
          color="orange"
          class="mr-4"
          @click="badPhotosSend()"> Плохие фото
      </v-btn>-->

      <v-divider/>

      <br/>

      <div>
        <v-row>
          <!--<v-col> todo deprecated
            <v-checkbox
                v-model="remarks"
                label="Приветствие"
                color="green"
                value="greeting"
                hide-details
            />
          </v-col>-->
          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Плохие фото"
                color="red"
                value="bad_photos"
                hide-details
            />
          </v-col>
<!--          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Плохое селфи"
                color="red darken-3"
                value="bad_selfie"
                hide-details
            />
          </v-col>-->
          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Заполни анкету"
                color="orange darken-3"
                value="fill_out_form"
                hide-details
            />
          </v-col>
          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Не разумный возраст"
                color="blue darken-2"
                value="not_reasonable_age"
                hide-details
            />
          </v-col>
          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Активация"
                color="yellow darken-2"
                value="stop_searching_later_warning"
                hide-details
            />
          </v-col>

          <v-col>
            <v-checkbox
                v-model="remarks"
                label="Твой шанс"
                color="blue darken-2"
                value="bonus_meeting"
                hide-details
            />
          </v-col>
        </v-row>

        <v-row  class="mt-8">
          <v-col>
            <v-btn @click="testing()">
              Тестовый пуш
            </v-btn>
          </v-col>
          <v-col>
            <div class="float-right">
              <v-btn v-if="showRemarkBtn" @click="sendRemarks()">
                Отправить
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {mapActions} from "vuex";

export default {
  name: "ProfileComments",
  props: ['profile'],
  mounted() {
    this.profileLocal = this.profile
  },
  data() {
    return {
      profileLocal: null,
      valid: true,

      showBadSelfieBtn: true,
      showBadPhotosBtn: true,

      // checkboxes
      remarks: [],
      showRemarkBtn: true,

      showTestingBtn: true,

      comment: {
        body: "",
      },
      rules: {
        bodyRules: [
          v => !!v || 'Body must be valid',
          v => !!v && v.length > 3 || 'Body length must be more than 3 characters',
        ],
      }
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    testing() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.showTestingBtn = false

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/testing-push`).then(resp => {
        console.log('Sending test push complete. ', resp.data)
        this.remarks = []
        this.showTestingBtn = true
      })
    },
    sendRemarks() {
      this.EXEC_PROCESSING_REQUEST(true)

      if (this.loading) return

      this.showRemarkBtn = false

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/send-remarks`,
          {remarks: this.remarks}
      ).then(resp => {
        console.log('Sending remarks complete. ', resp.data)
        this.remarks = []
        this.showRemarkBtn = true
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    // todo deprecated
    badSelfieSend() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/send-bad-examination-message`,
          this.comment
      ).then(resp => {
        console.log('BadSelfieSend complete. ', resp.data)
        this.showBadSelfieBtn = false
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    badPhotosSend() {
      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/send-bad-photos-message`,
          this.comment
      ).then(resp => {
        console.log('BadPhotosSend complete. ', resp.data)
        this.showBadPhotosBtn = false
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    sendComment(e) {
      e.preventDefault()

      console.log('send')

      if (this.loading) return

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/comments`,
          this.comment
      ).then(resp => {
        console.log('New comment: ', resp.data)
        this.profileLocal.comment = this.comment.body
        this.comment.body = ''

        // todo пока без извращений
        // eventBus.$emit(eventBusEvents.EVENT_CREATE_PROMO_BY_ADMIN)
        // this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.comment.body) {
        alert('body is empty')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
