<template>
  <v-card>
    <v-card-title>
      PromoUsagesGrid

      <v-btn class="ml-4" color="orange" @click="getPromoUsageList()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="promos"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          :items-per-page="itemsPerPage"
          class="elevation-1"
          item-key="id"
          show-select
      >
        <template v-slot:item.profile="{ item }">
          <ProfileControlDialog :profile_id="item.profile_id" @close="close()"/>
          {{ item.profile.first_name }}
        </template>

        <template v-slot:item.promo="{ item }">
          {{ item.promo.name }} <!--todo rename for code-->
        </template>

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions} from "@/enums/enums";
import DefaultStatus from "../DefaultStatus";
import ProfileControlDialog from "../Profile/ProfileControlDialog";
import {mapGetters} from "vuex";

const queryString = require('query-string');

export default {
  name: "PromoUsageGrid",
  props: ['url'],
  mounted() {
    this.getPromoUsageList()
  },
  components: {
    DefaultStatus,
    ProfileControlDialog
  },
  data() {
    return {
      promos: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      itemsPerPage: 100,
      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Profile',
          align: 'start',
          sortable: true,
          value: 'profile',
        },
        {
          text: 'Promo',
          align: 'start',
          sortable: true,
          value: 'promo',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
    }),
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PROMO_BY_ADMIN, async () => {
      this.getPromoUsageList()
    });
    eventBus.$on(eventBusEvents.EVENT_CREATE_PROMO_BY_ADMIN, async () => {
      this.getPromoUsageList()
    });
    eventBus.$on(eventBusEvents.EVENT_FILTER_PROMO, async search => {
      this.queryParams = search
      this.getPromoUsageList()
    });
  },
  methods: {
    getPromoUsageList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}${this.url}?${q}`)
          .then(response => this.promos = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
