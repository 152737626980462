<template>
  <div>
    <v-row>
      <v-col cols="11">
        <PairGrid
            :url="'/admin/pairs'"/>
      </v-col>

      <v-col cols="1">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <v-navigation-drawer
        v-model="drawer"
        :clipped="false"
        right
        fixed
        dark
        app
        disable-route-watcher
        enable-resize-watcher
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <PairFilter class="mb-4"/>

      <v-divider/>

    </v-navigation-drawer>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import PairGrid from "../../../components/Pair/PairGrid"
import PairFilter from "../../../components/Pair/PairFilter"

export default {
  name: "AdminPairsPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    PairGrid,
    PairFilter,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
