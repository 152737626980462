<template>
  <div v-if="profileLocal">
    <v-container>
      <h3 v-if="profileLocal.is_seed">
        Seed
      </h3>

      <h2 v-if="!!profileLocal.id">
        {{ profileLocal.user_id }}_{{ profileLocal.id }}
        -
        {{ profileLocal.first_name }} - {{ profileLocal.age }},
        <span class="grey lighten-2">
            {{ showEmail() }}
        </span>
        -
        {{ getCityName(profileLocal) }}

        <StatusSearch :status="profileLocal.search_status"/>
      </h2>

      <h3 class="mt-3">
        {{ showPhone() }}
        <v-btn icon :href="getWhatsappLink(profileLocal.phone)" target="_blank" color="green">
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>

        &nbsp;
        &nbsp;
        &nbsp;

        <v-btn
            :href="getTelegramLink(profileLocal.telegram_username)"
            v-if="profileLocal.telegram_user_id && profileLocal.telegram_username"
            icon
            target="_blank"
            color="green"
            style="cursor: pointer;"
        >
          <v-chip color="blue">
            tg
          </v-chip>
        </v-btn>
      </h3>

      <h3>
        <v-chip class="mt-2 mb-2 lighten-4" color="secondary">
          {{ getRegisteredAt() }}
        </v-chip>
      </h3>

      <h3>
        <OsTypeShop :os_type="getOs()" v-if="profileLocal.devices.length > 0"/>
      </h3>

      <h3 class="mt-4">
        Ищет {{ `${profileLocal.search_age_from} - ${profileLocal.search_age_to}` }} лет
      </h3>

      <h4 class="mt-4">
        Likes: {{ profileLocal.likes }}
      </h4>

      <v-row>
        <v-col>
          <v-card class="mt-4 grey lighten-5">
            <v-card-actions @click="showPairs = !showPairs">
              История
              <v-spacer/>

              <v-btn icon>
                <v-icon>
                  {{ showPairs ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showPairs">
                <v-divider/>

                <v-card-text>
                  <PairGrid :url="`/admin/profiles/${profileLocal.id}/pairs`"
                            :allow_pair_filters="false"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <!--<v-row>
            <v-col>
              <v-card class="mt-4 cian lighten-2">
                <v-card-actions @click="showSupport = !showSupport">
                  Поддержка
                  <v-spacer/>

                  <v-btn icon>
                    <v-icon>{{ showSupport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="showSupport">
                    <v-divider/>

                    <v-card-text>
                      <MessageList/>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>-->

      <v-row>
        <v-col>
          <!--todo add promos usage and tariff usage-->
          <v-card class="mt-4 green lighten-5">
            <v-card-actions @click="showTariffUsage = !showTariffUsage">
              Купленные тарифы
              <v-spacer/>

              <v-btn icon>
                <v-icon>{{ showTariffUsage ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showTariffUsage">
                <v-divider/>

                <v-card-text>
                  <TariffUsageGrid :url="`/admin/profiles/${profileLocal.id}/tariff-usages`"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <!--      <v-row>
              <v-col>
                &lt;!&ndash;todo add promos usage and tariff usage&ndash;&gt;
                <v-card class="mt-4 yellow lighten-5">
                  <v-card-actions @click="showPromoUsage = !showPromoUsage">
                    Использованные промокоды
                    <v-spacer/>

                    <v-btn icon>
                      <v-icon>{{ showPromoUsage ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="showPromoUsage">
                      <v-divider/>

                      <v-card-text>
                        <PromoUsageGrid :url="`/admin/profiles/${profileLocal.id}/promo-usages`"/>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>
            </v-row>-->

      <v-row>
        <v-col>
          <v-card class="mt-4 grey lighten-5">
            <v-card-actions @click="showMeetings= !showMeetings">
              Купленные свидания (На балансе: {{ profileLocal.balance }})
              <v-spacer/>

              <v-btn icon>
                <v-icon>
                  {{ showMeetings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showMeetings">
                <v-divider/>

                <v-card-text>
                  <MeetingGrid :url="`/admin/profiles/${profileLocal.id}/meetings`"
                               :profile_id="profileLocal.id"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="profileLocal">
        <v-col>
          <!--<PresentableForm :profile="profileLocal" :is_presentable="profileLocal.is_presentable"/>-->

          <v-row class="my-4">
            <v-col>
              <UfoForm :profile="profileLocal" :is_ufo="profileLocal.is_ufo"/>
            </v-col>

            <v-col>
              <ViewedByModeratorForm :profile="profileLocal"
                                     :is_viewed_by_moderator="profileLocal.is_viewed_by_moderator"/>
            </v-col>

            <v-col>
              <VipForm :profile="profileLocal" :is_vip="profileLocal.is_vip"/>
            </v-col>

            <v-col>
              <CasteForm :profile="profileLocal" :caste="profileLocal.caste"/>
            </v-col>
          </v-row>

          <br/>

          <v-card class="mt-4 blue lighten-5">
            <v-card-actions @click="showSearchAdminInfo = !showSearchAdminInfo">
              Подборы глазами админа
              <v-spacer/>

              <v-btn icon>
                <v-icon>{{ showSearchAdminInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showSearchAdminInfo">
                <v-divider/>

                <v-card-text>
                  <ProfileMatchingInfo :profile="profileLocal" :mode="'for-admin'"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mt-4 blue lighten-5">
            <v-card-actions @click="showSearchInfo = !showSearchInfo">
              Подборы глазами юзера
              <v-spacer/>

              <v-btn icon>
                <v-icon>{{ showSearchInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showSearchInfo">
                <v-divider/>

                <v-card-text>
                  <ProfileMatchingInfo :profile="profileLocal" :mode="'for-customer'"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mt-4 brown lighten-5">
            <v-card-actions @click="showTechInfo = !showTechInfo">
              Техническая информация
              <v-spacer/>

              <v-btn icon>
                <v-icon>{{ showTechInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="showTechInfo">
                <v-divider/>

                <v-card-text>
                  <ProfileTechInfo :profile="profileLocal"/>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>

          <UserDetailsPanel :user_id="profileLocal.user_id"/>

          <hr>

          <v-card>
            <v-card-title>
              Редактирование учетной записи
            </v-card-title>

            <v-card-text>
              <UserControlDialog
                  :user_id="profileLocal.user_id"
                  :profile="profileLocal"
                  :action="crudActions.update"/>
            </v-card-text>
          </v-card>

          <v-card class="my-2">
            <v-card-title>
              Статус поиска
            </v-card-title>

            <v-card-text>
              <!--mv to component-->
              <template v-if="profileLocal.search_status === 1">
                Поиск запущен
                <v-btn @click="stopSearch()" color="red">
                  Остановить
                </v-btn>
              </template>
              <template v-if="profileLocal.search_status === 3">
                Поиск остановлен
                <v-btn @click="activateSearch()" color="green">
                  Запустить
                </v-btn>
              </template>
            </v-card-text>
          </v-card>

          <AboutForm :profile="profileLocal"
                     :about="profileLocal.about"
                     :about_draft="profileLocal.about_draft"
          />

          <!-- todo Проверка биометрии - продублировать в клиентскую часть-->
          <PhotoForm
              v-for="examination in profileLocal.examinations"
              :key="examination.id"
              :photos="examination.photos ? examination.photos : []"
              :url="`/admin/profiles/${profileLocal.id}/examinations/${examination.id}/photos`"
              :title="'Проверка'"
              :min="3"
              :max="5"
          >
            <v-img width="200" :src="getCover(examination)"/>
          </PhotoForm>

          <PhotoForm
              :photos="profileLocal.photos ? profileLocal.photos : []"
              :url="`/admin/profiles/${profileLocal.id}/photos`"
              :min="3"
              :max="5"
          />

          <ProfileComments class="mt-4" :profile="profileLocal"/>

          <!-- todo пока отказались от Whatsapp-а-->
          <!--<MessageList :chat_type="whatsappTypes.main"/>-->

          <!-- todo Дискусионный момент. Документы просто смотрит админ. Впоследствии отказываемся от них.
          <DocumentPanel
                  :title="'Selfie with passport page'"
                  :type="documentTypes.selfieWithCover"
                  :document="selfieWithCoverDocument"
                  class="mt-4"
                  :url="`/admin/users/${this.userLocal.id}/profile/documents`"
          />
          <DocumentPanel
                  :title="'Family status page'"
                  :type="documentTypes.familyStatus"
                  :document="familyStatusDocument"
                  class="mt-4"
                  :url="`/admin/users/${this.userLocal.id}/profile/documents`"
          />-->
        </v-col>

        <v-col>
          <template v-if="profileLocal">

            <div>
              <v-progress-linear color="blue" height="30" :value="profileLocal.filling_profile"
                                 striped>
                <template>
                  <strong>Профиль заполнен на {{ profileLocal.filling_profile }}%</strong>
                </template>
              </v-progress-linear>
            </div>

            <div class="mt-4">
              <v-progress-linear color="orange" height="30" :value="profileLocal.register_progress"
                                 striped>
                <template>
                  <strong>Процесс регистрации -
                    {{ profileLocal.register_step.name }}
                    {{ profileLocal.register_progress }}%
                  </strong>
                </template>
              </v-progress-linear>
            </div>

            <v-card class="mt-4 red lighten-5">
              <v-card-actions @click="showMainInfo = !showMainInfo">
                Основная информация
                <v-spacer/>

                <v-btn icon>
                  <v-icon>{{ showMainInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showMainInfo">
                  <v-divider/>

                  <v-card-text>
                    <BirthtimeForm
                        :profile="profileLocal"
                        :birthtime="profileLocal.birthtime"
                    />
                    <BirthdayForm
                        :profile="profileLocal"
                        :birthday="profileLocal.birthday"
                    />
                    <HeightForm
                        :profile="profileLocal"
                        :height="profileLocal.height"
                    />
                    <SearchAgeForm
                        :profile="profileLocal"
                        :ageRange="[profileLocal.search_age_from, profileLocal.search_age_to]"
                    />
                    <SearchHeightForm
                        :profile="profileLocal"
                        :heightRange="[profileLocal.search_height_from, profileLocal.search_height_to]"
                    />
                    <SexForm
                        :profile="profileLocal"
                        :sex="profileLocal.sex"
                    />
                    <PreferConnectionsForm
                        :profile="profileLocal"
                        :connections="{
                                                is_phone_prefer: profileLocal.is_phone_prefer,
                                                is_whatsapp_prefer: profileLocal.is_whatsapp_prefer,
                                                is_telegram_prefer: profileLocal.is_telegram_prefer,
                                                is_viber_prefer: profileLocal.is_viber_prefer,
                                                is_email_prefer: profileLocal.is_email_prefer,
                                            }"
                    />
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card class="mt-4 green lighten-5">
              <v-card-actions @click="showAttributes = !showAttributes">
                Параметры профиля
                <v-spacer/>

                <v-btn icon>
                  <v-icon>
                    {{ showAttributes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showAttributes">
                  <v-divider/>

                  <v-card-text>
                    <DictFloatPanel
                        v-for="dict in dictFloatList"
                        :key="dict.title"
                        :title="dict.title"
                        :dictMethod="`/dicts/${dict.dictMethod}`"
                        :changeMethod="`/admin/profiles/${profileLocal.id}/${dict.changeMethod}`"
                        :postArgument="dict.postArgument"
                        :value="getDictValue(dict.postArgument)"
                        class="mt-4"
                    />
                    <DictMultiplePanel
                        v-for="dict in dictMultipleList"
                        :key="dict.title"
                        :title="dict.title"
                        :dictMethod="`/dicts/${dict.dictMethod}`"
                        :changeMethod="`/admin/profiles/${profileLocal.id}/${dict.changeMethod}`"
                        :postArgument="dict.postArgument"
                        :values="getDictValue(dict.postArgument)"
                        class="mt-4"
                    />
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card class="mt-4 orange lighten-5">
              <v-card-actions @click="showNotificationsSearchInfo = !showNotificationsSearchInfo">
                Уведомления
                <v-spacer/>

                <v-btn icon>
                  <v-icon>{{ showNotificationsSearchInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showNotificationsSearchInfo">
                  <v-divider/>

                  <v-card-text>
                    <ProfileNotifications :profile="profileLocal"/>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card class="mt-4 violet lighten-5">
              <v-card-actions @click="showStoppers = !showStoppers">
                Стоперы
                <v-spacer/>

                <v-btn icon>
                  <v-icon>{{ showStoppers ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showStoppers">
                  <v-divider/>

                  <v-card-text>
                    <ProfileStoppers :profile="profileLocal"/>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card class="mt-4 red lighten-5" v-if="allowForStartSearch()">
              <v-card-actions @click="showStartSearch = !showStartSearch">
                Запустить новый поиск
                <v-spacer/>

                <v-btn icon>
                  <v-icon>{{ showStartSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showStartSearch">
                  <v-divider/>

                  <v-card-text>
                    <template>
                      <StartNewSearch :profile="profileLocal"/>
                    </template>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card class="mt-4 green lighten-5">
              <v-card-actions @click="showOffers = !showOffers">
                Предложения (как видит в истории)
                <v-spacer/>

                <v-btn icon>
                  <v-icon>
                    {{ showOffers ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showOffers">
                  <v-divider/>

                  <v-card-text>
                    <ProfileOffers :profile="profileLocal"/>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </template>

          <!-- todo пока отказались от Whatsapp-а-->
          <!-- <MessageList :chat_type="whatsappTypes.spam" class="mt-6"/>-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  crudActions,
  dictFloatList,
  dictMultipleList,
  documentTypes,
  profileSearchStatusesList,
  userStatusesEnum,
  userStatusesList
} from "@/enums/enums";
import DictFloatPanel from "../Dicts/Change/DictFloatChangePanel";
// import DictFloatPanel from "../Dicts/Filter/DictFloatFilterPanel";
// import MessageList from "../Whatsapp/Message/List";
import UserDetailsPanel from "../User/UserDetailsPanel";
import DictMultiplePanel from "../Dicts/Change/DictMultipleChangePanel";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import SexForm from "./Forms/SexForm";
import AboutForm from "./Forms/AboutForm";
import PhotoForm from "./Forms/PhotoForm";
import HeightForm from "./Forms/HeightForm";
import BirthdayForm from "./Forms/BirthdayForm";
import BirthtimeForm from "./Forms/BirthtimeForm";
import SearchAgeForm from "./Forms/SearchAgeForm";
import ProfileComments from "../User/ProfileModerationComments";
import MeetingGrid from "../Meeting/MeetingGrid";
import ProfileTechInfo from "./ProfileTechInfo";
import SearchHeightForm from "./Forms/SearchHeightForm";
import PreferConnectionsForm from "./Forms/PreferConnectionsForm";
import ProfileMatchingInfo from "./ProfileMatchingInfo";
import CasteForm from "./Forms/CasteForm";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus"
import {getWhatsappLinkByPhone} from "@/utils/helpers";
import UserControlDialog from "../User/UserControlDialog";
import ProfileNotifications from "./ProfileNotifications";
import {convertUtcToDate} from "@/utils/converters";
import OsTypeShop from "../TariffUsage/OsTypeShop";
import ProfileStoppers from "./ProfileStoppers";
import ProfileOffers from "./ProfileOffers";
import UfoForm from "@/components/Profile/Forms/UfoForm";
import StartNewSearch from "./StartNewSearch";
import StatusSearch from "./StatusSearch";
import VipForm from "@/components/Profile/Forms/VipForm";
import {whatsappTypes} from "@/enums/enums";
import ViewedByModeratorForm from "./Forms/ViewedByModeratorForm";

require('dotenv').config()

export default {
  name: 'ProfilePage',
  props: ['profile_id'],
  components: {
    StatusSearch,
    UfoForm,
    UserControlDialog,
    PairGrid: () => import('../Pair/PairGrid.vue'),
    TariffUsageGrid: () => import('../TariffUsage/TariffUsageGrid'),
    // PromoUsageGrid: () => import('../PromoUsage/PromoUsageGrid'),
    SexForm,
    VipForm,
    AboutForm,
    PhotoForm,
    CasteForm,
    HeightForm,
    OsTypeShop,
    // MessageList,
    MeetingGrid,
    BirthdayForm,
    ProfileOffers,
    BirthtimeForm,
    SearchAgeForm,
    DictFloatPanel,
    StartNewSearch,
    ProfileComments,
    ProfileTechInfo,
    ProfileStoppers,
    UserDetailsPanel,
    SearchHeightForm,
    DictMultiplePanel,
    ProfileMatchingInfo,
    ProfileNotifications,
    ViewedByModeratorForm,
    PreferConnectionsForm,
  },
  data() {
    return {
      profileLocal: null,
      showAttributes: true,
      showSearchInfo: false,
      showSearchAdminInfo: false,
      showTechInfo: false,
      showMainInfo: false,
      showPairs: false,
      showSupport: false,
      showPromoUsage: false,
      showTariffUsage: false,
      showMeetings: false,
      showNotificationsSearchInfo: false,
      showStoppers: false,
      showOffers: false,
      showStartSearch: false,
    }
  },
  mounted() {
    this.getProfileById(this.profile_id)
  },
  created() {
    // eventBus.$on(eventBusEvents.EVENT_EDIT_PROFILE_BY_ADMIN, async userId =>
    //     this.getUserById(userId)
    // );

    // todo tmp
    eventBus.$on(eventBusEvents.EVENT_REFRESH_MEETINGS_BY_ADMIN, async () =>
        this.getProfileById(this.profile_id)
    );
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    dictFloatList: function () {
      return dictFloatList
    },
    userStatusesEnum: function () {
      return userStatusesEnum
    },
    dictMultipleList: function () {
      return dictMultipleList
    },
    documentTypes: () => {
      return documentTypes;
    },
    selfieWithCoverDocument: function () {
      return this?.profileLocal?.documents
          ?.filter(it => it.d_type === documentTypes.selfieWithCover)[0] ?? null
    },
    familyStatusDocument: function () {
      return this?.profileLocal?.documents
          ?.filter(it => it.d_type === documentTypes.familyStatus)[0] ?? null
    },
    whatsappTypes: function () {
      return whatsappTypes
    }
  },
  methods: {
    initMessages() {
      console.log('change initMessages')

      let chatId = this.profileLocal.phone
              .replaceAll(' ', '')
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll('+', '')
              .replaceAll('-', '')
          + `@c.us`

      console.log('initMessages', chatId, this.profileLocal)

      eventBus.$emit(eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_MAIN, chatId)
      eventBus.$emit(eventBusEvents.EVENT_REFRESH_WHATSAPP_MESSAGES_IN_CHAT_SPAM, chatId)
    },
    allowForStartSearch() {
      return this.profileLocal.search_status === profileSearchStatusesList.status_ready_for_search
    },
    getOs() {
      return this.profileLocal.devices.length > 0 ? this.profileLocal.devices[0].os : null
    },
    getRegisteredAt() {
      return convertUtcToDate(this.profileLocal.created_at)
    },
    isDeleted() {
      return this.profileLocal.user.status === userStatusesList.deleted
    },
    isAllowed() {
      return !this.isDeleted()
    },
    showPhone() {
      return this.isAllowed() ? this.profileLocal.user.phone : this.profileLocal.user.phone_trash
    },
    showEmail() {
      return this.isAllowed() ? this.profileLocal.user.email : this.profileLocal.user.email_trash
    },
    close() {
      this.profileLocal = null;
    },
    getProfileById(profileId) {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${profileId}`)
          .then(resp => {
            this.profileLocal = resp.data.data.profile
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => {
            // todo пока отказались от Whatsapp-а
            // this.initMessages()
          })
    },
    // todo deprecated
    // isConsumer() {
    //     return this.profileLocal.role === rolesList.consumer
    // },
    getDictValue(argName) {
      return this.profileLocal[argName]
    },
    // todo move to service
    getCover(examination) {
      let photos = examination?.biometric?.photos || []
      if (!photos.length) return null
      let elem = photos[Math.floor(Math.random() * photos.length)]
      return elem.image
    },
    getWhatsappLink() {
      return getWhatsappLinkByPhone(this.showPhone())
    },
    getTelegramLink(telegram_username) {
      return `https://t.me/${telegram_username}`
    },
    // todo doublicate
    getCityName(profile) {
      return !profile.city_live ? profile?.missing_cities?.map(it => it.name)?.join(',') : profile.city_live.name
    },

    // mv to component
    activateSearch() {
      this.$http.put(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/activate-search-status`)
          .then(resp => {
            this.profileLocal.search_status = profileSearchStatusesList.status_ready_for_search
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => {
            // todo пока отказались от Whatsapp-а
            // this.initMessages()
          })
    },
    stopSearch() {
      this.$http.put(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/stop-search-status`)
          .then(resp => {
            this.profileLocal.search_status = profileSearchStatusesList.status_frozen
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => {
            // todo пока отказались от Whatsapp-а
            // this.initMessages()
          })
    },
  }
}
</script>

<style scoped>

</style>
