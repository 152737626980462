<template>
  <v-container class="col-md-6 offset-md-3">
    <h2 class="mb-4">
      Register Details
    </h2>

    <!--todo-->

    <RegisterExaminationPhoto
        :examinations="user.profile.examinations"
    />

    <v-progress-linear
        color="light-blue"
        height="20"
        :value="user.profile.register_progress"
        striped
    >
      <template>
        <strong>{{ user.profile.register_progress }}%</strong>
      </template>
    </v-progress-linear>

    <template v-if="isSmsVerification()">
      <SmsVerifier/>
    </template>

    <template v-if="isVideoAgree()">
      <RegisterAgreeVideo/>
    </template>

    <!--todo-->
    <template v-if="isExamination()">
      <RegisterExaminationPhoto
          :examinations="user.profile.examinations"
      />
    </template>

    <template v-if="isSex()">
      <RegisterSex/>
    </template>

    <template v-if="isBirthday()">
      <RegisterBirthday/>
    </template>

    <template v-if="isHeight()">
      <RegisterHeight/>
    </template>

    <template v-if="isBirthtime()">
      <RegisterBirthtime/>
    </template>

    <template v-if="isCityBorn()">
      <RegisterCityBorn/>
    </template>

    <!--user.profile.photos: {{ user.profile.photos }}-->
    <!--user: {{ user }}-->

    <template v-if="isPhoto()">
      <RegisterPhoto
          :photos="user.profile.photos"
      />
    </template>

    <template v-if="isSearchAge()">
      <RegisterSearchAge/>
    </template>

    <template v-if="isSearchHeight()">
      <RegisterSearchHeight/>
    </template>


    <template v-if="isDocumentSelfie()">
      <RegisterDocumentUpload
          :title="'Selfie with passport page'"
          :type="documentTypes.selfieWithCover"
          :document="selfieWithCoverDocument"
      />
    </template>

    <template v-if="isDocumentFamilyPage()">
      <RegisterDocumentUpload
          :title="'Family status page'"
          :type="documentTypes.familyStatus"
          :document="familyStatusDocument"
      />

    </template>

    <!--<template v-if="isPreferConnections()">
        <RegisterPreferConnections/>
    </template>-->

    <template v-if="isLastStep()">
      <RegisterComplete/>
    </template>
  </v-container>
</template>

<script>
import RegisterSex from "./RegisterSex";
import RegisterComplete from "./RegisterComplete";
import RegisterBirthday from "./RegisterBirthday";
import RegisterBirthtime from "./RegisterBirthtime";
import RegisterHeight from "./RegisterHeight";
import RegisterCityBorn from "./RegisterCityBorn";
import RegisterPhoto from "./RegisterPhoto";
import RegisterSearchAge from "./RegisterSearchAge";
import RegisterSearchHeight from "./RegisterSearchHeight";
import RegisterAgreeVideo from "./RegisterAgreeVideo";
import RegisterDocumentUpload from "./RegisterDocumentUpload";
import RegisterExaminationPhoto from "./RegisterExaminationPhoto";
import SmsVerifier from "../../components/SmsVerifier";
import {documentTypes, registerSteps} from "@/enums/enums";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapGetters} from "vuex";

require('dotenv').config()

export default {
  name: "RegisterDetails",
  components: {
    RegisterSex,
    SmsVerifier,
    RegisterPhoto,
    RegisterHeight,
    RegisterCityBorn,
    RegisterBirthday,
    RegisterComplete,
    RegisterBirthtime,
    RegisterSearchAge,
    RegisterAgreeVideo,
    RegisterExaminationPhoto,
    RegisterSearchHeight,
    RegisterDocumentUpload
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_SMS_VERIFIED, () => {
      //...
    });
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
    }),
    documentTypes: () => {
      return documentTypes;
    },
    selfieWithCoverDocument: function () {
      return this?.user?.profile?.documents?.filter(it => it.d_type === documentTypes.selfieWithCover)[0] ?? null
    },
    familyStatusDocument: function () {
      return this?.user?.profile?.documents?.filter(it => it.d_type === documentTypes.familyStatus)[0] ?? null
    }
  },
  mounted() {
    //...
  },
  methods: {
    // todo изменить модель шагов регистрации
    isExamination() {
      return false
    },
    isSmsVerification() {
      return this.user.profile.register_step === registerSteps.smsVerification
    },
    isVideoAgree() {
      return this.user.profile.register_step === registerSteps.videoAgree
    },
    isSex() {
      return this.user.profile.register_step === registerSteps.sex
    },
    isBirthday() {
      return this.user.profile.register_step === registerSteps.birthday
    },
    isHeight() {
      return this.user.profile.register_step === registerSteps.height
    },
    isBirthtime() {
      return this.user.profile.register_step === registerSteps.birthtime
    },
    isCityBorn() {
      return this.user.profile.register_step === registerSteps.cityBorn
    },
    isPhoto() {
      return this.user.profile.register_step === registerSteps.photo
    },
    isSearchAge() {
      return this.user.profile.register_step === registerSteps.searchAge
    },
    isSearchHeight() {
      return this.user.profile.register_step === registerSteps.searchHeight
    },
    isDocumentSelfie() {
      return this.user.profile.register_step === registerSteps.documentSelfie
    },
    isDocumentFamilyPage() {
      return this.user.profile.register_step === registerSteps.documentFamilyPage
    },
    isLastStep() {
      return this.user.profile.register_step === registerSteps.lastStep
    }
  }
}
</script>

<style scoped>

</style>
