<template>
  <v-chip :color="getColor()">
    {{ getName() }} ({{ status }})
  </v-chip>
</template>

<script>
import {pairStatusesList} from "@/enums/enums";

export default {
  name: "PairStatusChip",
  props: ['status'],
  methods: {
    getName() {
      if (this.status === pairStatusesList.created) {
        return 'Создана'
      }
      if (this.status === pairStatusesList.matched) {
        return 'Совпала'
      }
      if (this.status === pairStatusesList.mismatch) {
        return 'Не совпала'
      }
      if (this.status === pairStatusesList.declined) {
        return 'Разорвана'
      }
      if (this.status === pairStatusesList.resumed) {
        return 'Поиск возобновлен'
      } else return 'Unknown'
    },
    getColor() {
      if (this.status === pairStatusesList.created) {
        return 'secondary'
      }
      if (this.status === pairStatusesList.matched) {
        return 'green'
      }
      if (this.status === pairStatusesList.mismatch) {
        return 'primary'
      }
      if (this.status === pairStatusesList.declined) {
        return 'red'
      }
      if (this.status === pairStatusesList.resumed) {
        return 'orange'
      } else return 'Unknown'
    },
  }
}
</script>

<style scoped>

</style>
