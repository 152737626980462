<template>
  <p>
    Main
  </p>
</template>

<script>
export default {
  name: "MatchmakerHome"
}
</script>

<style scoped>

</style>
