<template>
  <v-row>
    <v-col cols=11>
      <!--todo-->
      <UniversalDashboard
          :title="'Возраст'"
          :url="'/admin/profiles?age_from=${from}&age_to=${to}'"
          :list="this.ages"
          v-if="this.ages.length"
      />
    </v-col>

    <v-col cols=1>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-navigation-drawer v-model="drawer"
                           :clipped="false"
                           right fixed dark app disable-route-watcher enable-resize-watcher
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"/>

        <AgeDashboardFilter class="mb-4"/>

        <v-divider/>

      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {objectToQueryString} from "@/utils/helpers";
import AgeDashboardFilter from "./AgeDashboardFilter";
import UniversalDashboard from "../UniversalDashboard";

const queryString = require('query-string');

export default {
  name: "AgeDashboard",
  components: {
    AgeDashboardFilter,
    UniversalDashboard
  },
  data() {
    return {
      drawer: false,

      ages: [],
      queryParams: {},
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_FILTER_DASHBOARD, async search => {
      console.log('EVENT_FILTER_DASHBOARD', search)
      this.queryParams = search
      this.getAges()
    });
  },
  mounted() {
    this.queryParams = this.$route.query
    this.getAges()
  },
  methods: {
    getAges() {
      let q = objectToQueryString(this.queryParams)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/statistics/ages?${q}`)
          .then(response => this.ages = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
  }
}
</script>

<style scoped>

</style>
