<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Rating
              </th>
              <th class="text-left">
              </th>
            </tr>
            </thead>

            <tbody>
            <tr
                v-for="profile in profiles" :key="profile.id">
              <td>
                <router-link :to="getUrl(profile)" target="_blank">
                  {{ profile.id }}
                </router-link>
              </td>
              <td>
                <router-link :to="getUrl(profile)" target="_blank">
                  {{ profile.name }}
                </router-link>
              </td>
              <td>
                <v-chip color="green">
                  {{ profile.rating }}
                </v-chip>
              </td>
              <td>
                <v-btn color="primary" :to="getUrl(profile)" target="_blank">
                  Перейти
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "TemporaryProfilesPage",
  data() {
    return {
      profiles: [],
      keyHash: null,
    }
  },
  mounted() {
    this.keyHash = this.$route.params.keyHash
    this.getList()
  },
  methods: {
    getUrl(item) {
      return `/vip/${this.keyHash}/profiles/${item.id}`
    },
    getList() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/vip/${this.keyHash}/profiles`)
          .then(res => {
            this.profiles = res.data.data.items
          })
    },
  }
}
</script>

<style scoped>

</style>
