import RegisterDetailsPage from "../pages/RegisterDetails/RegisterDetailsPage";
import HomePage from "../pages/HomePage";
import AdminTariffsPage from "../pages/Admin/AdminTariffsPage";
import AdminPromoCategoriesPage from "../pages/Admin/AdminPromoCategoriesPage";
import ModeratorPage from "../pages/Moderator/ModeratorPage";
import ModeratorHome from "../pages/Moderator/ModeratorHome";
import MatchmakerPage from "../pages/Matchmaker/MatchmakerPage";
import MatchmakerHome from "../pages/Matchmaker/MatchmakerHome";
import AdminRegisterStepsPage from "../pages/Admin/AdminRegisterStepsPage";
import AdminBiometricsPage from "../pages/Admin/AdminBiometricsPage";
import AdminBooleanSettingsPage from "../pages/Admin/AdminBooleanSettingsPage";
import AdminNumericSettingsPage from "../pages/Admin/AdminNumericSettingsPage";
import AdminFaqPage from "../pages/Admin/AdminFaqPage";
import AdminPromoUsagesPage from "../pages/Admin/AdminPromoUsagesPage";
import AdminTariffUsagesPage from "../pages/Admin/AdminTariffUsagesPage";
import LeadsPage from "../pages/Admin/AdminLeadsPage";
import AdminDailyMatchesPage from "../pages/Admin/AdminDailyMatchesPage";
import AdminMeetingsPage from "../pages/Admin/AdminMeetingsPage";
import AdminMissingCitiesPage from "../pages/Admin/AdminMissingCitiesPage";
import AdminDashboardSimplePage from "../pages/Admin/AdminDashboardSimplePage";
import AdminProfileDetailPage from "../pages/Admin/Accounts/Profile/AdminProfileDetailPage";
import AdminPairsPage from "../pages/Admin/Pair/AdminPairsPage";
import AdminPairDetailPage from "../pages/Admin/Pair/AdminPairDetailPage";
import AdminStoppersPage from "../pages/Admin/AdminStoppersPage";
import AdminChallengersPage from "@/pages/Admin/Accounts/Profile/AdminChallengersPage";
import AdminUfoPage from "@/pages/Admin/Accounts/Profile/AdminUfoPage";
import AdminUsersArchivePage from "@/pages/Admin/AdminUsersArchivePage";
import ModeratorChallengersPage from "@/pages/Moderator/Account/Profile/ModeratorChallengersPage";
import ModeratorProfilesPage from "@/pages/Moderator/Account/Profile/ModeratorProfilesPage";
import ModeratorProfilesOnModerationPage from "@/pages/Moderator/Account/Profile/ProfilesOnModerationPage";
import ModeratorUfoPage from "@/pages/Moderator/Account/Profile/ModeratorUfoPage";
import AdminProfilesPage from "@/pages/Admin/Accounts/Profile/AdminProfilesPage";
import AdminProfilesOnModerationPage from "@/pages/Admin/Accounts/Profile/ProfilesOnModerationPage";
import EditorSheetsPage from "@/pages/Editor/EditorSheetsPage";
import EditorPage from "@/pages/Editor/EditorPage";
import EditorSheetDetailPage from "@/pages/Editor/EditorSheetDetailPage";
import EditorHome from "@/pages/Editor/EditorHome";
import WhatsappPage from "@/pages/Admin/Support/WhatsappPage";
import VipProfilesDetailPage from "../pages/Vip/Presentable/DetailPage";
import MatchmakerProfilesPage from "@/pages/Matchmaker/Account/Profile/MatchmakerProfilesPage";
import AdminAcquiringsPage from "../pages/Admin/AdminAcquiringsPage";
import VipPersonCandidatesPage from "@/pages/TemporaryGuest/Profile/VipPersonCandidatesPage.vue";
import VipPersonCandidateDetailPage from "@/pages/TemporaryGuest/Profile/VipPersonCandidateDetailPage.vue";

const LoginPage = require('../pages/LoginPage').default;
const AdminPage = require('../pages/Admin/AdminPage').default;
// const AdminProfilesPage = require('../pages/Admin/Accounts/AdminProfilesPage').default;

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            guest: true
        }
    },
    /*{ todo на случай web версии
        path: '/register',
        name: 'Register',
        component: RegisterPage,
        meta: {
            guest: true
        }
    },*/
    {
        path: '/register-details',
        name: 'RegisterDetailsPage',
        component: RegisterDetailsPage,
        meta: {
            guest: false
        }
    },
    /*{ todo на случай web версии
        path: '/profile',
        name: 'Profile',
        component: ProfileOnRegisterPage,
        meta: {
            guest: false,
            need_details_register: true
        }
    },*/
    /*{ todo на случай web версии
        path: '/pairs',
        name: 'Pairs',
        component: PairsPage,
        meta: {
            guest: false,
            need_details_register: true
        }
    },*/

    // todo tmp vip display
    {
        path: '/vip/profiles/:password',
        name: 'VipProfilesDetailPage',
        component: VipProfilesDetailPage,
        meta: {
            guest: false
        }
    },
    // Для дамы

    {
        path: '/vip/:keyHash',
        component: VipPersonCandidatesPage,
    },
    {
        path: '/vip/:keyHash/profiles/:profileId',
        component: VipPersonCandidateDetailPage,
    },
    {
        path: '/admin',
        component: AdminPage,
        meta: {
            requiresAuth: true,
            isAdmin: true
        },
        children: [
            {
                path: '',
                // component: AdminHome,
                component: AdminDashboardSimplePage,
                // component: AdminDashboardPage,
            },
            {
                path: 'dashboard',
                component: AdminDashboardSimplePage,
                // component: AdminDashboardPage,
            },
            {
                path: 'support/whatsapp',
                component: WhatsappPage,
            },
            {
                path: 'profiles',
                component: AdminProfilesPage,
                // children: [
                //     {
                //         path: '/:profileId',
                //         component: AdminProfileDetailPage,
                //     }
                // ],
            },
            {
                path: 'profiles-on-moderation',
                component: AdminProfilesOnModerationPage
                ,
            },
            {
                path: 'profiles/:profileId',
                component: AdminProfileDetailPage,
            },
            {
                path: 'pairs',
                component: AdminPairsPage,
                // children: [
                //     {
                //         path: '/:pairId',
                //         component: AdminPairDetailPage,
                //     }
                // ],
            },
            {
                path: 'pairs/:pairId',
                component: AdminPairDetailPage,
            },
            {
                path: 'challengers',
                component: AdminChallengersPage,
            },
            {
                path: 'ufo',
                component: AdminUfoPage,
            },
            {
                path: 'stoppers',
                component: AdminStoppersPage,
            },
            {
                path: 'users-archive',
                component: AdminUsersArchivePage,
            },
            {
                path: 'daily-matches',
                component: AdminDailyMatchesPage,
            },
            {
                path: 'meetings',
                component: AdminMeetingsPage,
            },
            {
                path: 'missing-cities',
                component: AdminMissingCitiesPage,
            },
            {
                path: 'tariffs',
                component: AdminTariffsPage,
            },
            {
                path: 'promo-categories',
                component: AdminPromoCategoriesPage,
            },
            {
                path: 'register-steps',
                component: AdminRegisterStepsPage,
            },
            {
                path: 'biometrics',
                component: AdminBiometricsPage,
            },
            {
                path: 'settings/numeric',
                component: AdminNumericSettingsPage,
            },
            {
                path: 'settings/boolean',
                component: AdminBooleanSettingsPage,
            },
            {
                path: 'faq',
                component: AdminFaqPage,
            },
            {
                path: 'promo-usages',
                component: AdminPromoUsagesPage,
            },
            {
                path: 'tariff-usages',
                component: AdminTariffUsagesPage,
            },
            {
                path: 'acquirings',
                component: AdminAcquiringsPage,
            },
            {
                path: 'leads',
                component: LeadsPage,
            },
            {
                path: 'sheets',
                component: EditorSheetsPage,
            },
            {
                path: 'sheets/:sheetId',
                component: EditorSheetDetailPage,
            },
        ]
    },
    {
        path: '/editor',
        component: EditorPage,
        meta: {
            requiresAuth: true,
            isEditor: true
        },
        children: [
            {
                path: '',
                component: EditorHome,
            },
            {
                path: 'sheets',
                component: EditorSheetsPage,
            },
            {
                path: 'sheets/:sheetId',
                component: EditorSheetDetailPage,
            },
        ]
    },
    {
        path: '/moderator',
        component: ModeratorPage,
        meta: {
            requiresAuth: true,
            isModerator: true
        },
        children: [
            {
                path: '',
                component: ModeratorHome,
            },
            {
                path: 'profiles',
                component: ModeratorProfilesPage,
                // children: [
                //     {
                //         path: ':profileId',
                //         component: AdminProfileDetailPage,
                //     }
                // ]
            },
            {
                path: 'profiles-on-moderation',
                component: ModeratorProfilesOnModerationPage,
            },
            {
                path: 'profiles/:profileId',
                component: AdminProfileDetailPage,
            },
            {
                path: 'sheets',
                component: EditorSheetsPage,
            },
            {
                path: 'sheets/:sheetId',
                component: EditorSheetDetailPage,
            },
            {
                path: 'pairs',
                component: AdminPairsPage,
                // children: [
                //     {
                //         path: '/:pairId',
                //         component: AdminPairDetailPage,
                //     }
                // ],
            },
            {
                path: 'pairs/:pairId',
                component: AdminPairDetailPage,
            },
            {
                path: 'challengers',
                component: ModeratorChallengersPage,
            },
            {
                path: 'ufo',
                component: ModeratorUfoPage,
            },
            {
                path: 'stoppers',
                component: AdminStoppersPage,
            },
            {
                path: 'support/whatsapp',
                component: WhatsappPage,
            },
        ]
    },

    {
        path: '/matchmaker',
        component: MatchmakerPage,
        meta: {
            requiresAuth: true,
            isMatchmaker: true
        },
        children: [
            {
                path: '',
                component: MatchmakerHome,
            },
            {
                path: 'profiles',
                component: MatchmakerProfilesPage,
                // children: [
                //     {
                //         path: ':profileId',
                //         component: AdminProfileDetailPage,
                //     }
                // ]
            },
            {
                path: 'profiles/:profileId',
                component: AdminProfileDetailPage,
            }
        ]
    },
]
