<template>
  <div v-if="pair">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>id</td>
          <td>{{ pair.id }}</td>
        </tr>
        <tr>
          <td>Статус</td>
          <td>
            {{ pair.status }} -
            {{ getPairStatusName(pair.status) }}
          </td>
        </tr>
        <tr>
          <td>rating</td>
          <td>{{ pair.rating }}</td>
        </tr>
        <tr>
          <td>Время предложения</td>
          <td>{{ pair.offered_at }}</td>
        </tr>
        <tr>
          <td>Время матчинга</td>
          <td>{{ pair.matched_at }}</td>
        </tr>
        <tr>
          <td>Была Сматчена?</td>
          <td>{{ pair.is_matched }}</td>
        </tr>
        <tr>
          <td>Разорвана?</td>
          <td>{{ pair.is_declined }}</td>
        </tr>
        <tr>
          <td>Кто разорвал</td>
          <td>{{ pair.decliner_id }} {{ pair.decliner }}</td>
        </tr>
        <tr>
          <td>Предположительное время авторазрыва</td>
          <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ pair.positive_declined_at }}</td>
        </tr>
        <tr>
          <td>Время разрыва</td>
          <td>{{ pair.declined_at }}</td>
        </tr>
        <tr>
          <td>Восстановлена?</td>
          <td>{{ pair.is_resumed }}</td>
        </tr>
        <tr>
          <td>whatsapp chat</td>
          <td>{{ pair.whatsapp_chat }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {pairStatusesEnum} from "@/enums/enums";

export default {
  name: "PairTechInfo",
  props: ['pair'],
  methods: {
    getPairStatusName(status) {
      return pairStatusesEnum.find(it => it.id === status).title
    }
  }
}
</script>

<style scoped>

</style>
