<template>
  <v-card>
    <v-card-title>
      Фильтр
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <header class="pt-6 font-weight-bold">
            Диапазон:
          </header>
        </v-col>

        <v-col>
          <FilterDatepicker :fieldName="'started_at'" :label="'От'" @update="updateStartedAt"/>
        </v-col>

        <v-col>
          <FilterDatepicker :fieldName="'finished_at'" :label="'До'" @update="updateFinishedAt"/>
        </v-col>
      </v-row>

      <v-row>
        <aside class="pt-5 font-weight-bold">
          <v-btn @click="resetRange()" color="warning" x-small dark>
            Сбросить
          </v-btn>
        </aside>
      </v-row>

      <v-row>
        <v-col>
          <v-select v-model="search.dashboard.sex"
                    :items="sexList" label="Пол" item-text="title"
                    item-value="id"/>
        </v-col>

        <v-col justify="center" x-small>
          <aside class="pt-5 font-weight-bold">
            <v-btn @click="search.dashboard.sex = null" color="warning" x-small dark>
              Сбросить
            </v-btn>
          </aside>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {sexEnum} from "@/enums/enums"
import FilterDatepicker from "../../../../components/FilterDatePicker"

const _ = require('lodash')

export default {
  name: "UserStatusesDashboardFilter",
  components: {
    FilterDatepicker
  },
  data() {
    return {
      search: {
        dashboard: {
          sex: null,
          started_at: null,
          finished_at: null
        }
      }
    }
  },
  computed: {
    sexList: function () {
      return sexEnum
    }
  },
  methods: {
    resetRange() {
      this.search.dashboard.started_at = null
      this.search.dashboard.finished_at = null
    },
    updateStartedAt(val) {
      this.search.dashboard.started_at = val
    },
    updateFinishedAt(val) {
      this.search.dashboard.finished_at = val
    },
    commitFilter(data) {
      let modifyDashBoard = {}

      Object.keys(data.dashboard)
          .filter(key => data.dashboard[key] !== null && data.dashboard[key] !== '')
          .forEach(key => modifyDashBoard[`${key}`] = data.dashboard[key])

      let result = {...modifyDashBoard}
      // result.profile_age_from = data.profile.age_range[0]
      // result.profile_age_to = data.profile.age_range[1]

      eventBus.$emit(eventBusEvents.EVENT_FILTER_USER_BY_STATUS_FOO, result)
    }
  },
  watch: {
    search: {
      handler: _.debounce(function (v) {
        this.commitFilter(v)
      }, 1000),
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
