<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PromoGrid :promoCategory="promoCategory"/>
      </v-col>

      <v-col cols="4">
        <PromoPanel :action="crudActions.create" :promoCategory="promoCategory"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import PromoGrid from "../../components/Promo/PromoGrid";
import PromoPanel from "../../components/Promo/PromoPanel";

export default {
  name: 'AdminTariffsPage',
  props: [
    'promoCategory'
  ],
  components: {
    PromoGrid,
    PromoPanel,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
