<template>
  <v-card>
    <v-card-title>
      Пары: {{ quantity }}
      <v-spacer/>

      <v-btn color="orange" @click="refresh()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-spacer/>

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details/>
    </v-card-title>

    <v-card-text>
      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()" v-if="allowNext()">
        Далее
      </v-btn>

      <v-divider/>

      <v-data-table
          v-model="selected"
          item-key="id"
          class="elevation-1"
          :single-select="singleSelect"
          :headers="headers"
          :items="pairs"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['id']"
          :sort-desc="[true]"
          :items-per-page="itemsPerPage"
          :item-class="itemRowBackground"
          show-select
      >
        <template v-slot:item.status="{ item }">
          <PairStatusChip :status="item.status"/>
        </template>

        <!--<template v-slot:item.offered_at="{ item }">
            {{ convertUtcToDate(item) }}
        </template>-->
        <template v-slot:item.created_at="{ item }">
          {{ convertUtcToDate(item) }}
        </template>

        <template v-slot:item.man="{ item }">
          <PairGridPersonCell :person="item.man"/>
        </template>

        <template v-slot:item.woman="{ item }">
          <PairGridPersonCell :person="item.woman"/>
        </template>

        <template v-slot:item.actions="{ item }">
          <PairControlDialog :pair_id="item.id" :action="crudActions.update"/>

          <!--<AgreeDialog
                  @remove="remove(item)"
                  class="ml-2"
          />-->
        </template>
      </v-data-table>

      <v-divider/>

      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()">
        Далее
      </v-btn>

      {{ queryParams }}
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import PairStatusChip from "./PairStatusChip";
import {crudActions} from "@/enums/enums";
import PairControlDialog from "./PairControlDialog";
import {mapActions, mapGetters} from "vuex";
import {convertUtcToDate} from "@/utils/converters";
import {objectToQueryString} from "@/utils/helpers";
import PairGridPersonCell from "@/components/Pair/PairGridPersonCell";

export default {
  name: "PairGrid",
  props: {
    url: String,
    allow_pair_filters: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    // console.log('this_route_query', this.$route.query)
    // console.log('mounted_allow_pair_filters', this.allow_pair_filters)
    // console.log('url', this.url)

    this.queryParams = {...this.queryParams, ...this.$route.query}
    this.getPairsList()
  },
  components: {
    // AgreeDialog,
    PairStatusChip,
    PairControlDialog,
    PairGridPersonCell,
  },
  data() {
    return {
      pairs: [],
      quantity: 0,

      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      itemsPerPage: 100,
      queryParams: {
        limit: 100,
        offset: 0
      },

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Предложена.',
          sortable: true,
          value: 'created_at',
          // value: 'offered_at',
        },
        {
          text: 'Man',
          align: 'start',
          sortable: true,
          value: 'man',
        },
        {
          text: 'Woman',
          align: 'start',
          sortable: true,
          value: 'woman',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Rating',
          sortable: true,
          value: 'rating',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
    }),
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_PAIR_BY_ADMIN, async () =>
        this.getPairsList()
    );
    eventBus.$on(eventBusEvents.EVENT_FILTER_PAIR, async search => {
      this.queryParams = search
      let q = objectToQueryString(search)
      history.pushState(null, null, `pairs?${q}`)
      this.getPairsList()
    });
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    allowNext() {
      return this.pairs.length > 0
    },
    refresh() {
      this.queryParams = {
        limit: 100,
        offset: 0
      }

      this.getPairsList()
    },
    convertUtcToDate(pair) {
      return convertUtcToDate(
          pair.created_at, pair?.man?.profile?.city?.timezone
      )
    },
    itemRowBackground(item) {
      return `${item.highlight}`
    },
    close() {
      this.getPairsList()
    },
    next() {
      this.queryParams.offset += 100
      this.getPairsList()

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    prev() {
      if (!this.showPrev()) {
        alert('Невозможно уменьшить')
      }

      this.queryParams.offset -= 100
      this.getPairsList()
      window.scroll(0, 0)
    },
    showPrev() {
      return !!this.queryParams.offset
    },
    getPairsList() {
      let q = objectToQueryString(this.queryParams)

      this.EXEC_PROCESSING_REQUEST(true)

      // console.log(`allow_pair_filters: ${this.allow_pair_filters}`)

      let requestUrl = this.allow_pair_filters ?
          `${process.env.VUE_APP_SERVER_BASE_URL}${this.url}?${q}` :
          `${process.env.VUE_APP_SERVER_BASE_URL}${this.url}`

      this.$http.get(requestUrl)
          .then(response => {
            this.pairs = response.data.data.items
            this.quantity = response.data.data.quantity
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    /*remove(pair) {
        this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/pairs/${pair.id}`)
            .then(response => {
                console.log('pair removed', response.data)
                this.getPairsList()
            })
            .catch(err => alert(converValidationServerErrorsToString(err)));
    },*/
  }
}
</script>

<style scoped>

</style>
