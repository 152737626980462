<template>
  <div>
    <v-row>
      <v-col cols="8">
        <RegisterStepGrid/>
      </v-col>

      <v-col cols="4">
        <RegisterStepPanel :action="crudActions.create"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import RegisterStepGrid from "../../components/RegisterStep/RegisterStepGrid";
import RegisterStepPanel from "../../components/RegisterStep/RegisterStepPanel";

export default {
  name: "AdminRegisterSteps",
  components: {
    RegisterStepGrid,
    RegisterStepPanel,
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
  },
}
</script>

<style scoped>

</style>
