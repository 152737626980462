var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Покупки - ("+_vm._s(_vm.getByRealUserCount())+") / "+_vm._s(_vm.acquirings.length)+" "),(!_vm.processingRequest)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"orange"},on:{"click":function($event){return _vm.getAcquiringList()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"single-select":_vm.singleSelect,"headers":_vm.headers,"items":_vm.acquirings,"expanded":_vm.expanded,"search":_vm.search,"sort-by":['id'],"sort-desc":[true],"item-key":"id","items-per-page":_vm.itemsPerPage,"item-class":_vm.itemRowBackground,"show-select":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertUtcToDate(item))+" ")]}},{key:"item.os",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.os)+" ")]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.id)+" ")]}},{key:"item.profile_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile_id)+" ")]}},{key:"item.profile",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ProfileControlDialog',{attrs:{"profile_id":item.profile_id},on:{"close":function($event){return _vm.close()}}}),_vm._v(" "+_vm._s(item.profile.first_name)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getTooltipInfo(item.profile))+" ")])])]}},{key:"item.publisher",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.publisher)+" ")]}},{key:"item.product_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff.product_id)+" ")]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.age)+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.city.name)+" ")]}},{key:"item.os_type",fn:function(ref){
var item = ref.item;
return [_c('OsTypeShop',{attrs:{"os_type":item.os_type}})]}},{key:"item.tariff_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff ? item.tariff.name : null)+" ")]}},{key:"item.tariff_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tariff ? item.tariff.price : null)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('DefaultStatus',{attrs:{"status":item.status}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }