<template>
  <v-card>
    <v-card-title>
      {{ mode }} - {{ counters.total }}

      <!--{{ getRealUserCount() }} / {{ total }}-->

      <v-spacer/>

      <v-btn @click="resetFilters()">
        Сброс фильтров
        <!--<v-icon>
            mdi-refresh-
        </v-icon>-->
      </v-btn>

      <template v-if="isModeUsers()">
        <v-spacer/>

        <div @click="filterByUserStatus(userStatusesList.on_register)">
          <UserStatus :status="userStatusesList.on_register" style="cursor: pointer;">
            {{ counters.on_register }}
          </UserStatus>
        </div>

        <v-spacer/>

        <!--        <div @click="filterByUserStatus(userStatusesList.on_moderation)">
                  <UserStatus :status="userStatusesList.on_moderation" style="cursor: pointer;">
                    {{ counters.on_moderation }}
                  </UserStatus>
                </div>-->

        <div @click="filterByViewedModerator(userStatusesList.on_moderation)">
          <v-chip color="grey">
            Не просмотрено {{ counters.no_viewed_by_moderator }}
          </v-chip>
        </div>

        <v-spacer/>

        <div @click="filterByUserStatus(userStatusesList.on_re_moderation)">
          <UserStatus :status="userStatusesList.on_re_moderation" style="cursor: pointer;">
            {{ counters.on_re_moderation }}
          </UserStatus>
        </div>
      </template>

      <v-spacer/>

      <v-btn color="orange" @click="getProfilesList()" v-if="!processingRequest">
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      <UserControlDialog :action="crudActions.create" v-if="isModeUsers()"/>

      <v-spacer/>

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details/>

      <!--<v-spacer/>
      <v-btn @click="sortByElo()">
        Elo
        <v-icon right dark >
          mdi-arrow-split-horizontal
        </v-icon>
      </v-btn>-->

      <!--<v-spacer/>

      <v-btn @click="sortByLikes()" class="pink darken">
        Likes
        <v-icon>
          mdi-arrow-split-horizontal
        </v-icon>
      </v-btn>-->

    </v-card-title>

    <v-card-text>
      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()" v-if="allowNext()">
        Далее
      </v-btn>

      <v-divider/>

      <v-data-table
          v-model="selected"
          item-key="id"
          class="elevation-1"
          :single-select="singleSelect"
          :headers="headers"
          :items="profiles"
          :expanded.sync="expanded"
          :search="search"
          :items-per-page="itemsPerPage"
          :item-class="itemRowBackground"
          show-select
      >
        <!--
        :sort-by="['id']"
        :sort-desc="[true]"
        -->
        <template v-slot:item.id="{ item }">
          {{ item.user.id }}_{{ item.id }}
          <!--<v-icon v-if="item.profile.is_presentable">
            mdi-star
          </v-icon>-->

          <v-icon v-if="item.is_ufo">
            mdi-ufo
          </v-icon>
        </template>

        <template v-slot:item.os="{ item }">
          {{ item.os }}
        </template>

        <template v-slot:item.created_at="{ item }">
          <template v-if="isViewedByModerator(item)">
            {{ convertUtcToDate(item) }}
          </template>
          <template v-else>
            <v-icon>
              mdi-eye-off
            </v-icon>
            &nbsp;
            &nbsp;
            <ProfileRouteLink :url="`profiles/${item.id}`"/>
          </template>
        </template>

        <template v-slot:item.city_live_name="{ item }">
          {{ item.city_live_name }}
        </template>

        <!--<template v-slot:item.role="{ item }">
            <UserRole :role="item.role"/>
        </template>-->

        <template v-slot:item.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                  {{ item.name }}

                  <v-chip v-if="item.telegram_user_id" color="blue">
                    tg
                  </v-chip>
              </span>
            </template>

            <span>
               {{ getTooltipInfo(item) }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:item.status="{ item }">
          <UserStatus :status="item.user.status"/>
        </template>

        <template v-slot:item.search_status="{ item }">
          <div align="center">
            <StatusSearch :status="item.search_status"/>
          </div>

          <!--{{ getProfileSearchStatusName(item.search_status) }}-->
        </template>

        <!--        <template v-slot:item.phone="{ item }">
                  {{ getPhone(item.user.phone) }}
                </template>-->

        <!--<template v-slot:item.warning="{ item }">
          {{ item.profile.complaints_for_candidate.length }} / {{ item.profile.blockings_for_candidate.length }}
        </template>-->

        <template v-slot:item.balance="{ item }">
          {{ item.balance }}
        </template>

        <template v-slot:item.offers_count="{ item }">
          {{ item.offers_count }}
        </template>

        <template v-slot:item.filling_profile="{ item }">
          {{ item.filling_profile }}
        </template>

        <template v-slot:item.caste="{ item }">
          {{ item.caste }}
        </template>

        <!--<template v-slot:item.rating_elo="{ item }">
            {{ item.profile.rating_elo }}
        </template>-->

        <template v-slot:item.rating_likes="{ item }">
          {{ item.rating_likes }}
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- <ProfileControlDialog :profile_id="item.profile.id" @close="close()"/>-->

          <!--<a :href="`profiles/${item.profile.id}`" target="_blank" class="v-button">
              <v-icon aria-hidden="false">
                  mdi-page-next
              </v-icon>
          </a>-->

          <ProfileRouteLink :url="`profiles/${item.id}`"/>

          <UserControlDialog
              :user_id="item.user.id"
              :profile="item"
              :action="crudActions.update"
              v-if="allowForUpdate()"
          />

          <AgreeDialog @remove="remove(item.user.id)" class="ml-2" v-if="allowForDelete(item.user.status)"/>

          <span v-if="!!item.is_seed">
              Seed
          </span>

          <template>
            <v-icon v-if="item.sex === 1">
              mdi-gender-male
            </v-icon>
            <v-icon v-else-if="item.sex === 2">
              mdi-gender-female
            </v-icon>
            <v-icon v-else>
              mdi-sim-alert-outline
            </v-icon>
          </template>
        </template>
      </v-data-table>

      <v-divider/>

      <v-btn green @click="prev()" v-if="showPrev()">
        Назад
      </v-btn>
      <v-btn green @click="next()">
        Далее
      </v-btn>

      {{ queryParams }}
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import AgreeDialog from "../AgreeDialog";
import {crudActions, profileSearchStatusesEnum, sexList, userModesList, userStatusesList} from "@/enums/enums";
import {mapActions, mapGetters} from "vuex";
import {convertUtcToDate} from "@/utils/converters";
import ProfileRouteLink from "../Profile/ProfileRouteLink";
import {objectToQueryString} from "@/utils/helpers";
import UserStatus from "@/components/User/UserStatus";
import UserControlDialog from "@/components/User/UserControlDialog";
import StatusSearch from "./StatusSearch";

export default {
  name: 'ProfileGrid',
  props: ['mode'],
  components: {
    UserStatus,
    AgreeDialog,
    StatusSearch,
    ProfileRouteLink,
    UserControlDialog,
    // ProfileControlDialog,
    // UserRole,
  },
  data() {
    return {
      profiles: [],
      // total: null,
      counters: {
        total: null,
        on_moderation: null,
        on_re_moderation: null,
        on_register: null,
      },

      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',
      itemsPerPage: 100,

      queryParams: {
        limit: 100,
        offset: 0
      },

      eloDirection: 'desc',
      likesDirection: 'desc'
    }
  },
  mounted() {
    this.queryParams = {...this.queryParams, ...this.$route.query}
    this.getProfilesList()
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_USER_BY_ADMIN, async () => {
      this.getProfilesList()
    });
    eventBus.$on(eventBusEvents.EVENT_FILTER_PROFILE, async ({search, mode}) => {
      this.queryParams = search
      let q = objectToQueryString(search)
      history.pushState(null, null, `${mode}?${q}`)
      this.getProfilesList()
    });
  },
  computed: {
    ...mapGetters({
      processingRequest: 'GET_PROCESSING_REQUEST',
      user: 'GET_USER',
    }),
    crudActions: function () {
      return crudActions
    },
    userStatusesList: function () {
      return userStatusesList
    },
    userModesList: function () {
      return userModesList
    },
    headers: function () {
      let headers = [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'OS',
          align: 'start',
          sortable: true,
          value: 'os',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Регистрация',
          align: 'start',
          sortable: true,
          value: 'created_at',
          modes: [
            this.userModesList.profiles,
            // this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Город',
          align: 'start',
          sortable: true,
          value: 'city_live_name',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        /*{
            text: 'Role',
            align: 'start',
            sortable: true,
            value: 'role',
        },*/
        {
          text: 'Статус Учетки',
          align: 'start',
          sortable: true,
          value: 'status',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Статус поиска',
          align: 'start',
          sortable: true,
          value: 'search_status',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        /*{
          text: 'Phone',
          align: 'start',
          sortable: true,
          value: 'phone',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },*/
        {
          text: 'Age',
          sortable: true,
          value: 'age',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]

        },
        {
          text: 'Куплено свиданий',
          sortable: true,
          value: 'balance',
          modes: [
            this.userModesList.profiles,
            // this.userModesList.challengers,
            // this.userModesList.ufo,
          ]
        },
        {
          text: 'Likes',
          sortable: true,
          value: 'rating_likes',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Предложнений',
          sortable: true,
          value: 'offers_count',
          modes: [
            // this.userModesList.users,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Группа',
          sortable: true,
          value: 'caste',
          modes: [
            // this.userModesList.users,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
        {
          text: 'Регистрация',
          sortable: true,
          value: 'filling_profile',
          modes: [
            this.userModesList.profiles,
            // this.userModesList.challengers,
            // this.userModesList.ufo,
          ]
        },
        /*{
            text: 'Жалобы/Блокировки',
            sortable: true,
            value: 'warning',
          },
          {
            text: 'Elo',
            sortable: true,
            value: 'rating_elo',
            // align: 'start',
          },
          */
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%',
          modes: [
            this.userModesList.profiles,
            this.userModesList.challengers,
            this.userModesList.ufo,
          ]
        },
      ]

      // alert(123)

      return headers.filter(header => !!header.modes.find(it => it === this.mode))
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    getTooltipInfo(profile) {
      return `${profile.user_id}_${profile.id}. ${profile.user.phone}`
    },

    resetFilters() {
      this.queryParams = {
        limit: 100,
        offset: 0
      }
      eventBus.$emit(eventBusEvents.EVENT_FILTER_USER_RESET)
      this.getProfilesList()
    },

    // refresh() {
    //   this.queryParams = {
    //     limit: 100,
    //     offset: 0
    //   }
    //   this.getPairsList()
    // },

    next() {
      this.queryParams.offset += 100
      this.getProfilesList()

      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    allowNext() {
      return this.profiles.length > 0
    },
    prev() {
      if (!this.showPrev()) {
        alert('Невозможно уменьшить')
      }

      this.queryParams.offset -= 100
      this.getProfilesList()
      window.scroll(0, 0)
    },
    showPrev() {
      return !!this.queryParams.offset
    },

    isModeUsers() {
      return this.mode === this.userModesList.profiles
    },
    isModeChallengers() {
      return this.mode === this.userModesList.challengers
    },
    isModeUfo() {
      return this.mode === this.userModesList.ufo
    },
    getPhone(phone) {
      return phone.includes('+7') ? phone : ''
    },

    filterByUserStatus(status) {
      eventBus.$emit(eventBusEvents.EVENT_FILTER_USER_BY_STATUS, status)
    },

    filterByViewedModerator() {
      eventBus.$emit(eventBusEvents.EVENT_FILTER_USER_BY_VIEWED_MODERATOR)
    },
    // todo duplicate
    allowForDelete(userStatus) {
      return userStatus !== userStatusesList.deleted && this.isModeUsers()
    },
    isUserStatusDeleted(userStatus) {
      return userStatus === userStatusesList.deleted
    },
    isUserStatusOnRegister(userStatus) {
      return userStatus === userStatusesList.on_register
    },
    allowForUpdate() {
      return this.isModeUsers()
    },
    // todo duplicate
    remove(userId) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${userId}`)
          .then(response => {
            console.log('user removed', response.data)
            this.getProfilesList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    // todo move to util
    convertUtcToDate(profile) {
      return convertUtcToDate(profile.created_at, profile.city?.timezone)
    },

    itemRowBackground(item) {
      // todo
      // if(!!item.profile.age && (item.profile.age > 70)) {
      if (!!item.age && (item.age > 70)) {
        return 'yellow lighten-2'
      }

      return item.sex === sexList.man ? 'cyan lighten-5' : 'red lighten-5'
    },
    getProfileSearchStatusName(searchStatus) {
      return profileSearchStatusesEnum.find(it => it.id === searchStatus).title
    },
    sortByElo() {
      // todo refactor with profiles list
      this.queryParams = {
        'profile_sort-by': 'rating_elo',
        'profile_sort-direction': this.eloDirection,
      }

      this.getProfilesList()

      this.eloDirection = this.eloDirection === 'desc' ? 'asc' : 'desc'
    },
    sortByLikes() {
      // todo refactor with profiles list
      this.queryParams = {
        'profile_sort-by': 'rating_likes',
        'profile_sort-direction': this.likesDirection,
      }

      this.getProfilesList()

      this.likesDirection = this.likesDirection === 'desc' ? 'asc' : 'desc'
    },
    getProfilesList() {
      // let mode = this.isModeUfo() ? 'ufo' : (this.isModeChallengers() ? 'challengers' : null)
      // if (mode) {
      //   this.queryParams.mode = mode
      // }

      let q = objectToQueryString(this.queryParams)

      console.log('qqqqq: ', {q})

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/${this.mode}?${q}`)
          .then(response => {
            this.profiles = response.data.data.items
            // this.total = response.data.data.total
            this.counters = response.data.data.counters
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    getRealUserCount() {
      // todo вложенность без профиля
      return this.profiles.filter(this.getRealConditionByAge()).length
    },
    getRealOnRegisterCount() {
      return this.profiles.filter(this.getRealConditionByAge())
          .filter(it => it.status === userStatusesList.on_register).length
    },
    getRealOnModerationCount() {
      return this.profiles.filter(this.getRealConditionByAge())
          .filter(it => it.status === userStatusesList.on_moderation).length
    },
    getRealOnReModerationCount() {
      return this.profiles.filter(this.getRealConditionByAge())
          .filter(it => it.status === userStatusesList.on_re_moderation).length
    },
    getRealConditionByAge() {
      return it => it.age < 70
    },
    close() {
      this.getProfilesList();
    },
    isViewedByModerator(profile) {
      return profile.is_viewed_by_moderator || this.isUserStatusDeleted(profile.user.status) || this.isUserStatusOnRegister(profile.user.status)
    }
    // edit(user) {
    //     eventBus.$emit(eventBusEvents.EVENT_EDIT_USER_BY_ADMIN, user)
    // },
    // changeProfile(user) {
    //     eventBus.$emit(eventBusEvents.EVENT_EDIT_PROFILE_BY_ADMIN, user.id)
    // }
  }
}
</script>

<style scoped>

</style>
