<template>
  <v-card>
    <v-card-title>
      Время рождения ({{ birthtimeLocal }})
    </v-card-title>

    <v-card-text>
      <template v-if="isKnowTime">
        <!--<v-time-picker
                v-model="birthtimeLocal"
                format="24hr"
        />-->

        <v-text-field type="time" v-model="birthtimeLocal"/>

        <v-btn @click="isKnowTime = false" elevation="2" depressed color="red">
          Don`t know
        </v-btn>
      </template>

      <template v-else>
        <v-list dense>
          <v-subheader>REPORTS</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
                v-for="time in timeArray"
                @change="changeTimeFromList(time.value)"
                :key="time.value">
              <v-list-item-content>
                <v-list-item-title v-text="time.label"/>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-card-text>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" @click="change" text>
        Change
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {mapActions} from "vuex";

export default {
  name: 'BirthdayForm',
  props: ['birthtime', 'profile'],
  data() {
    return {
      birthtimeLocal: null,
      isKnowTime: true,
      timeArray: [
        {
          label: 'Ночь',
          value: '02:00'
        },
        {
          label: 'Утро',
          value: '08:00'
        },
        {
          label: 'День',
          value: '14:00'
        },
        {
          label: 'Вечер',
          value: '20:00'
        },
        {
          label: 'Не знаю',
          value: '12:00'
        },
      ],
    }
  },
  mounted() {
    this.birthtimeLocal = this.birthtime
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP'
    ]),
    changeTimeFromList(timeValue) {
      console.log('BirthdayForm_timeValue____________', timeValue)
      this.birthtimeLocal = timeValue
      this.change();
    },
    change() {
      if (!this.birthtimeLocal) {
        alert('Birthtime is empty')
        return false
      }

      if (!this.birthtimeLocal.match(/\d{2}:\d{2}/)) {
        alert('Data is invalid')
        return false
      }

      let url = this.profile ? `/admin/profiles/${this.profile.id}/birthtime` : `/profile/birthtime`

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}${url}`, {
        birthtime: this.birthtimeLocal,
        _method: 'put'
      })
          .then(() => eventBus.$emit(eventBusEvents.EVENT_CHANGE_BIRTHTIME, this.birthtimeLocal))
          .catch(err => alert(converValidationServerErrorsToString(err)))
    }
  }
}
</script>

<style scoped>

</style>
