<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="ml-2"
          color="info"
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          dark
      >
        <v-icon aria-hidden="false">
          {{ pair_id ? 'mdi-pencil' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Пара {{ pair_id }}
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn dark text @click="dialog = false">
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <PairPanel :pair_id="pair_id"/>

      <v-divider/>
    </v-card>
  </v-dialog>
</template>

<script>
import PairPanel from "./PairPanel";

export default {
  name: "PairControlDialog",
  props: [
    'pair_id',
    'action',
  ],
  components: {
    PairPanel
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>

</style>
