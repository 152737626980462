<template>
  <v-card>
    <v-card-title>
      SheetGrid

      <SheetControlDialog :action="crudActions.create"/>

      <v-spacer/>

      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
          v-model="selected"
          :single-select="singleSelect"
          :headers="headers"
          :items="sheets"
          :expanded.sync="expanded"
          :search="search"
          :sort-by="['position']"
          class="elevation-1"
          item-key="id"
          show-select
      >
        <!--:sort-desc="[true]"-->
        <!--items-per-page="30"-->

        <template v-slot:item.category="{ item }">
          {{ item.category.name }}
        </template>

        <template v-slot:item.down="{ item }">
          <v-btn @click="moveTo(item, 'down')">
            <v-icon>
              mdi-arrow-up-bold
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.up="{ item }">
          <v-btn @click="moveTo(item, 'up')">
            <v-icon>
              mdi-arrow-down-bold
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.bg_color="{ item }">
          <div style="width: 50px; height: 50px;" :style="{backgroundColor: item.bg_color}">
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <DefaultStatus :status="item.status"/>
        </template>

        <template v-slot:item.actions="{ item }">
          <!--<SheetControlDialog :sheet="item" :action="crudActions.update"/>-->
          {{ item.id }}
          <SheetRouteLink :sheet_id="item.id"/>
          <AgreeDialog @remove="remove(item)" class="ml-2"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import AgreeDialog from "../AgreeDialog";
import {crudActions, sexList} from "@/enums/enums";
import DefaultStatus from "../DefaultStatus";
import SheetRouteLink from "./SheetRouteLink";
import SheetControlDialog from "@/components/Sheet/SheetControlDialog";

const queryString = require('query-string');

export default {
  name: "SheetGrid",
  mounted() {
    this.getSheetList()
  },
  components: {
    AgreeDialog,
    DefaultStatus,
    SheetRouteLink,
    SheetControlDialog
  },
  data() {
    return {
      sheets: [],
      singleSelect: false,
      selected: [],
      expanded: [],
      singleExpand: false,
      search: '',

      queryParams: {},

      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Slug',
          align: 'start',
          sortable: true,
          value: 'slug',
        },
        {
          text: 'Sex',
          align: 'start',
          sortable: true,
          value: 'sex',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'SType',
          align: 'start',
          sortable: true,
          value: 's_type',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Color',
          align: 'start',
          sortable: true,
          value: 'bg_color',
        },
        {
          text: 'Опустить',
          sortable: false,
          value: 'down',
          width: '20%'
        },
        {
          text: 'Порядок',
          sortable: true,
          value: 'position',
        },
        {
          text: 'Поднять',
          sortable: false,
          value: 'up',
          width: '20%'
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '20%'
        },
      ]
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_REFRESH_SHEET_BY_ADMIN, async () => {
      this.getSheetList()
    });
    eventBus.$on(eventBusEvents.EVENT_CREATE_SHEET_BY_ADMIN, async () => {
      this.getSheetList()
    });
    eventBus.$on(eventBusEvents.EVENT_FILTER_SHEET, async search => {
      this.queryParams = search
      this.getSheetList()
    });
  },
  methods: {
    getSheetList() {
      let q = queryString.stringify(this.queryParams, {
        arrayFormat: 'bracket',
        skipNull: true,
        skipEmptyString: true
      })

      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets?${q}`)
          .then(response => this.sheets = response.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    remove(sheet) {
      this.$http.delete(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets/${sheet.id}`)
          .then(response => {
            console.log('sheet removed', response.data)
            this.getSheetList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));
    },
    moveTo(registerStep, direction) {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets/${registerStep.id}/${direction}`, {
        _method: 'put'
      })
          .then(response => {
            console.log('sheet moved', response.data)
            this.getSheetList()
          })
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
  }
}
</script>

<style scoped>

</style>
