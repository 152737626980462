<template>
  <div>
    <SexForm/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import SexForm from "../../components/Profile/Forms/SexForm";

export default {
  name: "RegisterSex",
  components: {
    SexForm
  },
  created() {
    eventBus.$on(eventBusEvents.EVENT_CHANGE_SEX, async sex => {
      console.log('sex', sex)
      this.next()
    });
  },
  methods: {
    ...mapActions([
      'EXEC_INCREMENT_REGISTER_STEP',
      'EXEC_REFRESH_USER',

    ]),
    next() {
      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/profile/increment-register-step`, {
        _method: 'put'
      })
          .then(resp => {
            this.EXEC_INCREMENT_REGISTER_STEP()
            this.EXEC_REFRESH_USER(resp.data.data.user)
          }).catch(err => alert(converValidationServerErrorsToString(err)));
    }
  }
}
</script>

<style scoped>

</style>
