<template>
  <div v-if="!!profile">
    <h4>Уведомления</h4>

    <v-row>
      <v-col>
        <v-btn color="deep-purple lighten-2" @click="searchNotifications" text>
          Получить список
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <div v-if="showNotificationsInfo">
          Всего:
          <v-chip>{{ notifications.length }}</v-chip>
        </div>
      </v-col>
    </v-row>

    <v-simple-table v-if="notifications">
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Заголовок</td>
          <td>Тело</td>
          <td>Тип</td>
          <td>Прочитано?</td>
        </tr>
        <tr v-for="notification in notifications" :key="notification.id">
          <td>
            {{ notification.name }}
          </td>
          <td>
            {{ notification.body }}
          </td>
          <td>
            {{ notification.n_type }}
          </td>
          <td>
            {{ convertUtcToDate(notification.created_at) }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider/>
  </div>
</template>

<script>
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {convertUtcToDate} from "../../utils/converters";

export default {
  name: "ProfileNotifications",
  props: ['profile'],
  data() {
    return {
      showNotificationsInfo: false,
      notifications: [],
    }
  },
  mounted() {
    this.searchNotifications()
  },
  methods: {
    convertUtcToDate(date) {
      return convertUtcToDate(date)
    },
    searchNotifications() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/users/${this.profile.user_id}/notifications`)
          .then(response => {
            this.notifications = response.data.data.items
            this.showNotificationsInfo = true
          })
          .catch(err => alert(converValidationServerErrorsToString(err)));

    }
  }
}
</script>

<style scoped>

</style>
