<template>
  <div>
    <v-row>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
        >
          <v-text-field
              v-model="sheetLocal.name"
              :rules="rules.nameRules"
              label="name"
              type="tel"
              @keyup.enter="handleSubmit"
              required
          />

          <template v-if="actionLocal === crudActions.update">
            <v-text-field
                v-model="sheetLocal.slug"
                :rules="rules.slugRules"
                label="slug"
                type="tel"
                @keyup.enter="handleSubmit"
                required
            />
          </template>

          <v-text-field
              v-model="sheetLocal.s_type"
              :rules="rules.sTypeRules"
              label="s_type"
              type="tel"
              @keyup.enter="handleSubmit"
              required
          />

          <v-radio-group v-model="sheetLocal.sex">
            <v-radio
                v-for="itSex in ['male', 'female']"
                :key="itSex"
                :value="itSex"
                :label="itSex"
            />
          </v-radio-group>

          <br>

          <v-select v-model="sheetLocal.category_id"
                    :items="categories"
                    :label="'Категория'"
                    item-text="name"
                    item-value="id"
                    v-if="categories.length > 0"
          />

          <v-text-field
              v-model="sheetLocal.body"
              :rules="rules.answerRules"
              label="body"
              type="text"
              @keyup.enter="handleSubmit"
              onfocus="this.removeAttribute('readonly')"
              required
              readonly
          />

<!--          <v-color-picker
              v-model="sheetLocal.bg_color"
              flat
              hide-canvas hide-mode-switch hide_inputs :show-swatches="false"
          />-->

          Color: {{ sheetLocal.bg_color }}

          <br>
          <br>

          <input type="color" v-model="sheetLocal.bg_color">

          <br>
          <br>
          <br>
          <br>

          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="handleSubmit"> {{ btnText }}
          </v-btn>

          <br/>

          <!-- sheetLocalForm: {{ sheetLocal }} -->
        </v-form>
      </v-col>

      <!-- todo как вариант вынести  -->
      <v-col>
        <template v-if="actionLocal === crudActions.update">
          <DictMultiplePanel
              :title="'Связанные статьи'"
              :dictMethod="`/admin/sheets/${sheetLocal.id}/relations`"
              :changeMethod="`/admin/sheets/${sheetLocal.id}/relations`"
              :postArgument="'relations'"
              :values="sheetLocal.relations"
              class="mt-4"
          />

          <br/>

          <PhotoForm
              :photos="sheetLocal.photos ? sheetLocal.photos : []"
              :url="`/admin/sheets/${sheetLocal.id}/photos`"
              :min="3"
              :max="20"
              :showSource="true"
              :showAnchor="true"
          />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {eventBus, eventBusEvents} from "@/eventBus/eventBus";
import {crudActions, sheetTypes} from "@/enums/enums";
import PhotoForm from "@/components/Profile/Forms/PhotoForm";
import DictMultiplePanel from "@/components/Dicts/Change/DictMultipleChangePanel";

require('dotenv').config()

export default {
  name: "SheetForm",
  components: {
    PhotoForm,
    DictMultiplePanel
  },
  props: ['action', 'sheet'],
  data() {
    return {
      valid: true,

      categories: [],

      actionLocal: null,

      sheetLocal: {
        name: null,
        body: null,
        category_id: null,
        sex: null,
        slug: null,
        s_type: null,
        bg_color: '#ccc',
        // status
        // position
        // relations
        // photos
      },
      rules: {
        questionRules: [
          v => !!v || 'Name must be valid',
          v => v.length > 5 || 'Name length must be more than 5 characters',
        ],
        answerRules: [
          v => !!v || 'Body must be valid',
          v => v.length > 20 || 'Body length must be more than 20 characters',
        ],
        categoryIdRules: [
          v => !!v || 'Category must be valid',
        ],
        sexRules: [
          v => !!v || 'Sex must be valid',
          v => (v === 'male' || v === 'female') || 'Body length must be more than 20 characters',
        ],
        slugRules: [
          v => !!v || 'Slug must be valid',
          v => v.length > 5 || 'Slug length must be more than 5 characters',
        ],
        sTypeRules: [
          v => !!v || 'sType must be valid',
          v => v.length > 5 || 'sType length must be more than 5 characters',
        ],
      }
    }
  },
  computed: {
    crudActions: function () {
      return crudActions
    },
    btnText: function () {
      return this.actionLocal === this.crudActions.create ? 'Create' : 'Update'
    },
    sheetTypes: function () {
      return sheetTypes
    }
  },
  created() {
    this.actionLocal = this.action

    if (this.sheet) {
      this.sheetLocal = this.sheet

      this.edit(this.sheetLocal)
    }

    eventBus.$on(eventBusEvents.EVENT_EDIT_SHEET_BY_ADMIN, async sheet => {
      console.log('EDIT_SHEET_BY_ADMIN', sheet)
      this.edit(sheet)
    })
    eventBus.$on(eventBusEvents.EVENT_REFRESH_SHEET_BY_ADMIN, async () => {
      this.refresh()
    })
  },
  mounted() {
    this.getCategoryList()

    if (this.actionLocal === this.crudActions.update) {
      this.sheetLocal = this.sheet
    } else {
      this.sheetLocal.s_type = this.sheetTypes.default
    }
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST'
    ]),
    getCategoryList() {
      this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheet-categories`)
          .then(resp => this.categories = resp.data.data.items)
          .catch(err => alert(converValidationServerErrorsToString(err)))
    },
    edit(data) {
      this.actionLocal = this.crudActions.update
      this.sheetLocal.id = data.id
      this.sheetLocal.name = data.name
      this.sheetLocal.body = data.body
      this.sheetLocal.category_id = data.category_id
      this.sheetLocal.slug = data.slug
      this.sheetLocal.s_type = data.s_type
      this.sheetLocal.bg_color = data.bg_color
    },
    refresh() {
      this.actionLocal = this.crudActions.create
      this.sheetLocal.id = null
      this.sheetLocal.name = null
      this.sheetLocal.body = null
      this.sheetLocal.category_id = null
      this.sheetLocal.slug = null
      this.sheetLocal.s_type = this.sheetTypes.default
      this.sheetLocal.bg_color = '#ccc'
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.actionLocal === this.crudActions.create) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      console.log('sheet_form_create')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets`,
          this.sheetLocal
      ).then(resp => {
        const sheet = resp.data.data.sheet
        console.log('Created sheet: ', sheet)
        eventBus.$emit(eventBusEvents.EVENT_CREATE_SHEET_BY_ADMIN, sheet)
        this.refresh()
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    update() {
      console.log('sheet_form_update')

      if (!this.validate()) {
        return false
      }

      this.EXEC_PROCESSING_REQUEST(true)

      let data = {
        ...this.sheetLocal,
        ...{
          _method: 'put'
        }
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/sheets/${this.sheetLocal.id}`,
          data
      ).then(resp => {
        console.log('sheet___________________', resp.data.data.sheet)
        this.sheetLocal = resp.data.data.sheet

        if (this.actionLocal === this.crudActions.create) {
          eventBus.$emit(eventBusEvents.EVENT_REFRESH_SHEET_BY_ADMIN)
        }
      })
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    },
    validate() {
      if (!this.sheetLocal.name) {
        alert('name is empty')
        return false
      }
      if (!this.sheetLocal.body) {
        alert('body is empty')
        return false
      }
      if (!this.sheetLocal.category_id) {
        alert('category_id is empty')
        return false
      }
      if (!this.sheetLocal.sex) {
        alert('sex is empty')
        return false
      }
      if (!this.sheetLocal.s_type) {
        alert('s_type is empty')
        return false
      }

      if (this.actionLocal === this.crudActions.update) {
        if (!this.sheetLocal.slug) {
          alert('slug is empty')
          return false
        }
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
