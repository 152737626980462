<template>
  <v-container fluid>
    <div class="moderator-hello">
      <h4 class="mb-4">
        {{ msg }}
      </h4>

      <section>
        <router-view/>
      </section>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ModeratorPage',
  data() {
    return {
      msg: 'Moderator page'
    }
  }
}
</script>

<style scoped>

</style>
