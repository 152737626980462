<template>
  <div>
    <v-row>
      <v-col cols="11">
        <AcquiringGrid :url="'/admin/acquirings'"/>
      </v-col>

      <v-col cols="1">
          <v-app-bar-nav-icon @click="drawer = !drawer"/>
      </v-col>
    </v-row>

    <v-navigation-drawer v-model="drawer" right fixed dark app disable-route-watcher enable-resize-watcher :clipped="false">
        <v-app-bar-nav-icon @click="drawer = !drawer"/>
        <AcquiringFilter class="mb-4"/>
        <v-divider/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {crudActions} from "@/enums/enums"
import TariffUsageGrid from "../../components/TariffUsage/TariffUsageGrid";
import TariffUsageFilter from "../../components/TariffUsage/TariffUsageFilter";
import AcquiringGrid from "../../components/Acquiring/AcquiringGrid";
import AcquiringFilter from "../../components/Acquiring/AcquiringFilter";

export default {
  name: "AdminAcquiringPage",
  data() {
    return {
      drawer: false
    }
  },
  components: {
    AcquiringGrid,
    AcquiringFilter,
  },
  computed: {
    crudActions: function () {
      return crudActions
    }
  }
}
</script>

<style scoped>

</style>
