<template>
  <div>
    <v-app-bar app>
      <v-layout row justify-center>
        <v-toolbar dark color="red lighten-2" class="hidden-xs-and-down">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

          <v-toolbar-title>DrSvat</v-toolbar-title>

          <v-spacer/>

          <v-toolbar-items>
            <v-btn class="nav-link" to="/admin/dashboard" text v-if="isLoggedIn"><!--active-->
              Главная
            </v-btn>

            <template v-if="!isLoggedIn">
              <v-btn to="/login" text>
                Вход
              </v-btn>

              <!--<v-btn to="/register" text>
                  Регистрация
              </v-btn>-->
            </template>

            <template v-else>
              <!--<v-btn to="/profile" class="nav-link" text>
                  Profile
              </v-btn>-->
              <v-btn type="button" class="nav-link" @click="logout" text>
                Выход
              </v-btn>
            </template>
          </v-toolbar-items>
        </v-toolbar>
      </v-layout>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
    >
      <div class="pa-2">
        <v-list v-if="isLoggedIn">
          <v-list-item-group>
            <v-list-item
                v-for="(item, i) in computedMenuItems"
                :key="i"
                :to="getLink(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"/>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!--<template v-if="!!user">
            {{ user }}
        </template>-->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {rolesEnum, rolesList} from "@/enums/enums";

export default {
  name: "Navbar",
  data: () => ({
    drawer: null,
    menuItems: [
      {
        icon: 'mdi-finance',
        text: 'Dashboard',
        link: '/dashboard',
        computable: true,
        roles: [
          rolesList.admin,
        ]
      },
      {
        icon: 'mdi-heart',
        text: 'Пары',
        link: '/pairs',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-account',
        text: 'На модерации',
        link: '/profiles-on-moderation',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-account',
        text: 'Пользователи',
        link: '/profiles',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
          rolesList.matchmaker,
        ]
      },
      {
        icon: 'mdi-comment-account-outline',
        text: 'Претенденты',
        link: '/challengers',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-question',
        text: 'Поддержка',
        link: '/support/whatsapp',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-ufo',
        text: 'Ufo-шники',
        link: '/ufo',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-stop',
        text: 'Пассивные',
        link: '/stoppers',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
        ]
      },
      {
        icon: 'mdi-yin-yang',
        text: 'Подборы',
        link: '/daily-matches',
        computable: true,
        roles: [
          rolesList.admin,
        ]
      },
      {
        icon: 'mdi-consolidate',
        text: 'Свидания',
        link: '/meetings',
        computable: true,
        roles: [
          rolesList.admin,
        ]
      },
      {
        icon: 'mdi-book',
        text: 'Страницы',
        link: '/sheets',
        computable: true,
        roles: [
          rolesList.admin,
          rolesList.moderator,
          rolesList.editor,
        ]
      },
      /*{
          icon: 'mdi-account-question',
          text: 'Лиды',
          link: '/leads',
          computable: true,
          roles: [
              rolesList.admin,
          ]
      },*/
      {
        icon: 'mdi-book',
        text: 'Архив',
        link: '/users-archive',
        computable: true,
        roles: [
          rolesList.admin,
        ]
      },
      /*{
          icon: 'mdi-city-variant-outline',
          text: 'Ожидающие города',
          link: '/missing-cities',
          computable: true,
          roles: [rolesList.admin,]
      },*/
      {
        icon: 'mdi-desktop-mac-dashboard',
        text: 'Шаги регистрации',
        link: '/admin/register-steps',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-alarm-panel',
        text: 'Тарифы',
        link: '/admin/tariffs',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-sale',
        text: 'Промокоды',
        link: '/admin/promo-categories',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-sale',
        text: 'Использованные',
        link: '/admin/promo-usages',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-currency-btc',
        text: '$$ в приложении',
        link: '/admin/tariff-usages',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-currency-btc',
        text: '$$ на сайте',
        link: '/admin/acquirings',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-eye-settings',
        text: 'Биометрия',
        link: '/admin/biometrics',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-help',
        text: 'Faq',
        link: '/admin/faq',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-cog-outline',
        text: 'Настройки числовые',
        link: '/admin/settings/numeric',
        roles: [rolesList.admin]
      },
      {
        icon: 'mdi-cog-transfer',
        text: 'Настройки булевые',
        link: '/admin/settings/boolean',
        roles: [rolesList.admin]
      },
      /*{
          icon: 'mdi-account',
          text: 'Аккаунт',
          link: '/profile',
          roles: [rolesList.consumer]
      },
      {
          icon: 'mdi-heart',
          text: 'Пары',
          link: '/pairs',
          roles: [rolesList.consumer]
      },*/
    ]
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'IS_LOGGED_IN',
      user: 'GET_USER',
    }),
    title: () => {
      return process.env.VUE_APP_TITLE
    },
    computedMenuItems: function () {
      return this.menuItems.filter(it => it.roles.includes(this.user.role))
    },
    rolesEnum: function () {
      return rolesEnum
    }

  },
  methods: {
    ...mapActions([
      'EXEC_LOGOUT'
    ]),
    getLink(item) {
      if (!item.computable) {
        return item.link
      }

      let roleName = this.rolesEnum.find(it => it.id === this.user.role).name

      return `/${roleName}${item.link}`
    },
    logout() {
      this.EXEC_LOGOUT().then(() => {
        this.$router.push('/login')
      })
    },
    isItemAllow(role) {
      return role === this.user.role
    }
  }
}
</script>

<style scoped>

</style>
