<template>
  <v-btn @click="startNewSearch()" v-if="show">
    Запустить новый поиск
  </v-btn>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {converValidationServerErrorsToString} from "@/utils/errorUtil";
import {profileSearchStatusesList} from "@/enums/enums";

export default {
  name: "StartNewSearch",
  props: ['profile'],
  data() {
    return {
      profileLocal: null,
      show: true,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'GET_PROCESSING_REQUEST'
    }),
  },
  mounted() {
    this.profileLocal = this.profile
  },
  methods: {
    ...mapActions([
      'EXEC_PROCESSING_REQUEST',
    ]),
    startNewSearch() {
      if (this.loading) return

      this.EXEC_PROCESSING_REQUEST(true)

      if (this.profileLocal.search_status !== profileSearchStatusesList.status_ready_for_search) {
        alert('Пользователь не готов к поиску')
        return
      }

      this.$http.post(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/profiles/${this.profileLocal.id}/start-new-search`)
          .then(() => this.show = false)
          .catch(err => alert(converValidationServerErrorsToString(err)))
          .finally(() => this.EXEC_PROCESSING_REQUEST(false))
    }
  }
}
</script>

<style scoped>

</style>
