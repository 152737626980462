<template>
    <span :style="{color: getColorByReaction(member.reaction)}">
        {{ member.profile.first_name }} - {{ member.o_type }}
      <!--{{ member.profile.first_name }} - {{ member.offer_id }}-->
    </span>
</template>

<script>
export default {
  name: "MemberNameColorReaction",
  props: ['member'],
  methods: {
    getColorByReaction(reaction) {
      let colors = {
        danger: 'red',
        info: 'grey',
        success: 'green',
      }

      if (reaction === 1) return colors.success
      else if (reaction === 2) return colors.danger
      else return colors.info
    },
  }
}
</script>

<style scoped>

</style>
